import { createRouter, createWebHistory } from 'vue-router';
import vueConfig from '../../vue.config';
import { store } from '@/store';
import Home from '@/views/HomeView.vue';
import PoliticaCookies from '@/views/contenido/legal/PoliticaCookies.vue';
import PoliticaPrivacidad from '@/views/contenido/legal/PoliticaPrivacidad.vue';
import Faqs from '@/views/contenido/legal/FaqsSection.vue';
import PoliticaCamasLibres from '@/views/contenido/legal/PoliticaCamasLibres.vue';
import Landing from '@/views/landing/LandingView.vue';
import CondicionesCovid from '@/views/contenido/legal/CondicionesCovid.vue';
import UsuarioActivacion from '@/views/usuario/UsuarioActivacion.vue';
import UsuarioPerfil from '@/views/usuario/UsuarioPerfil.vue';
import UsuarioRooming from '@/views/usuario/UsuarioRooming.vue';
import UsuarioProximasReservas from '@/views/usuario/UsuarioProximasReservas.vue';
import UsuarioProximasReservasDetalle from '@/views/usuario/UsuarioProximasReservasDetalle.vue';
import UsuarioProximasReservasModificar from '@/views/usuario/UsuarioProximaReservasModificar.vue';
import UsuarioProximasReservasConfirmacion from '@/views/usuario/UsuarioProximaReservasConfirmacion.vue';
import UsuarioDarBajaPublicidad from '@/views/usuario/UsuarioDarBajaPublicidad.vue';
import MisClientes from '@/views/usuario/staff/MisClientes.vue';
import ApresSkisDetalle from '@/views/contenido/apresSkis/ApresSkisDetalle.vue';
import ApresSkisCatalogo from '@/views/contenido/apresSkis/ApresSkisCatalogo.vue';
import FestivalesDetalle from '@/views/productos/agrupaciones/AgrupacionesExperienciasLanding.vue';
import FestivalesReservarEntrada from '@/views/productos/agrupaciones/FestivalesReservarEntrada.vue';
import FestivalesCatalogo from '@/views/productos/agrupaciones/FestivalesCatalogo.vue';
import FestivalesServicios from '@/views/productos/agrupaciones/FestivalesServicios.vue';
import FestivalesResumen from '@/views/productos/agrupaciones/FestivalesResumen.vue';
import FestivalesThanks from 'src/views/common/success/SuccessFestivales.vue';
import UsuarioPasswordRecordar from '@/views/usuario/UsuarioPasswordRecordar/UsuarioPasswordRecordar.vue';
import UsuarioPasswordEstablecer from '@/views/usuario/UsuarioPasswordEstablecer.vue';
import Buscador from '@/views/productos/catalogo/CatalogoBuscador.vue';
import BuscadorServicios from '@/views/productos/seleccion/SeleccionBuscador.vue';
import BuscadorResumenReserva from '@/views/productos/resumen/ResumenBuscador.vue';
import ReservaConfirmada from '@/views/common/success/SuccessConfirmacionReserva.vue';
import * as RouterNames from '@/router/RouterNames';
import NotFound from '@/components/NotFound';
import MisReservas from '@/views/reservas/MisReservas';
import TrabajaConNosotros from 'src/views/contenido/nosotros/TrabajaConNosotros.vue';
import QuienesSomos from 'src/views/contenido/nosotros/QuienesSomos.vue';
import DatosPersonales from '../views/landing/formulario/DatosPersonales.vue';
import ThanksYou from 'src/views/common/success/SuccessFormulario.vue';
import BlogLanding from '@/views/contenido/blog/BlogLanding.vue';
import blogPost from '@/views/contenido/blog/BlogPost.vue';
import CheckInReserva from '../views/reservas/CheckInReserva.vue';
import InformacionAlojamiento from '../views/productos/informacion/InformacionAlojamiento.vue';
import { redireccionRutas } from '../router/redirecciones';
import { appGlobalStore } from '@/store_pinia/app';
import { useLoginStore } from '@/store_pinia/login';

const BASE_VIAJES_GRUPOS = 'grupos/viajes';

const routes = [
	//////////////////////////////////////////////////////////////
	///////////////////         LANDING        ///////////////////
	//////////////////////////////////////////////////////////////
	{
		path: vueConfig.publicPath,
		name: RouterNames.Home,
		component: Home,
	},
	{
		path: '/buscador',
		name: RouterNames.Buscador,
		component: Buscador,
	},
	{
		path: '/buscador/alojamientos/:idAlojamiento',
		name: RouterNames.BuscadorAlojamiento,
		component: InformacionAlojamiento,
	},
	{
		path: '/buscador/servicios',
		name: RouterNames.BuscadorServicios,
		component: BuscadorServicios,
	},
	{
		path: '/buscador/reserva',
		name: RouterNames.BuscadorResumen,
		component: BuscadorResumenReserva,
		meta: { requiresLogin: true },
	},
	{
		path: '/leads',
		name: 'DatosPersonales',
		component: DatosPersonales,
	},
	{
		path: '/presupuestotyp',
		name: 'ThankYou',
		component: ThanksYou,
	},
	{
		path: vueConfig.publicPath + BASE_VIAJES_GRUPOS,
		name: RouterNames.ViajesGrupoCatalogo,
		component: () => import('src/views/productos/catalogo/CatalogoViajesGrupo.vue'),
		props: route => ({
			areaDestino: route.query.areaDestino,
			lugarExperienciaDestino: route.query.lugarExperienciaDestino,
			tipoGrupo: route.query.tipoGrupo,
		}),
	},
	{
		path: vueConfig.publicPath + BASE_VIAJES_GRUPOS + '/:viajeId/alojamientos',
		name: RouterNames.GruposReservaAlojamientos,
		component: () => import('src/views/productos/seleccion/SeleccionAlojamiento.vue'),
	},
	{
		path: vueConfig.publicPath + 'alojamientos/:idAlojamiento',
		name: RouterNames.InformacionAlojamiento,
		component: () => import('src/views/productos/informacion/InformacionAlojamiento.vue'),
	},
	{
		path:
			vueConfig.publicPath +
			BASE_VIAJES_GRUPOS +
			'/:viajeId/alojamientos/:hotelId/:numPlazas/:tipoHabId/:cupoIncremento/:regimen/:incrementoAlojamiento/:integrado',
		name: RouterNames.GruposReservaFormulario,
		component: () => import('src/modules/viajesGrupos/views/ViajesGrupoSeleccionServicios.vue'),
	},
	{
		path: vueConfig.publicPath + BASE_VIAJES_GRUPOS + '/:viajeId/modificarReservas',
		name: RouterNames.ViajesGrupoModificarReservas,
		component: () => import('src/modules/viajesGrupos/views/ViajesGrupoSeleccionServicios.vue'),
	},
	{
		path: vueConfig.publicPath + BASE_VIAJES_GRUPOS + '/:viajeId/unirse/:codigoGrupo',
		name: RouterNames.GruposReservaFormularioUnirse,
		component: () => import('src/modules/viajesGrupos/views/ViajesGrupoSeleccionServicios.vue'),
	},
	{
		path: vueConfig.publicPath + BASE_VIAJES_GRUPOS + '/:viajeId/unirse',
		name: RouterNames.GruposReservaFormularioUnirseSinAlojamiento,
		component: () => import('src/modules/viajesGrupos/views/ViajesGrupoSeleccionServicios.vue'),
	},
	{
		path: vueConfig.publicPath + BASE_VIAJES_GRUPOS + '/:viajeId/confirmacion',
		name: RouterNames.GruposReservaResumen,
		component: () => import('src/modules/viajesGrupos/views/ViajesGrupoResumenReserva.vue'),
		meta: { requiresLogin: true },
	},
	{
		path: vueConfig.publicPath + BASE_VIAJES_GRUPOS + '/:viajeId/reserva/:codigoViaje',
		name: RouterNames.ReservaConfirmadaComponent,
		component: () => import('src/views/common/success/SuccessConfirmacionReserva.vue'),
		meta: { requiresLogin: true },
	},
	{
		path: vueConfig.publicPath + '/pay-thanks',
		name: RouterNames.GruposViajesThankYouRedireccion,
		component: () => import('src/views/common/success/SuccessPagoRedireccion.vue'),
		meta: { requiresLogin: true },
	},
	{
		path: vueConfig.publicPath + BASE_VIAJES_GRUPOS + '/thanks/:grupoId',
		name: RouterNames.GruposViajesThankYou,
		component: () => import('src/views/common/success/SuccessPago.vue'),
		meta: { requiresLogin: true },
	},
	{
		path: vueConfig.publicPath + '/pay-problem',
		name: RouterNames.GruposViajesErrorPago,
		component: () => import('src/views/common/error/ErrorPago.vue'),
		meta: { requiresLogin: true },
	},
	{
		path: vueConfig.publicPath + BASE_VIAJES_GRUPOS + '/:viajeId',
		name: RouterNames.GruposInformacionViaje,
		component: () => import('src/views/productos/informacion/InformacionViaje.vue'),
		props: true,
	},
	//////////////////////////////////////////////////////////////
	///////////////////         USUARIO        ///////////////////
	//////////////////////////////////////////////////////////////
	// {
	// 	path: vueConfig.publicPath + 'ofertas-nieve/:uri',
	// 	name: RouterNames.Landing,
	// 	component: Landing
	// },
	{
		path: vueConfig.publicPath + ':uri1/:uri2?/:uri3?',
		name: RouterNames.Landing,
		component: Landing,
	},
	{
		path: vueConfig.publicPath + 'usuario/perfil',
		name: 'perfil',
		component: UsuarioPerfil,
		meta: { requiresLogin: true },
	},
	{
		path: vueConfig.publicPath + 'usuario/proximasreservas',
		name: RouterNames.UsuarioProximasReservas,
		component: UsuarioProximasReservas,
		meta: { requiresLogin: true },
	},
	{
		path: vueConfig.publicPath + 'usuario/rooming',
		name: RouterNames.UsuarioRooming,
		component: UsuarioRooming,
		meta: { requiresLogin: true },
	},
	{
		path: vueConfig.publicPath + 'usuario/proximasreservas/:grupoId',
		name: RouterNames.UsuarioProximasReservasDetalle,
		component: UsuarioProximasReservasDetalle,
		meta: { requiresLogin: true },
	},
	{
		path: vueConfig.publicPath + 'usuario/proximasreservas/:grupoId/modificar',
		name: RouterNames.UsuarioProximaReservasModificar,
		component: UsuarioProximasReservasModificar,
		meta: { requiresLogin: true },
	},
	{
		// Mantener url por google analitics
		path: vueConfig.publicPath + 'grupos/proximasreservas/:grupoId/confirmacion',
		name: RouterNames.UsuarioProximaReservasConfirmacion,
		component: UsuarioProximasReservasConfirmacion,
		meta: { requiresLogin: true },
	},
	{
		path: vueConfig.publicPath + 'usuario/password/recordar',
		name: 'UsuarioPasswordRecordar',
		component: UsuarioPasswordRecordar,
	},
	{
		path: vueConfig.publicPath + 'usuario/activacion/:codigo',
		name: 'UsuarioActivacion',
		component: UsuarioActivacion,
	},
	{
		path: vueConfig.publicPath + 'usuario/password/establecer/:codigo',
		name: 'UsuarioPasswordEstablecer',
		component: UsuarioPasswordEstablecer,
	},
	{
		path: vueConfig.publicPath + 'usuario/password/recordar',
		name: 'UsuarioPasswordRecordar',
		component: UsuarioPasswordRecordar,
	},
	{
		path: vueConfig.publicPath + '/baja-emails-promocionales/:codigo',
		name: 'UsuarioDarBajaPublicidad',
		component: UsuarioDarBajaPublicidad,
	},

	//////////////////////////////////////////////////////////////
	///////////////////         STAFF     ////////////////////////
	//////////////////////////////////////////////////////////////
	{
		path: vueConfig.publicPath + 'staff/misClientes',
		name: RouterNames.MisClientes,
		component: MisClientes,
		meta: { requiresLogin: true },
	},

	//////////////////////////////////////////////////////////////
	///////////////////         APRES SKIS     ///////////////////
	//////////////////////////////////////////////////////////////
	{
		path: vueConfig.publicPath + 'apresSkis/:apresSkiId',
		name: RouterNames.ApresSkisDetalle,
		component: ApresSkisDetalle,
	},
	{
		path: vueConfig.publicPath + 'apresSkis',
		name: RouterNames.ApresSkisCatalogo,
		component: ApresSkisCatalogo,
	},

	//////////////////////////////////////////////////////////////
	//////////////         FESTIVALES AISLADOS     ///////////////
	//////////////////////////////////////////////////////////////
	{
		path: vueConfig.publicPath + 'agrupaciones/:uri',
		name: RouterNames.FestivalesDetalle,
		component: FestivalesDetalle,
	},
	{
		path: vueConfig.publicPath + 'agrupaciones/catalogo/:uri',
		name: RouterNames.FestivalesCatalogo,
		component: FestivalesCatalogo,
	},
	{
		path: vueConfig.publicPath + 'agrupaciones/reserva/:uri',
		name: RouterNames.FestivalesReservarEntrada,
		component: FestivalesReservarEntrada,
	},
	{
		path: vueConfig.publicPath + 'agrupaciones/reserva/:uri/servicios/:eventoId',
		name: RouterNames.FestivalesServicios,
		component: FestivalesServicios,
	},
	{
		path: vueConfig.publicPath + 'agrupaciones/reserva/:uri/servicios/:eventoId/resumen',
		name: RouterNames.FestivalesResumen,
		component: FestivalesResumen,
	},
	{
		path: vueConfig.publicPath + 'agrupaciones/:nombreFestival/thanks',
		name: RouterNames.FestivalesThanks,
		component: FestivalesThanks,
	},
	{
		path: vueConfig.publicPath + 'agrupaciones/:uri/eventos/:eventoId/validarTicket/:codigoUnico',
		name: RouterNames.ValidacionQrFestivales,
		component: () => import('@/views/productos/agrupaciones/FestivalesValidacionQr.vue'),
		meta: { requiresLogin: true },
	},
	{
		path: vueConfig.publicPath + ':grupoId/reservas/:reservaId/servicios/:codigoValidacion',
		name: RouterNames.ValidacionQrServicios,
		component: () => import('../views/festivales/ServiciosValidacionQr.vue'),
		meta: { requiresLogin: true },
	},

	//////////////////////////////////////////////////////////////
	///////////////////          LEGAL         ///////////////////
	//////////////////////////////////////////////////////////////
	{
		path: vueConfig.publicPath + 'legal/faqs',
		name: 'FAQS',
		component: Faqs,
	},
	{
		path: vueConfig.publicPath + 'legal/politicaPrivacidad',
		name: 'PoliticaPrivacidad',
		component: PoliticaPrivacidad,
	},
	{
		path: vueConfig.publicPath + 'legal/politicaCookies',
		name: 'PoliticaCookies',
		component: PoliticaCookies,
	},
	{
		path: vueConfig.publicPath + 'legal/condicionesCovid',
		name: 'CondicionesCovid',
		component: CondicionesCovid,
	},
	{
		path: vueConfig.publicPath + 'legal/politicaCamasLibres',
		name: 'PoliticaCamasLibres',
		component: PoliticaCamasLibres,
	},

	// NOSOTROS
	{
		path: vueConfig.publicPath + 'nosotros/trabajaConNosotros',
		name: 'TrabajaConNosotros',
		component: TrabajaConNosotros,
	},
	{
		path: vueConfig.publicPath + 'nosotros/quienesSomos',
		name: 'QuienesSomos',
		component: QuienesSomos,
	},

	{
		path: '/blog',
		name: 'BlogLanding',
		component: BlogLanding,
	},
	{
		path: '/blog/:blogUri',
		name: 'BlogLandingPost',
		component: blogPost,
	},

	//////////////////////////////////////////////////////////////
	///////////////////        RESERVAS        ///////////////////
	//////////////////////////////////////////////////////////////
	{
		path: vueConfig.publicPath + 'reservas/confirmada',
		name: 'ReservaConfirmada',
		component: ReservaConfirmada,
	},

	//////////////////////////////////////////////////////////////
	///////////////////     MIS RESERVAS    ///////////////////
	//////////////////////////////////////////////////////////////
	{
		path: vueConfig.publicPath + 'usuario/misReservas',
		name: RouterNames.MisReservas,
		component: MisReservas,
	},
	//////////////////////////////////////////////////////////////
	///////////////////     RESERVAS-PARATI    ///////////////////
	//////////////////////////////////////////////////////////////

	{
		path: vueConfig.publicPath + 'grupos/:grupoId/reserva/:reservaId/checkin',
		name: RouterNames.CheckIn,
		component: CheckInReserva,
		meta: { requiresLogin: true },
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'Error',
		component: NotFound,
	},
	// {
	//     path: '/about',
	//     name: 'About',
	//     // route level code-splitting
	//     // this generates a separate chunk (about.[hash].js) for this route
	//     // which is lazy-loaded when the route is visited.
	//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	// }
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	base: process.env.BASE_URL,
	routes: [...routes],
	scrollBehavior(to) {
		if (to.hash) {
			return {
				selector: to.hash,
			};
		} else {
			return { left: 0, top: 0 };
		}
	},
});

router.beforeEach(async (to, from, next) => {
	const storeGlobal = appGlobalStore();
	while (storeGlobal.permisos === null) {
		await new Promise(r => setTimeout(r, 100));
	}
	redireccionRutas(to, next);
});

router.beforeEach((to, from, next) => {
	const loginStore = useLoginStore();
	if (to.matched.some(record => record.meta.requiresLogin) && !store.state.login.isLogged) {
		loginStore.openLoginModal();
		next(vueConfig.publicPath + BASE_VIAJES_GRUPOS);
	} else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	const storeGlobal = appGlobalStore();
	document.title = storeGlobal.valores.meta_titulo || window.location.origin;
	if (document.querySelector("[name='description']")) {
		document.querySelector("[name='description']").remove();
	}
	var meta = document.createElement('meta');
	meta.name = 'description';
	meta.content = storeGlobal.valores.meta_descripcion;
	document.getElementsByTagName('head')[0].appendChild(meta);
	next();
});

router.beforeEach((to, from, next) => {
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find(r => r.meta && r.meta.title);

	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find(r => r.meta && r.meta.metaTags);

	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find(r => r.meta && r.meta.metaTags);

	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	} else if (previousNearestWithMeta) {
		document.title = previousNearestWithMeta.meta.title;
	}

	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

	if (!nearestWithMeta) return next();

	nearestWithMeta.meta.metaTags
		.map(tagDef => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach(key => {
				tag.setAttribute(key, tagDef[key]);
			});

			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		.forEach(tag => document.head.appendChild(tag));

	next();
});

export default router;
