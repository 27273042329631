import SkiAndNightApiService from './BaseSkiAndNightApiService';

const USUARIO_API_VERSION = 'v1';
const USUARIO_API_BASEPATH_URL = '/festivales/' + USUARIO_API_VERSION;

class EventosApiService {
	async getFestivales(titulo) {
		const params = new URLSearchParams();
		if (titulo) {
			params.append('titulo', titulo);
		}
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL, params);
	}

	async getFestival(uri, codigo) {
		const params = new URLSearchParams();
		if (codigo) {
			params.append('codigo', codigo);
		}
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/${uri}`), params);
	}

	async getFestivalSeo(uri, codigo) {
		const params = new URLSearchParams();
		if (codigo) {
			params.append('codigo', codigo);
		}
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/${uri}/seo`), params);
	}

	async getEventos(uri, codigo) {
		const params = new URLSearchParams();
		if (codigo) {
			params.append('codigo', codigo);
		}
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos`), params);
	}

	async getEvento(uri, eventoId, codigo) {
		const params = new URLSearchParams();
		if (codigo) {
			params.append('codigo', codigo);
		}
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos/${eventoId}`), params);
	}

	async getTickets(uri, eventoId) {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos/${eventoId}/tickets`));
	}

	async crearTicketera(uri, eventoId, bodyCreateTicketera) {
		return await SkiAndNightApiService.post(
			USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos/${eventoId}/ticketera`),
			bodyCreateTicketera
		);
	}

	async validarDescuento(uri, eventoId, codigo, ticketeraId) {
		const params = new URLSearchParams();
		params.append('codigo', codigo);
		params.append('ticketeraId', ticketeraId);
		return await SkiAndNightApiService.get(
			USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos/${eventoId}/validarDescuento`),
			params
		);
	}

	async getTicketeras() {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat('/ticketeras'));
	}

	async getTicketera(ticketeraId) {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/ticketeras/${ticketeraId}`));
	}

	async getResumenValidarTicket(uri, eventoId, codigo) {
		return await SkiAndNightApiService.get(
			USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos/${eventoId}/tickets/${codigo}`)
		);
	}

	async validarTicket(uri, eventoId, codigo) {
		return await SkiAndNightApiService.post(
			USUARIO_API_BASEPATH_URL.concat(`/${uri}/eventos/${eventoId}/tickets/${codigo}/validar`)
		);
	}
}

export default new EventosApiService();
