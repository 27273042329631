<template>
	<template v-if="grupo && storeGlobal.valores.img_logo_agencia">
		<div class="espaciado container container-pdf m-2">
			<div class="row">
				<div class="col-md-8 px-2">
					<div class="card">
						<div class="text-primary m-3">
							<div class="title mb-2">{{ grupo.viaje.nombre }}</div>
							<div class="sub-title">{{ servicio.nombre }}</div>
							<div class="description">
								{{ servicio.descripcion }}
							</div>
						</div>
						<div class="card-body text-primary py-0">
							<div class="row mb-2">
								<div class="col-12 text-right">
									<div class="row">
										<div class="col">
											<div class="my-2 text-title">{{ $t('reserva.bono.fechaIni') }}</div>
											<div class="my-2 text-description">
												{{ servicio.fechaInicio }}
											</div>
										</div>
										<div class="col">
											<div class="my-2 text-title">{{ $t('reserva.bono.fechaFin') }}</div>
											<div class="my-2 text-description">
												{{ servicio.fechaFin }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-12">
									<div class="text-description mb-1">{{ $t('reserva.bono.informacionGeneral') }}</div>
										<p class="text-title">{{ servicio.descripcion }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 px-2">
					<div class="ticket-body text-primary">
						<div class="row mb-2 m-3">
							<div v-if="servicio.codigoValidacion" class="col-md-12 text-center mb-3">
								<Qrcode
									:value="urlQrCode(grupo.id, reserva.id, servicio.codigoValidacion)"
									:size="100"
									level="H"
									class="mt-2" />
							</div>
							<div class="col-12 pb-2">
								<p class="mb-0 text-description">
									<template v-if="servicio.paqueteBasico">{{ grupo.reservas[0].precioBasico }}{{agenciaStore.getCurrency}}</template>
									<template v-else>{{ servicio.precio }}{{agenciaStore.getCurrency}}</template>
								</p>
								<p class="mb-0 text-title">{{ $t('reserva.bono.impuestosIncluidos') }}</p>
								<p v-if="servicio.paqueteBasico" class="mb-0 text-title">
									{{ $t('reserva.bono.incluidoPaqueteBasico') }}
								</p>
								<p v-else class="mb-0 text-title">{{ $t('reserva.bono.servicioExtra') }}</p>
							</div>
							<div class="col-12">
								<p class="mb-0 text-title">{{ $t('reserva.bono.localizador') }}:</p>
								<p class="text-description">{{ servicio.id }}</p>
								<p class="mb-0 text-title">{{ $t('reserva.bono.titular') }}:</p>
								<p class="text-description">{{ reserva.nombreUsuario }}</p>
								<p class="mb-0 text-title mt-2">{{ $t('reserva.bono.email') }}:</p>
								<p class="text-description"> {{ reserva.emailUsuario }}</p>
							</div>
						</div>
						<div class="text-muted powered-by m-3 text-center">
							Powered by <img class="" style="width: 68px" src="@/assets/img/logo_mygocu.png" alt="" />
						</div>
					</div>
				</div>
				<div class="col-md-12 pt-2 px-2">
					<div class="card">
						<div class="row p-3">
							<div class="col-3 text-center align-content-center">
								<img :src="storeGlobal.valores.img_logo_agencia" alt="" class="img-logo" />
							</div>
							<div class="col-9">
								<img :src="imagenExperiencia" alt="" class="img-experiencia" />
							</div>
						</div>

						<div class="card-body">
							<p class="text-terms">{{ $t('general.terminosCondiciones') }}</p>
							<p class="text-terms-description">
								{{ $t('footer.terminosCondiciones.bono.descripcion') }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</template>
</template>

<script setup>
	import Qrcode from 'qrcode.vue';
	import { appGlobalStore } from '@/store_pinia/app';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { computed } from 'vue';

	const storeGlobal = appGlobalStore();
	const agenciaStore = agenciaStoreModule();
	const storeHabitacion = informacionHabitacion();

	const props = defineProps({
		// ServicioDTO
		servicio: {
			type: Object,
			default: null,
			required: false,
		},
		// RReservaDTO
		reserva: {
			type: Object,
			default: null,
		},
		// RGrupoDTO
		grupo: {
			type: Object,
			default: null,
		},
	});

	const baseURL = window.location.origin;

	const imagenExperiencia = computed(() => storeHabitacion.getViaje.imagenExperiencia ?? props.grupo.habitacion?.alojamiento.imagen);

	function urlQrCode(grupoId, reservaId, codigoValidacion) {
		return `${baseURL}/${grupoId}/reservas/${reservaId}/servicios/${codigoValidacion}`;
	}
</script>

<style lang="scss">
	.espaciado {
		margin: 9rem 1rem 1rem 1.5rem;
	}

	.container-pdf {
		background: var(--bs-white);

		.card {
			border-radius: var(--bs-border-radius);
			border: 0.5px solid rgba(0, 17, 63, 0.25);
			background: var(--bs-white);
			// box-shadow: 0px 1px 4px 0px rgba(0, 17, 63, 0.1);
			height: 100%;
		}

		.title {
			font-size: 16px;
			font-weight: 700;
		}

		.sub-title {
			font-size: 18px;
			font-weight: 800;
		}

		.description {
			font-size: 18px;
			font-weight: 400;
		}

		.ticket-body {
			border-radius: var(--bs-border-radius);
			border: 0.5px solid rgba(0, 17, 63, 0.25);
			background: var(--bs-white);
			// box-shadow: 0px 1px 4px 0px rgba(0, 17, 63, 0.1);
			height: 100%;
		}

		.text-title {
			font-size: 11px;
			font-weight: 400;
		}

		.text-description {
			font-size: 11px;
			font-weight: 600;
			margin-bottom: 8px;
		}

		.powered-by {
			font-size: 9px;
			font-weight: 400;
		}

		.text-terms {
			font-size: 9px;
			font-weight: 600;
		}

		.text-terms-description {
			font-size: 8px;
			font-weight: 400;
		}

		.img-logo {
			max-width: 90%;
			max-height: 150px;
		}

		.img-experiencia {
			width: 100%;
		}
	}
</style>
