import SkiAndNightApiService from './BaseSkiAndNightApiService';

const HOME_API_VERSION = 'v1';
const HOME_API_BASEPATH_URL = '/home/' + HOME_API_VERSION;

class HomeApiService {
	async getAgencia() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/agencia'));
	}

	async getOfertas() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/ofertas'));
	}

	async getEsponsors() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/esponsors'));
	}

	async getVideos() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/videos'));
	}

	async getFaqs() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/faqs'));
	}

	async sendLeads(email) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat('/leads'), { ...email });
	}

	async sendInformacionPersonal(infoPersonal) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat('/presupuestoSwInfoPersonal'), {
			...infoPersonal,
		});
	}

	async sendInformacionViaje(infoViaje) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat('/presupuestoSwInfoViaje'), { ...infoViaje });
	}

	async sendTrabajaConNosotrosCv(trabajaConNosotros) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat('/trabajaNosotrosCv'), trabajaConNosotros, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	// async getAlojamiento(idAlojamiento) {
	// 	return await SkiAndNightApiService.get(
	// 		HOME_API_BASEPATH_URL.concat(`/alojamiento/${idAlojamiento}`)
	// 	);
	// }
	async getAlojamiento(idAlojamiento) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat(`/alojamiento/${idAlojamiento}`));
	}

	// async getAlojamientoSeo(idAlojamiento) {
	// 	return await SkiAndNightApiService.get(
	// 		HOME_API_BASEPATH_URL.concat(`/alojamiento/${idAlojamiento}/seo`)
	// 	);
	// }

	async getAlojamientoSeo(idAlojamiento) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat(`/alojamiento/${idAlojamiento}/seo`));
	}

	async getBlogFilters() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/blogs/filtros'));
	}

	async getBlog() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/blog'));
	}

	async getBlogByUri(uri) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/blog/' + uri));
	}

	async getBlogByUriSeo(uri) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/blog/' + uri + '/seo'));
	}

	async getApresSkis() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/apresSkis'));
	}

	async getApresSkisHeader() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/apresSkis/header'));
	}

	async getApresSkiById(idApresSki) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/apresSkis/' + idApresSki));
	}

	async getApresSkiByIdSEO(idApresSki) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/apresSkis/' + idApresSki + '/seo'));
	}

	async getFestivales() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/festivales'));
	}

	async getFestivalByUri(uri) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/festivales/' + uri));
	}

	async getAvisoLegal() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/avisoLegal'));
	}
}

export default new HomeApiService();
