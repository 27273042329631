<template>
	<div class="row">
		<div class="col">
			<div class="input-group mb-1">
				<select
					v-model="servicioSeleccionado"
					class="form-select selector-servicio text-primary"
					:disabled="!isServicioChangeable(reserva, servicioSeleccionado)"
					@change="servicioSeleccionado.justSelected = true">
					<option
						v-for="servicio in categoriaServicios.servicios.filter(s => s.activo)"
						:key="servicio.id"
						:value="servicio"
						:disabled="!isServicioSelectable(reserva, servicio)">
						<!-- PROVISIONAL: Comentado a peticion -->
						<!-- <span v-if="!servicio.nulo && servicio.tipo === 'transporte'">
							{{ servicio.numDias }}
							<span v-if="servicio.numDias == 1"> {{ $t('general.dia') }}</span>
							<span v-else> {{ $t('general.dias') }}</span>
						</span> -->
						{{ servicio.nombre }}
						<span>
							<span v-if="!servicio.nulo">
								( {{ $t('general.del') }}
								{{ dateMonthNumbers(servicio.fechaInicio) }}
								{{ $t('general.al') }} {{ dateMonthNumbers(servicio.fechaFin) }})
							</span>
							<span v-if="servicio.precio > 0">(+</span>
							<span v-if="servicio.precio">
								<span v-if="servicio.precio < 0">(</span>
								{{ servicio.precio }}{{agenciaStore.getCurrency}})
							</span>
						</span>
					</option>
				</select>
			</div>
			<div class="row text-primary mb-2 description-text">
				<template v-if="servicioSeleccionado && !servicioSeleccionado.nulo">
					<div class="col-12">
						{{ servicioSeleccionado.descripcion }}
					</div></template
				>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { dateMonthNumbers } from '@/helpers/dateUtiles';
	import { isServicioSelectable, isServicioChangeable } from '@/helpers/serviciosUtils';
	import { onBeforeMount, ref, watch } from 'vue';
	import { agenciaStoreModule } from '@/store_pinia/agencia';

	const props = defineProps({
		idxReserva: {
			type: Number,
			default: null,
		},
		categoriaServicios: {
			type: Object,
			default: null,
		},
	});

	const emits = defineEmits(['servicioSeleccionado']);

	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();

	const servicioSeleccionado = ref();

	const reserva = storeHabitacion.getReservaByIdx(props.idxReserva);

	const addServicioToReserva = () => {
		storeHabitacion.addServicioToReserva(props.idxReserva, servicioSeleccionado.value);
		emits('servicioSeleccionado', servicioSeleccionado.value);
	};

	function init() {
		let categoria = props.categoriaServicios.categoria;
		let reservaCurrentServicio = reserva.servicios[categoria];
		// If the booking does not have any service of this category needs to be initialised
		if (reservaCurrentServicio === undefined) {
			if (props.categoriaServicios.paqueteBasico) {
				servicioSeleccionado.value = props.categoriaServicios.servicios.find(({ paqueteBasico }) => paqueteBasico);
			} else {
				servicioSeleccionado.value = props.categoriaServicios.servicios.find(({ nulo }) => nulo);
			}
		} else {
			// Otherwise the current value is load
			servicioSeleccionado.value = reservaCurrentServicio;
		}
		addServicioToReserva();
	}

	watch(servicioSeleccionado, () => {
		addServicioToReserva();
	});

	onBeforeMount(() => {
		init();
	});
</script>

<style lang="scss" scoped>
	.selector-servicio {
		background-color: rgba(var(--bs-primary-rgb), 0.02);
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset;
	}

	.description-text {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0.4rem;

		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}
</style>
