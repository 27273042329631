<template>
	<div class="buscador-header">
		<div id="buscador-desktop3" class="filtro-buscador text-white bg-primary py-4 py-md-0">
			<div class="d-block d-md-none">
				<div class="justify-content-around w-100 d-flex flex-row">
					<div class="col-8 col-sm-6 px-3 px-sm-0">
						<HeaderBuscadorParaTi />
					</div>
					<div class="col-4 col-sm-4 mt-2 mt-sm-0 d-flex justify-content-center align-items-center">
						<div class="row">
							<div class="col-6">
								<button
									class="btn btn-secondary p-2 rounded-circle"
									data-bs-toggle="modal"
									data-bs-target="#modalBuscadorParaTi">
									<div class="d-flex">
										<div class="">
											<i class="fa-solid fa-magnifying-glass fa-xl" />
										</div>
									</div>
								</button>
							</div>
							<div class="col-6">
								<button
									class="btn btn-secondary p-2 rounded-circle"
									data-bs-toggle="modal"
									data-bs-target="#modalFiltro">
									<div class="d-flex">
										<div class="">
											<i class="fa-solid fa-filter fa-xl" />
										</div>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="seccion-proximas-reservas mx-3">
			<!-- <transition appear name="slide-fade">
      <publicidadNavidad contenido="Estás más cerca de conseguir tu regalo, reserva el viaje y descúbrelo "/>

        </transition> -->

			<div class="container-buscador-desktop">
				<div id="buscador-desktop2">
					<div class="card buscador-ocultar shadow">
						<div class="card-body text-primary position-relative pb-0">
							<HeaderBuscadorParaTi />

							<div class="m-1 text-end" style="right: 0; bottom: -17px">
								<button
									class="btn btn-primary rounded-circle me-1"
									data-bs-toggle="modal"
									data-bs-target="#modalBuscadorParaTi">
									<div class="d-flex">
										<div class="">
											<i class="fa-solid fa-magnifying-glass fa-sm" />
										</div>
									</div>
								</button>
								<button class="btn btn-primary rounded-circle" data-bs-toggle="modal" data-bs-target="#modalFiltro">
									<div class="d-flex">
										<div class="">
											<i class="fa-solid fa-filter fa-sm" />
										</div>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Buscador -->
			<div class="row buscador-desktop">
				<BuscadorContainer :selector-grupos="false" />
			</div>
			<div v-if="!loadingHoteles && hoteles" class="container row my-5 mx-auto gx-0 justify-content-between">
				<!-- Filtros -->
				<div id="filtros" class="col-lg-3 d-none d-lg-flex flex-column barra-lateral">
					<!-- Mapa -->
					<div v-if="false" class="cuadro-mapa mb-4">
						<div class="fondo-mapa d-flex justify-content-center align-items-center">
							<button class="boton boton-naranja fw-650">VER MAPA ALOJAMIENTOS</button>
						</div>
					</div>

					<!-- Filtros -->
					<div class="filtros">
						<div class="d-flex justify-content-between align-items-center">
							<div class="cuadro-azul">Filtrar alojamientos</div>
							<button class="boton boton-no-fondo p-1" @click="resetFiltros()">Reestablecer filtros</button>
						</div>
						<div class="cuadro-filtros">
							<div class="px-2 seccion-nombres">
								<p class="ps-2 fw-650 mb-2">{{ $t('alojamiento.nombre') }}:</p>

								<div class="d-flex justify-content-between align-items-center mb-3">
									<input
										id="nombreFiltros"
										class="input-nombre"
										type="text"
										:placeholder="$t('alojamiento.nombre')"
										@keyup.enter="aniadirFiltrosNombre()" />
									<button class="boton-aniadir-nombre" @click="aniadirFiltrosNombre()">
										<i class="fa-solid fa-plus" />
									</button>
								</div>

								<div
									v-for="(filtroNombre, i) in filtros.nombres"
									:key="i"
									class="d-flex justify-content-between align-items-center mt-2">
									{{ filtroNombre }}

									<button class="boton-aniadir-nombre" @click="eliminarFiltrosNombre(i)">
										<i class="fa-solid fa-minus" />
									</button>
								</div>
							</div>

							<hr class="hr-filtro" />

							<div class="px-2 seccion-tipos-alojamiento">
								<p class="ps-2 fw-650 mb-2">Elige el tipo de alojamiento al que te gustaría ir</p>

								<li
									v-for="(tipo, i) in filtros.tipos"
									:key="i"
									class="d-flex justify-content-between align-items-center list-item"
									@click="updateTiposAlojamientoSelected(i)">
									<div>
										{{ firstLetterCapital(tipo.nombre) }}
									</div>
									<div>
										<i v-if="tipo.selected" class="fa-sharp fa-solid fa-circle-check text-secondary fa-xl" />
										<i v-else class="fa-regular fa-circle text-secondary fa-xl" />
									</div>
								</li>
							</div>

							<hr class="hr-filtro" />

							<div class="px-2 seccion-tipos-alojamiento">
								<p class="ps-2 fw-650 mb-3">Elige el rango de precio de tu viaje ({{agenciaStore.getCurrency}})</p>
								<div class="pb-2 mx-2 text-right">
									<SliderForm
										v-model="filtros.precios"
										tooltip-position="bottom"
										:min="preciosInicial.min"
										:max="preciosInicial.max"
										:step="10"
										class="slider-primary" />
								</div>
								<div class="mt-4 text-end pt-2">
									<button class="btn btn-primary" style="font-size: 10px" @click="filtrarPrecios">Aplicar</button>
								</div>
							</div>

							<hr class="hr-filtro" />

							<div class="px-2 seccion-tipos-alojamiento">
								<p class="ps-2 fw-650 mb-3">Elige la distancia a pistas (m)</p>
								<div class="pb-2 mx-2">
									<SliderForm
										v-model="filtros.distanciasPistas"
										tooltip-position="bottom"
										:min="distanciasPistasInicial.min"
										:max="distanciasPistasInicial.max"
										:step="10" />
								</div>
								<div class="mt-4 text-end pt-2">
									<button class="btn btn-primary" style="font-size: 10px" @click="filtrarPrecios">Aplicar</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Alojamientos -->
				<div class="col-12 col-lg-8">
					<!-- Titulo y ordenación -->
					<div class="d-flex flex-column flex-lg-row justify-content-between align-items-center my-3 mx-4">
						<p v-if="hoteles.length == 1" class="fw-bold mb-0">Se ha encontrado {{ hoteles.length }} alojamiento</p>
						<p v-else-if="hoteles.length <= 50" class="fw-bold mb-0">
							Se han encontrado {{ hoteles.length }} alojamientos
						</p>
						<p v-else class="fw-bold mb-0">Más de 50 viajes disponibles</p>

						<div class="d-flex align-items-center">
							<div class="info-ordenacion">
								{{ $t('general.ordenar') }}
							</div>
							<div class="dropdown">
								<button
									id="dropdownOpcionesOrdenar"
									class="btn boton-dropdown d-flex flex-row justify-content-between align-items-center py-2"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									<span>
										{{ opcionesOrdenacion[opcionOrdenacionEscogida] }}
									</span>

									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-chevron-down"
										viewBox="0 0 16 16">
										<path
											fill-rule="evenodd"
											d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
									</svg>
								</button>

								<ul class="dropdown-menu" aria-labelledby="dropdownOpcionesOrdenar">
									<li v-for="(opcion, i) in opcionesOrdenacion" :key="i">
										<button class="dropdown-item" type="button" @click="updateOpcionOrdenacionEscogida(i)">
											{{ opcion }}
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<!-- Texto si coincide con nuestros viajes -->
					<div
						v-if="nuestrosViajes.length > 0"
						class="cuadro-coincide p-2 d-flex flex-row justify-content-between align-items-center mt-4 p-2">
						<div class="d-flex flex-column flex-md-row align-items-center">
							<i class="fa-solid fa-circle-info fs-3 me-4" />
							<div class="text-center text-md-start">
								Tu búsqueda coincide en fechas con alguno de
								<span class="fw-bold">nuestros viajes.</span>
								<br />
								<button
									class="fw-bold boton boton-no-fondo m-0 p-0"
									data-bs-toggle="modal"
									data-bs-target="#modalNuestrosViajes">
									¿Qué son nuestros viajes?
								</button>

								<button
									id="botonNuestrosViajes"
									data-bs-toggle="modal"
									data-bs-target="#modalNuestrosViajesRecomendacion"
									style="visibility: hidden" />
							</div>
						</div>
						<button
							class="boton boton-naranja fw-650 m-0 mt-2 mt-md-0 d-none d-sm-block py-2 px-4"
							data-bs-toggle="modal"
							data-bs-target="#modalCoincidencias">
							Ver coincidencias
						</button>
					</div>

					<!-- Hoteles -->
					<div v-if="hoteles.length > 0" class="hoteles">
						<div v-for="(hotel, hotelInd) in hoteles" :key="hotelInd" class="cuadro-hotel mt-4 d-flex flex-column">
							<!-- Imagen info y valoracion hotel-->
							<div
								class="d-flex flex-column flex-md-row justify-content-between align-items-start m-3 position-relative">
								<HotelImageCarousel :imagenes="hotel.imagenes" />
								<div class="d-flex align-items-center flex-row w-100">
									<div class="d-flex flex-column gap-1">
										<div class="fs-6 fw-bold">
											{{ hotel.nombre }}
										</div>
										<div class="ms-1 d-flex flex-column gap-1">
											<div>
												<i v-for="i in hotel.estrellas" :key="i" class="fa-regular fa-star" />
											</div>
											<div class="icono-poblacion d-flex align-items-center gap-1">
												<i class="fa-solid fa-location-dot fs-6" />
												<span class="texto-info-hotel">
													{{ hotel.ubicacion }}
												</span>
											</div>
											<div
												class="ver-mas-info d-flex align-items-center gap-1 cursor-pointer"
												@click="goToAlojamiento(hotel.id)">
												<i class="fa-solid fa-circle-info" />
												<span class="texto-info-hotel"> más información del alojamiento </span>
											</div>

											<div class="d-flex gap-2 ms-3 mt-1">
												<template v-for="(servicio, i) in hotel.serviciosDestacados" :key="i">
													<img
														v-if="servicio.img"
														:title="servicio.nombre"
														:src="servicio.img"
														class="icono-servicio" />
												</template>
											</div>
											<div v-if="hotel.valoracion >= 6" class="d-block d-sm-none">
												<span class="puntuacion-hotel" style="font-size: 1.5rem">
													{{ hotel.valoracion }}
												</span>
											</div>
										</div>
									</div>
								</div>

								<div
									v-if="hotel.valoracion >= 6"
									class="d-none d-sm-flex flex-column justify-content-center text-center mt-2 me-3 position-absolute top-0 valoracion-hotel d-md-block">
									<p class="texto-valoracion mb-0">Valoración</p>
									<span class="puntuacion-hotel">
										{{ hotel.valoracion }}
									</span>
								</div>
							</div>

							<!-- Opciones pension -->
							<div class="d-flex gap-2 mx-3 mt-4 scroll-button" style="overflox-x: auto">
								<template v-for="(traduccion, pension) in opcionesPension" :key="pension">
									<div
										v-if="Object.keys(hotel.pensiones).includes(pension)"
										class="boton-cuadro-azul"
										style="font-size: 0.7rem"
										:class="hotel.currentPension == pension ? 'boton-cuadro-azul-selected' : ''"
										@click="updateOpcionesPensionActual(hotelInd, pension)">
										{{ opcionesPension[pension] }}
									</div>
								</template>
							</div>

							<!-- Opciones forfait-->
							<template v-for="(lugaresExperiencia, pension) in hotel.pensiones" :key="pension">
								<div v-if="hotel.currentPension == pension" class="d-flex flex-column cuadro-forfaits mt-4 p-4">
									<!-- Opcion -->
									<div
										v-for="(combinaciones, lugarExperiencia, indexEstacion) in lugaresExperiencia"
										:key="lugarExperiencia">
										<div class="d-flex flex-md-row flex-column justify-content-between">
											<!-- Imagen, descripcion y istancia -->
											<div class="d-flex flex-column">
												<div class="d-flex gap-2">
													<div>
														<img
															class="img-estacion"
															:src="
																estacionesData != null && estacionesData[lugarExperiencia] !== undefined
																	? estacionesData[lugarExperiencia].imagen
																	: ''
															"
															alt="" />
													</div>

													<div class="texto-lugarExperiencia">
														<p class="mb-0 fw-bold">
															{{
																estacionesData != null && estacionesData[lugarExperiencia] !== undefined
																	? estacionesData[lugarExperiencia].nombre
																	: ''
															}}
														</p>
														<div
															v-if="hotel.distanciasPistas[lugarExperiencia]"
															class="d-flex d-md-none gap-1 align-items-center"
															style="font-size: 0.7rem">
															<div>
																<img class="icono-distancia-estacion" src="@/assets/icons/icono-telesilla.webp" />
															</div>
															<span class="texto-distancia-estacion">
																{{ datediff(fechasForfait[0], fechasForfait[1]) }}
																Días de forfait del
																{{ formatDatesFullMonth(fechasForfait[0]) }}
																{{ $t('general.al') }}
																{{ formatDatesFullMonth(fechasForfait[1]) }}
																en
																{{ store_buscador.filtrosSeleccionadosBuscador.destino.nombre }}
																<br />
																A
																{{ distanciaPistasString(hotel.distanciasPistas[lugarExperiencia].distanciaKM) }}
																{{ $t('alojamiento.deEstacionEsqui') }}({{
																	hotel.distanciasPistas[lugarExperiencia].nombreAccesoPista
																}})
															</span>
														</div>
														<p class="d-none d-sm-block">
															{{
																estacionesData != null && estacionesData[lugarExperiencia] !== undefined
																	? estacionesData[lugarExperiencia].texto
																	: ''
															}}
														</p>
													</div>
												</div>

												<div
													v-if="hotel.distanciasPistas[lugarExperiencia]"
													class="d-none d-md-flex gap-1 ms-4 align-items-center"
													style="font-size: 0.7rem">
													<div>
														<img class="icono-distancia-estacion" src="@/assets/icons/icono-telesilla.webp" />
													</div>
													<span class="texto-distancia-estacion">
														{{ datediff(fechasForfait[0], fechasForfait[1]) }}
														Días de forfait del
														{{ formatDatesFullMonth(fechasForfait[0]) }}
														{{ $t('general.al') }}
														{{ formatDatesFullMonth(fechasForfait[1]) }}
														en
														{{ store_buscador.filtrosSeleccionadosBuscador.destino.nombre }}
														<br />
														A
														{{ distanciaPistasString(hotel.distanciasPistas[lugarExperiencia].distanciaKM) }}
														{{ $t('alojamiento.deEstacionEsqui') }}({{
															hotel.distanciasPistas[lugarExperiencia].nombreAccesoPista
														}})
													</span>
												</div>
												<p class="d-block d-sm-none">
													{{
														estacionesData != null && estacionesData[lugarExperiencia] !== undefined
															? estacionesData[lugarExperiencia].texto
															: ''
													}}
												</p>
											</div>

											<div class="d-flex flex-column justify-content-center align-items-end mt-2 mt-md-0 desde-precio">
												<!-- Precio -->
												<div class="d-flex flex-column align-items-center mt-2 mt-md-0">
													<button
														class="boton boton-naranja fw-bold ms-0 boton-precio"
														@click="goToBuscadorAlojamiento(hotel, lugarExperiencia)">
														{{
															combinaciones[0].precioMedioReserva % 1 != 0
																? combinaciones[0].precioMedioReserva.toFixed(2)
																: combinaciones[0].precioMedioReserva
														}}{{agenciaStore.getCurrency}}
													</button>
													<span class="w-fit-content">por persona</span>
												</div>

												<!-- Cancelacion -->
												<div
													v-if="hasCombinacionCancelable(combinaciones)"
													class="texto-cancelacion fw-bold d-flex justify-content-end align-items-center gap-1 mt-1">
													<i class="fa-solid fa-circle-info" />
													{{ $t('alojamiento.cancelacionGratis') }}
												</div>
											</div>
										</div>
										<hr
											v-if="
												Object.keys(lugaresExperiencia).length > 1 &&
												indexEstacion !== Object.keys(lugaresExperiencia).length - 1
											"
											class="linea-opcion-estacion" />
									</div>
								</div>
							</template>
						</div>
					</div>
					<div v-if="hoteles && hoteles.length > 0" class="text-center mt-4">
						<Paginacion
							:cantidad-paginas="paginatorState.lastPage + 1"
							:pagina-actual="paginatorState.pag + 1"
							@enviar-pagina="cambiarPaginaEspecificaBuscador"
							@primera="cambiarPagina('FIRST')"
							@ultima="cambiarPagina('LAST')"
							@siguiente="cambiarPagina('NEXT')"
							@anterior="cambiarPagina('PREV')" />
					</div>
					<div v-else-if="hotelesStore && hotelesStore.length == 0" class="hoteles d-flex mt-4">
						No se han encontrado resultados con tu búsqueda, puedes realizar un nueva consulta.
						<!-- No existen resultados para la búsqueda. -->
					</div>
					<div v-else class="hoteles d-flex mt-4">
						No se han encontrado resultados con tu búsqueda, puedes realizar un nueva consulta.
						<!-- No existen resultados con los filtros especificados. -->
					</div>
				</div>
			</div>
			<div v-else-if="loadingHoteles" class="container row my-5 mx-auto gx-0">
				<Spinner />
			</div>
			<div v-else class="container row my-5 mx-auto gx-0">
				<p>
					No disponemos de alojamientos disponibles en las fechas seleccionadas, te recomendamos que realices una nueva
					búsqueda. Puedes buscar la disponibilidad dentro de nuestros viajes, si no has encontrado nada, escríbenos y
					te recomendamos.
				</p>
				<div class="d-flex justify-content-start">
					<button class="boton boton-naranja fw-650 m-0 mt-2 mt-md-0" type="button" @click="goToRecomendador()">
						{{ $t('home.banner.button') }}
					</button>
				</div>
			</div>
		</div>
	</div>

	<!-- MODALES -->
	<ModalSimple id-modal="modalNuestrosViajes" aria-labelled-by="modalNuestrosViajesLabel">
		<template #titulo>
			<div class="fw-bold texto-nuestros-viajes-modal">Nuestros viajes</div>
		</template>
		<template #contenido>
			<div class="texto-nuestros-viajes-modal">
				Únete a nuestros viajes con tus amigos y conoce a una comunidad de viajeros como tú. ¿A qué esperas para unirte
				a las mejores experiencias en la nieve?
			</div>
		</template>
	</ModalSimple>

	<ModalSimple id-modal="modalNuestrosViajesRecomendacion" aria-labelled-by="modalNuestrosViajesRecomendacionLabel">
		<template #titulo>
			<div class="fw-bold text-primary">Tu busqueda coincide en fechas con alguno de nuestros viajes</div>
		</template>
		<template #contenido>
			<div class="text-center">
				<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalCoincidencias">
					Ver coincidencia
				</button>
				<button class="btn btn-secondary" data-bs-dismiss="modal">Seguir con mi busqueda</button>
			</div>
		</template>
	</ModalSimple>

	<!-- MODALES -->
	<ModalSimple v-if="!loadingHoteles && hoteles" id-modal="modalFiltro" aria-labelled-by="modalFiltroLabel">
		<template #titulo>
			<div class="fw-bold texto-nuestros-viajes-modal">Filtro Viajes</div>
		</template>
		<template #contenido>
			<div class="filtros">
				<div class="d-flex justify-content-between align-items-center">
					<div class="cuadro-azul">Filtrar alojamientos</div>
					<button class="boton boton-no-fondo p-1" @click="resetFiltros()">Reestablecer filtros</button>
				</div>
				<div class="cuadro-filtros">
					<div class="px-2 seccion-nombres">
						<p class="ps-2 fw-650 mb-2">{{ $t('alojamiento.nombre') }}:</p>

						<div class="d-flex justify-content-between align-items-center mb-3">
							<input
								id="nombreFiltros"
								class="input-nombre"
								type="text"
								:placeholder="$t('alojamiento.nombre')"
								@keyup.enter="aniadirFiltrosNombre()" />
							<button class="boton-aniadir-nombre" @click="aniadirFiltrosNombre()">
								<i class="fa-solid fa-plus" />
							</button>
						</div>

						<div
							v-for="(filtroNombre, i) in filtros.nombres"
							:key="i"
							class="d-flex justify-content-between align-items-center mt-2">
							{{ filtroNombre }}

							<button class="boton-aniadir-nombre" @click="eliminarFiltrosNombre(i)">
								<i class="fa-solid fa-minus" />
							</button>
						</div>
					</div>

					<hr class="hr-filtro" />

					<div class="px-2 seccion-tipos-alojamiento">
						<p class="ps-2 fw-650 mb-2">Elige el tipo de alojamiento al que te gustaría ir</p>

						<li
							v-for="(tipo, i) in filtros.tipos"
							:key="i"
							class="d-flex justify-content-between align-items-center list-item"
							@click="updateTiposAlojamientoSelected(i)">
							<div>{{ firstLetterCapital(tipo.nombre) }}</div>
							<div>
								<i v-if="tipo.selected" class="fa-sharp fa-solid fa-circle-check text-secondary fa-xl" />
								<i v-else class="fa-regular fa-circle text-secondary fa-xl" />
							</div>
						</li>
					</div>

					<hr class="hr-filtro" />

					<div class="px-2 seccion-tipos-alojamiento mb-5">
						<p class="ps-2 fw-650 mb-3">Elige el rango de precio de tu viaje ({{agenciaStore.getCurrency}})</p>
						<div class="pb-2 mx-2">
							<SliderForm
								v-model="filtros.precios"
								tooltip-position="bottom"
								:min="preciosInicial.min"
								:max="preciosInicial.max"
								:step="10" />
						</div>
					</div>

					<hr class="hr-filtro" />

					<div class="px-2 seccion-tipos-alojamiento mb-5">
						<p class="ps-2 fw-650 mb-3">Elige la distancia a pistas (m)</p>
						<div class="pb-2 mx-2">
							<SliderForm
								v-model="filtros.distanciasPistas"
								tooltip-position="bottom"
								:min="distanciasPistasInicial.min"
								:max="distanciasPistasInicial.max"
								:step="10" />
						</div>
					</div>
				</div>
			</div>
		</template>
	</ModalSimple>

	<ModalSimple id-modal="modalBuscadorParaTi" aria-labelled-by="modalBuscadorLabel">
		<template #titulo>
			<div class="fw-bold texto-nuestros-viajes-modal">Buscador Viajes</div>
		</template>
		<template #contenido>
			<BuscadorParaTi />
		</template>
	</ModalSimple>

	<ModalSimple id-modal="modalCoincidencias" aria-labelled-by="modalCoincidenciasLabel">
		<template #titulo>
			<div class="fw-bold fs-4 mb-3">
				{{ store_buscador.filtrosSeleccionadosBuscador.destino.nombre }}
			</div>
		</template>
		<template #contenido>
			<div class="d-flex flex-wrap justify-content-center gap-3 lista-coincidencias-modal text-center">
				<template v-for="(oferta, i) in nuestrosViajes" :key="i">
					<div :class="!(i == 0 || i == 1 || i == 2) ? 'mt-4' : ''">
						<TarjetaHomeMejoresOfertas :oferta="oferta" />
					</div>
				</template>
			</div>
		</template>
	</ModalSimple>

	<div id="boton-subir" @click="goUp()">
		<i class="fa-solid fa-chevron-up hvr-grow" />
	</div>
</template>

<script setup>
	import Spinner from '@/components/SpinnerLoading.vue';
	import TarjetaHomeMejoresOfertas from '@/components/tarjetas/TarjetaHomeMejoresOfertas.vue';
	import BuscadorParaTi from '@/components/buscadores/BuscadorParaTi.vue';
	import ModalSimple from '@/components/modales/ModalSimple.vue';
	import { InformacionAlojamiento, BuscadorAlojamiento, Recomendador } from '@/router/RouterNames';
	import Paginacion from '@/components/misc/PaginacionButtons.vue';
	import { buscadorStore } from '@/store_pinia/buscador';
	import HeaderBuscadorParaTi from '@/components/header/headerBuscadorParaTi';
	import { firstLetterCapital } from '@/helpers/languageUtils';
	// import publicidadNavidad from '../../components/publicidad/publicidadNavidad.vue';
	import { formatDatesFullMonth } from '@/helpers/dateUtiles';
	import BuscadorContainer from '@/components/buscadores/BuscadorContainer.vue';
	import { ref, computed, watch } from 'vue';
	import { useRouter, useRoute } from 'vue-router';
	import SliderForm from '@vueform/slider';
	import HotelImageCarousel from '@/components/Carousel/HotelImageCarousel.vue';
	import { agenciaStoreModule } from '@/store_pinia/agencia';

	//LOGICA ALOJAMIENTOS
	const store_buscador = buscadorStore();
	const agenciaStore = agenciaStoreModule();
	const router = useRouter();
	const route = useRoute();
	const filtros = ref({
		nombres: [],
		tipos: [
			{
				nombre: 'Hotel',
				selected: false,
			},
			{
				nombre: 'Apartamento',
				selected: false,
			},
		],
		precios: [0, 0],
		distanciasPistas: [0, 0],
	});

	const loadingHoteles = computed(() => store_buscador.loadingHoteles);
	const hotelesStore = computed(() => store_buscador.hoteles);

	const nuestrosViajes = computed(() => store_buscador.nuestrosViajes);

	const opcionesPension = store_buscador.opcionesPension;
	const paginatorState = computed(() => store_buscador.paginatorState);
	const estacionesData = computed(() => {
		let lugaresExperiencia = {};
		if (store_buscador.filtrosBuscador != null) {
			store_buscador.filtrosBuscador.areas.forEach(ar => {
				ar.lugaresExperiencia.forEach(est => {
					lugaresExperiencia[est.id] = {
						imagen: est.icono,
						nombre: est.nombre,
						texto: est.descripcion,
					};
				});
			});
		}

		return lugaresExperiencia;
	});

	const preciosInicial = ref({
		min: 0,
		max: 0,
	});

	const distanciasPistasInicial = ref({
		min: 0,
		max: 0,
	});

	const hoteles = ref([]);

	const datediff = (first, second) => {
		return Math.round((second - first) / (1000 * 60 * 60 * 24)) + 1;
	};

	const obtenerPrimeraPension = pensionesHotel => {
		const pensionesTotales = Object.keys(opcionesPension);

		for (let i = 0; i < pensionesTotales.length; i++) {
			const elementoActual = pensionesTotales[i];

			if (pensionesHotel.includes(elementoActual)) {
				return elementoActual;
			}
		}

		return pensionesHotel[0];
	};

	const setUpHotelesAndFiltros = (limpiarFiltros = true) => {
		if (hotelesStore.value != null) {
			if (limpiarFiltros) {
				hotelesStore.value.forEach(hotel => {
					Object.values(hotel.pensiones).forEach(opcionesEstaciones => {
						Object.values(opcionesEstaciones).forEach(opcionesEstacion => {
							opcionesEstacion.forEach(opcion => {
								if (preciosInicial.value.min == 0 || opcion.precioMedioReserva < preciosInicial.value.min) {
									preciosInicial.value.min = opcion.precioMedioReserva | 0;
								}
								if (opcion.precioMedioReserva > preciosInicial.value.max) {
									preciosInicial.value.max = Math.ceil(opcion.precioMedioReserva);
								}
							});
						});
					});
				});

				hotelesStore.value.forEach(hotel => {
					Object.values(hotel.distanciasPistas).forEach(distanciaPistas => {
						if (
							distanciasPistasInicial.value.min == 0 ||
							distanciaPistas.distanciaKM * 1000 < distanciasPistasInicial.value.min
						) {
							distanciasPistasInicial.value.min = distanciaPistas.distanciaKM * 1000;
						}
						if (distanciaPistas.distanciaKM * 1000 > distanciasPistasInicial.value.max) {
							distanciasPistasInicial.value.max = distanciaPistas.distanciaKM * 1000;
						}
					});
				});

				//la siguiente accion hace trigger al watcher de filtros que setupea hoteles
				filtros.value = {
					nombres: [],
					tipos: [
						{
							nombre: 'Hotel',
							selected: false,
						},
						{
							nombre: 'Apartamento',
							selected: false,
						},
					],
					precios: [preciosInicial.value.min, preciosInicial.value.max],
					distanciasPistas: [distanciasPistasInicial.value.min, distanciasPistasInicial.value.max],
				};
			}

			const listaHoteles = [];

			hotelesStore.value.forEach(hotel => {
				listaHoteles.push({
					...hotel,
					currentImage: 0,
					currentPension: obtenerPrimeraPension(Object.keys(hotel.pensiones)),
				});
			});

			hoteles.value = listaHoteles;
		}
	};

	store_buscador
		.setFiltrosSeleccionadosBuscador(
			route.query.destino ? JSON.parse(route.query.destino) : null,
			route.query.fechasAlojamiento
				? JSON.parse(route.query.fechasAlojamiento).map(f => {
						return new Date(f);
				  })
				: null,
			route.query.fechasForfait
				? JSON.parse(route.query.fechasForfait).map(f => {
						return new Date(f);
				  })
				: null,
			route.query.habitaciones ? JSON.parse(route.query.habitaciones) : null
		)
		.then(() => {
			store_buscador.setHotelesYForfait().then(() => {
				setUpHotelesAndFiltros();
				store_buscador.setNuestrosViajes().then(() => {
					if (nuestrosViajes.value.length > 0) {
						document.getElementById('botonNuestrosViajes').click();
					}
				});
			});
		});

	const fechasForfait = computed(() => store_buscador.filtrosSeleccionadosBuscador.fechasForfait);

	const hasCombinacionCancelable = combinaciones => {
		return combinaciones.some(combinacion => combinacion.tipoCancelacion == 'TOTAL');
	};

	const opcionesOrdenacion = ['Más económicos', 'Menos económicos', 'Mejor valorados', 'Menos valorados'];

	const updateOpcionesPensionActual = (hotelInd, optKey) => {
		hoteles.value[hotelInd].currentPension = optKey;
	};

	// const goUp = () => {
	// 	window.scrollTo({
	// 		top:
	// 			document.getElementById('buscador-para-ti').offsetTop -
	// 			document.getElementById('desktop-navbar').offsetHeight,
	// 		left: 0,
	// 		behavior: 'smooth'
	// 	});
	// };

	const goToAlojamiento = id => {
		let routeData = router.resolve({
			name: InformacionAlojamiento,
			params: { idAlojamiento: id },
		});

		window.open(routeData.href, '_blank');
	};

	const goToRecomendador = () => {
		router.push({ name: Recomendador });
	};

	const goToBuscadorAlojamiento = (hotel, lugarExperiencia) => {
		store_buscador.estacionSeleccionada = lugarExperiencia;
		store_buscador.hotelSeleccionado = hotel;

		router.push({
			name: BuscadorAlojamiento,
			params: { idAlojamiento: hotel.id },
		});
	};

	//LOGICA FILTROS
	const opcionOrdenacionEscogida = ref(0);

	const updateOpcionOrdenacionEscogida = ind => {
		opcionOrdenacionEscogida.value = ind;
	};

	watch(opcionOrdenacionEscogida, () => {
		if (opcionesOrdenacion[opcionOrdenacionEscogida.value] == 'Mejor valorados') {
			store_buscador.paginatorState = {
				...store_buscador.paginatorState,
				orderDirection: 'DESC',
				orderedColumn: 'valoracion',
			};
			cambiarSizePagina('20', filtros.value);
		}

		if (opcionesOrdenacion[opcionOrdenacionEscogida.value] == 'Menos valorados') {
			store_buscador.paginatorState = {
				...store_buscador.paginatorState,
				orderDirection: 'ASC',
				orderedColumn: 'valoracion',
			};
			cambiarSizePagina('20', filtros.value);
		}

		if (opcionesOrdenacion[opcionOrdenacionEscogida.value] == 'Menos económicos') {
			store_buscador.paginatorState = {
				...store_buscador.paginatorState,
				orderDirection: 'DESC',
				orderedColumn: 'precio',
			};
			cambiarSizePagina('20', filtros.value);
		}

		if (opcionesOrdenacion[opcionOrdenacionEscogida.value] == 'Más económicos') {
			store_buscador.paginatorState = {
				...store_buscador.paginatorState,
				orderDirection: 'ASC',
				orderedColumn: 'precio',
			};
			cambiarSizePagina('20', filtros.value);
		}
	});

	const aniadirFiltrosNombre = () => {
		let element = document.getElementById('nombreFiltros');
		let value = element.value.trim();

		if (value != '') {
			filtros.value.nombres.push(document.getElementById('nombreFiltros').value);
			document.getElementById('nombreFiltros').value = '';
		}
		cambiarSizePagina('20', filtros.value);
	};

	const eliminarFiltrosNombre = ind => {
		filtros.value.nombres.splice(ind, 1);
		cambiarSizePagina('20', filtros.value);
	};

	const updateTiposAlojamientoSelected = ind => {
		filtros.value.tipos[ind].selected = !filtros.value.tipos[ind].selected;
		cambiarSizePagina('20', filtros.value);
	};

	const filtrarPrecios = () => {
		cambiarSizePagina('20', filtros.value);
	};

	const resetFiltros = () => {
		filtros.value.nombres = [];
		filtros.value.tipos.forEach(t => (t.selected = false));
		(filtros.value.precios = [preciosInicial.value.min, preciosInicial.value.maxs]),
			(filtros.value.distanciasPistas = [distanciasPistasInicial.value.min, distanciasPistasInicial.value.max]);
		cambiarSizePagina('20', filtros.value);
	};

	const distanciaPistasString = distanciaPista => {
		return distanciaPista >= 1 ? distanciaPista + ' km' : distanciaPista * 1000 + ' m';
	};

	//JS para boton subir al inicio
	let lastKnownScrollPosition = 0;
	let ticking = false;
	let showedBoton = false;

	function showBotonSubir(scrollPos) {
		if (document.getElementById('boton-subir') == null) return;

		if (scrollPos >= 210 && !showedBoton) {
			document.getElementById('boton-subir').style.display = 'block';
			showedBoton = true;
		} else if (scrollPos < 210 && showedBoton) {
			document.getElementById('boton-subir').style.display = 'none';
			showedBoton = false;
		}
	}

	document.addEventListener('scroll', () => {
		if (hoteles.value == null || loadingHoteles.value == true) {
			return;
		}

		lastKnownScrollPosition = window.scrollY;

		if (!ticking) {
			window.requestAnimationFrame(() => {
				showBotonSubir(lastKnownScrollPosition);
				ticking = false;
			});

			ticking = true;
		}
	});

	function cambiarPaginaEspecificaBuscador(numeroPagina) {
		store_buscador.paginatorState.pag = numeroPagina - 1;
		store_buscador.cambiarPagina(store_buscador.cacheId, 'FIRST', store_buscador.paginatorState);
	}

	function cambiarPagina(posicionPagina) {
		store_buscador.cambiarPagina(store_buscador.cacheId, posicionPagina, store_buscador.paginatorState).then(() => {
			setUpHotelesAndFiltros(false);
		});
	}

	function cambiarSizePagina(sizePagina, filtro) {
		store_buscador
			.cambiarSizePagina(store_buscador.cacheId, sizePagina, store_buscador.paginatorState, filtro)
			.then(() => {
				setUpHotelesAndFiltros(false);
				store_buscador.setNuestrosViajes();
			});
	}
</script>

<style lang="css" scoped>
	.slide-fade-enter-active {
		transition: all 0.7s ease-out;
	}

	.slide-fade-leave-active {
		transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateX(20px);
		opacity: 0;
	}

	.fw-650 {
		font-weight: 650 !important;
	}

	.w-fit-content {
		width: fit-content;
	}

	.buscador-header {
		background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9)),
			url('~@/assets/fotos/bola-cristal-background-buscador.webp');
		/*     background-repeat: no-repeat; */
		min-height: 80vh;
		background-size: contain;
		background-position: top;
		background-repeat: no-repeat;
		color: var(--bs-primary);
	}

	.seccion-proximas-reservas {
		padding-top: 14rem;
		min-height: 120vh;
	}

	.boton {
		cursor: pointer;
		font-size: 0.8rem;
		font-weight: 500;
		padding: 0.5rem 0.5rem;
		margin-left: 1rem;
		border-radius: var(--bs-border-radius-xxl);
		transition: all 0.2s ease;
		border: 0;
	}

	.scroll-button::-webkit-scrollbar {
		display: none;
	}

	.scroll-button {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		overflow-x: auto;
	}

	.boton-no-fondo {
		background-color: inherit;
		color: var(--bs-primary);
	}

	.boton-no-fondo:hover {
		text-decoration: underline;
	}

	.boton-no-fondo:active {
		color: #080a1d;
	}

	.boton-naranja {
		background-color: var(--bs-secondary);
		color: white;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
		font-size: 0.8rem;
	}

	.boton-naranja:hover {
		background-color: var(--bs-primary);
		border-color: var(--bs-primary);
	}

	.boton-naranja:active {
		outline: 2px solid rgba(33, 36, 68, 0.5);
	}

	.barra-lateral {
		width: 32%;
		height: fit-content;
		border-radius: var(--bs-border-radius-xl);
		padding: 1.5rem 1rem;
		background-color: #f9f7f8;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
	}

	.cuadro-mapa {
		border-radius: var(--bs-border-radius-xl);
		padding: 0.5rem;
		width: 100%;
		height: 125px;
		font-weight: 700;
		background-color: white;
	}

	.fondo-mapa {
		border-radius: var(--bs-border-radius-xl);
		width: 100%;
		height: 100%;
		background-image: url('~@/assets/fotos/fondo-mapa.webp');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.filtro-buscador {
		position: -webkit-sticky;
		position: sticky;
		top: 70px;
		z-index: 100;
		font-size: 0.7rem;
	}

	.valoracion-hotel {
		right: 0;
	}

	.filtros {
		font-size: 0.8rem;
	}

	.cuadro-azul {
		background-color: var(--bs-primary);
		color: white;
		padding: 0.5rem 1.4rem;
		font-size: 0.9rem;
		font-weight: 650;
		border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
		box-shadow: 2px -1px 5px 0 rgba(0, 0, 0, 0.5);
	}

	.cuadro-filtros {
		background-color: white;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
		padding: 1rem;
	}

	.hr-filtro {
		height: 1.25px;
		background-color: var(--bs-primary);
		opacity: 1;
	}

	.input-nombre {
		width: 100%;
		border-radius: var(--bs-border-radius-lg);
		padding: 0.5rem 1.5rem 0.5rem 0.8rem;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
		border-width: 0px;
	}

	.boton-aniadir-nombre {
		margin-left: -1.5rem;
		padding-right: 7px;
		outline: none;
		border-width: 0;
		background-color: inherit;
	}

	.input-nombre:focus {
		outline: none;
	}

	.list-item {
		cursor: pointer;
		margin-top: 0.5rem;
	}

	.imagen-hotel {
		background-image: url('~@/assets/img/special_workers/no-imagen-alojamiento.webp');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.8;

		width: 200px;
		height: 125px;
		border-radius: var(--bs-border-radius-xl);
	}

	.flechas-img-alojamiento {
		font-size: 1.5rem;
		padding: 0.5rem;
		cursor: pointer;
	}

	.icono-poblacion {
		padding-left: 0.06rem;
	}

	.ver-mas-info {
		cursor: pointer;
	}

	.texto-info-hotel {
		font-size: 0.7rem;
	}

	.icono-servicio {
		width: 1.15rem;
	}

	.texto-valoracion {
		font-size: 0.65rem;
	}

	.puntuacion-hotel {
		margin-top: -0.6rem;
		font-size: 2.2rem;
	}

	.boton-cuadro-azul {
		border: solid 1.5px var(--bs-primary);
		border-radius: var(--bs-border-radius);
		color: var(--bs-primary);
		background-color: inherit;
		padding: 0.2rem 1.2rem;
		cursor: pointer;
		box-shadow: 1px -0.5px 2px 0 rgba(0, 0, 0, 0.4);
	}

	.boton-cuadro-azul-selected {
		background-color: var(--bs-primary);
		color: white;
	}

	.info-ordenacion {
		background-color: var(--bs-primary);
		color: white;
		border-radius: var(--bs-border-radius-xl);
		padding-top: 0.7rem;
		padding-bottom: 0.7rem;
		z-index: 5;
		box-shadow: 0rem 0rem 0.7rem rgba(0, 0, 0, 0.5);
		font-size: 0.8rem;
		padding: 0.7rem;
	}

	.boton-dropdown {
		background-color: white;
		border-radius: var(--bs-border-radius-xl);
		min-width: 13rem;
		margin-left: -1rem;
		padding-left: 1.9rem;
		box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.5), -0.1rem -0.1rem 0.2rem rgba(255, 255, 255, 0.5);
	}

	.dropdown-item {
		padding: 0 0.5rem;
	}

	.cuadro-coincide {
		border-radius: var(--bs-border-radius-xxl);
		font-size: 0.85rem;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
		background-color: white;
	}

	.cuadro-hotel {
		border-radius: var(--bs-border-radius-xxl);
		font-size: 0.85rem;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
		background-color: white;
		overflow: hidden;
		width: 100%;
	}

	.cuadro-forfaits {
		border-radius: var(--bs-border-radius-xxl);
		width: 100%;
		background-color: #f9f7f8;
	}

	.img-estacion {
		width: 30px;
	}

	.texto-estacion {
		padding-top: 0.2rem;
	}

	.icono-distancia-estacion {
		width: 16px;
	}

	.texto-distancia-estacion {
		color: #0073a4;
	}

	.precio-hotel {
		width: 100px;
	}

	.texto-desde-precio {
		padding-top: 1.75rem;
	}

	.texto-por-persona {
		width: max-content;
	}

	.texto-cancelacion {
		color: #16ab0c;
	}

	.linea-opcion-estacion {
		height: 2px;
	}

	@media only screen and (max-width: 989px) {
		.seccion-proximas-reservas {
			padding-top: 7rem;
		}
	}

	@media only screen and (max-width: 767px) {
		.cuadro-coincide,
		.cuadro-hotel {
			border-radius: var(--bs-border-radius-xl);
		}
	}

	.texto-nuestros-viajes-modal {
		font-size: 0.95rem;
		text-align: justify;
	}

	.desde-precio {
		min-width: 170px;
	}

	.boton-precio {
		max-width: 80px;
		min-width: 70px;
	}

	#boton-subir {
		display: none;
		position: fixed;
		bottom: 0;
		right: 0;
		font-size: 3rem;
		color: var(--bs-secondary);
		margin-right: 1rem;
		margin-bottom: -1rem;
		cursor: pointer;
	}

	#boton-subir:hover {
		animation: hover-grow 0.25s forwards;
	}

	@keyframes hover-grow {
		from {
			transform: scale(1);
		}
		to {
			transform: scale(1.15);
		}
	}

	@media only screen and (max-width: 989px) {
		.buscador-desktop {
			height: 0px;
			overflow: hidden;
		}
	}

	.buscador-ocultar {
		border-radius: var(--bs-border-radius-xl);
	}

	#buscador-desktop3 {
		display: none;
	}

	.container-buscador-desktop {
		display: none;
	}

	@media only screen and (max-width: 989px) {
		.container-buscador-desktop {
			display: block;
		}
	}
</style>
