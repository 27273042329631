export default {
  "actions": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
  },
  "header": {
    "horarioAtencion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lun-Jeu de 9h00 à 18h30, Ven de 9h00 à 14h00"])},
    "ski": {
      "groups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos expériences"])},
        "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universitaires"])},
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écoles"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travailleurs"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
        "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familles"])},
        "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes privés"])}
      },
      "destinations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinations"])}
      },
      "stations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stations"])}
      }
    },
    "night": {
      "festivals": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivals"])}
      },
      "apres": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après-ski"])}
      }
    },
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous"])},
      "whoWeAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui sommes-nous?"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaillez avec nous"])}
    },
    "user": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])}
    },
    "login": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue!"])},
      "welcomePlanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenue à ", _interpolate(_named("planner")), "!"])},
      "continuarGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer avec Google"])},
      "tusDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos données"])},
      "restablecerPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
      "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer"])}
    }
  },
  "searcher": {
    "organized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos expériences"])},
    "personalized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expériences sur mesure"])},
    "destiny": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où voulez-vous aller?"])}
    },
    "date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez quand vous pouvez partir"])}
    },
    "datesAccomodation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates d'hébergement"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez quand vous voulez aller"])}
    },
    "datesForfait": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates du forfait"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates du forfait"])}
    },
    "rooms": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambres"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les chambres"])}
    },
    "groups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de groupe"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'où venez-vous?"])}
    }
  },
  "filters": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
    "alojamiento": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez plus d'hébergement"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les données suivantes et en cas de disponibilité d'une chambre, vous serez informé"])}
    }
  },
  "home": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "banner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIVEZ L'EXPÉRIENCE COMPLÈTE"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez nos expériences avec vos amis et découvrez une communauté de voyageurs comme vous. Choisissez une destination, les dates de voyage souhaitées et votre lieu de départ. Qu'attendez-vous pour rejoindre les meilleures expériences sur la neige?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommander une expérience"])},
      "eligeFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez quand voyager"])}
    },
    "festivales": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOS EXPÉRIENCES"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyagez avec vos amis vers nos destinations préférées où vous pourrez profiter de nos deux événements principaux : AWS Formi et WSF Pas de la Casa."])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])}
    },
    "buscadorSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre expérience sur mesure"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos voyages de groupe ne vous conviennent pas? Utilisez notre moteur de recherche pour configurer un voyage entièrement sur mesure."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller au moteur de recherche"])}
    },
    "porqueReservar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi réserver avec Ski&Night?"])},
      "skiEnjoy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKIER, PROFITER"])},
      "sleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DORMIR, "])},
      "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECOMMENCER"])},
      "texto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisez votre voyage à la neige vers n'importe quelle destination en Europe."])},
      "texto2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" sont les destinations parfaites pour profiter de la neige et des meilleures soirées."])},
      "texto3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec Ski&Night, nous vous offrons une expérience unique dans les meilleures destinations en plein paradis naturel. Nous vous offrons"])},
      "texto4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" et l'accès aux festivals pour que vous n'ayez à vous soucier que de vous amuser."])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hébergement, cours"])},
      "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location de matériel"])}
    },
    "packs": {
      "school": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages scolaires"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez entre 5 et 17 ans et que vous voulez profiter au maximum des journées de ski, c'est votre voyage. Améliorez votre technique et profitez de la nature, nous vous fournissons le transport, l'hébergement et les moniteurs."])}
      },
      "family": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages pour les familles"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous proposons le logement le plus confortable pour voyager à la neige avec votre famille. De plus, vous pourrez réserver des cours de ski pour les enfants et de nombreuses autres activités adaptées à vos besoins."])}
      },
      "university": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages pour les universitaires"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est votre voyage si vous voulez rencontrer des gens de votre âge lors des meilleurs festivals et après-ski. Osez vivre une expérience inoubliable dans la neige!"])}
      },
      "company": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages pour les travailleurs"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyager avec vos collègues de travail est très facile. Si vous avez plus de 25 ans, rejoignez nos voyages organisés pour rencontrer des personnes comme vous, où le ski et la vie nocturne vous surprendront."])}
      },
      "premium": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages Premium"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservez un voyage exclusif dans les meilleurs hébergements à la neige en complément des journées de ski avec des expériences uniques dans la neige : gastronomie, détente, sports extrêmes ou expériences culturelles."])}
      }
    },
    "festival": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter Snow Festival, notre festival"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chez Ski&Night, nous avons conçu l'expérience Winter Snow Festival, notre festival dans la neige où vous pourrez profiter des DJ et musiciens les plus exclusifs du moment en pleine montagne."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette année, nous célébrons la 4e édition du WSF, une expérience unique dans la neige que vous pourrez apprécier en Andorre aux côtés de plus de 5 000 personnes comme vous."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez nos festivals"])}
    },
    "apres": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meilleurs après-ski"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes spécialistes de l'organisation des meilleures fêtes dans la neige. Après une journée de ski, vous pourrez profiter de bonne musique avec nos DJ dans un cadre incomparable. Découvrez une sélection des après-ski les plus exclusifs :"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])}
    },
    "offers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meilleures offres"])}
    },
    "stations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meilleures stations de ski"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous organisons les meilleures escapades de ski à Formigal, Grandvalira et Sierra Nevada, entre autres stations. Jetez un œil aux stations où nous organisons des voyages et choisissez celle qui vous convient le mieux."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher tout"])}
    },
    "form": {
      "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
      "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
      "personalInformation": {
        "ADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recto du passeport"])},
        "alquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location de matériel"])},
        "altura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille (cm)"])},
        "anversoReversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recto et verso de la pièce d'identité téléchargés"])},
        "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
        "codPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
        "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre adresse e-mail"])},
        "passwordRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
        "ocultar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher"])},
        "mostrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer"])},
        "password8CharReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 8 caractères"])},
        "password1MayusReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 lettre majuscule"])},
        "password1NumReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 chiffre"])},
        "confirmnewpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le nouveau mot de passe"])},
        "contraseniaLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre mot de passe pour vous connecter"])},
        "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous le souhaitez, vous pouvez nous envoyer votre CV"])},
        "descripcionAlquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données sont indicatives, vous pourrez les essayer et les modifier en fonction de vos besoins dans le magasin lui-même."])},
        "diaHoraLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour et heure d'arrivée"])},
        "diaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour d'arrivée"])},
        "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport"])},
        "welcomeBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue de nouveau"])},
        "emailOtherLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous avec un autre compte"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
        "emailComercialesActivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails commerciaux actifs"])},
        "errorPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de validation du mot de passe, veuillez vérifier les informations d'identification."])},
        "expedicionDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de délivrance du passeport"])},
        "hombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
        "horaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure d'arrivée"])},
        "horaSalida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour et heure de départ"])},
        "howDid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment nous avez-vous connus?"])},
        "ingresaAltura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre taille"])},
        "ingresaPeso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre poids"])},
        "ingresaTallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre pointure"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
        "logeate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous"])},
        "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
        "msjADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il doit s'agir d'un fichier PDF au format PNG, JPG ou GIF"])},
        "msjcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour rendre votre mot de passe plus sécurisé, nous vous recommandons d'utiliser une combinaison de majuscules et de minuscules, de chiffres et de caractères spéciaux tels que *, %, !."])},
        "mujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
        "nacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
        "newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
        "noTienesCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de compte?"])},
        "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
        "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids (kg)"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
        "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
        "provincia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
        "RDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verso du passeport"])},
        "anversoReversoDnis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recto et verso du passeport"])},
        "registrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
        "errorRegPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit répondre à toutes les exigences de sécurité."])},
        "selecciona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "selectedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le document"])},
        "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
        "tallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pointure (EU)"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous nous dire quelque chose de plus sur vous?"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez ici"])},
        "noRecibirMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne souhaite pas recevoir de nouvelles et de communications commerciales."])},
        "aceptoMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte de recevoir des nouveautés et des communications commerciales."])},
        "emailLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre adresse électronique pour vous connecter ou vous enregistrer"])},
        "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
        "iniciarSesionRegistrarReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter ou s'inscrire pour réserver"])},
        "registradoCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistré avec succès"])}
      },
      "policy": {
        "readAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai lu et j'accepte les"])},
        "readAccepts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai lu et j'accepte les"])},
        "readAcceptsLos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai lu et j'accepte les"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et les"])},
        "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termes et conditions"])},
        "freePlacesPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique des places gratuites"])},
        "policyCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique d'annulation"])},
        "policyAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique d'hébergement en occupation complète"])},
        "titleCondicionesCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions et frais d'annulation"])},
        "msgCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conditions de réservation de l'expérience sont les suivantes:"])},
        "cancelReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation de la réservation"])},
        "descriptionCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le client renonce à l'expérience après la formalisation du dépôt exigé pour considérer la réservation comme REMBOURSABLE, il devra payer les montants suivants :"])},
        "descriptionCondicionesNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le client renonce à l'expérience après la formalisation du dépôt exigé pour considérer la réservation comme NON REMBOURSABLE, il devra payer les montants suivants :"])},
        "detail1Condiciones": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le ", _interpolate(_named("porcentaje")), "% s'il se produit entre ", _interpolate(_named("fechaInicio")), " et avant ", _interpolate(_named("fechaFin"))])},
        "detail2Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% s'il est dans les 15 jours naturels avant le départ."])},
        "detail3Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réservation de l'expérience ne sera en aucun cas remboursée"])},
        "detail4Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez également effectuer un changement de titulaire de la réservation pour qu'un collègue vous remplace, ce changement peut être effectué au plus tard 5 jours avant la date de début de l'expérience et peut entraîner des frais."])},
        "detail5Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les frais de gestion liés aux modifications de la réservation ou au non-respect des délais de paiement ne seront en aucun cas remboursables."])},
        "detail1Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du ", _interpolate(_named("fechaInicio")), " au ", _interpolate(_named("fechaFin")), " "])},
        "detail2Condiciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les suppléments et frais liés à des modifications de réservation ou dus au non-respect des délais de paiement et des frais ne seront en aucun cas remboursables."])},
        "detail3Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous pouvez modifier votre réservation gratuitement jusqu'au ", _interpolate(_named("fecha")), " dans la section Mes réservations de votre profil."])}
      },
      "realizarCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer le checkin"])},
      "Thanks": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous contacterons dans moins de 48 heures !"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour les informations. Vous aurez bientôt des nouvelles"])}
      },
      "TripInformation": {
        "accommodation": {
          "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartement"])},
          "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôtel"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas de préférence"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où préféreriez-vous séjourner ?"])}
        },
        "destiny": {
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune préférence, recommandez-moi !"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où voulez-vous aller ?"])}
        },
        "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien de personnes êtes-vous ?"])},
        "howManyDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien de jours voulez-vous skier ?"])},
        "interests": {
          "cousine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastronomie"])},
          "party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fête et ambiance"])},
          "relax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détente et spa"])},
          "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités dans la neige"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis intéressé par"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques de l'expérience"])},
        "tracks": {
          "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je veux être au pied des pistes"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela m'est égal de me déplacer"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance des pistes ?"])}
        },
        "whenTravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates de l'expérience"])},
        "whereFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'où voyagez-vous ?"])},
        "whoTravel": {
          "couple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couple"])},
          "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famille"])},
          "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amis"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec qui voyagez-vous ?"])},
          "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collègues de travail"])}
        }
      }
    }
  },
  "sponsors": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsors et partenaires"])}
  },
  "footer": {
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui sommes-nous ?"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travailler avec nous"])},
      "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])}
    },
    "saleGroups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres pour les groupes"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages scolaires"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages en famille"])},
      "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages universitaires"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages d'entreprise"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyages premium"])}
    },
    "saleSki": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres de ski"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski Pont de Décembre"])},
      "christmas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski de Noël"])},
      "newYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski du Nouvel An"])},
      "holyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski pendant la Semaine Sainte"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres offres"])}
    },
    "terminosCondiciones": {
      "orgTecJuridica": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. ORGANISATION TECHNIQUE ET RÉGLEMENTATION JURIDIQUE"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformément à la loi 34/2002 du 11 juillet, relative aux services de la société de l'information et du commerce électronique (LSSI), il est informé que le titulaire du site Web est Travel&Night S.L. (ci-après, TRAVEL&NIGHT) domicilié à C/ Gloria Fuertes, 4 5B, 28049, Madrid, Madrid, avec C.I.F. numéro B-87814125, inscrit au Registre du Commerce de la Communauté de Madrid."])}
      },
      "bono": {
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions Légales de Vente de Services par TRAVEL&NIGHT S.L. - NIF B87814125 L'achat de ce billet implique l'acceptation des conditions légales, de la politique de confidentialité et des cookies, que vous pouvez consulter sur le site d'achat. Ce bon peut être révoqué à tout moment par l'organisateur en proposant le remboursement du montant payé. L'organisation établit ses propres conditions légales pour l'accès et la vente de services, que vous devez consulter sur leurs médias ou demander explicitement à ceux-ci. Pour toute réclamation relative à l'organisation de l'événement ou de l'expérience, le détenteur de ce bon doit s'adresser à l'organisateur. Aucun changement ou remboursement n'est accepté, sauf ceux autorisés par l'organisateur. L'organisation se réserve le droit d'admission et peut refuser ou expulser le bénéfice de l'un des services faisant partie de l'expérience. TRAVEL&NIGHT n'est pas responsable des problèmes résultant de la contrefaçon, de la duplication ou de la vente illégale de ce billet. En cas de remboursements, l'organisateur se réserve le droit de ne pas retourner les frais de gestion car ceux-ci ont été correctement effectués par la plateforme."])}
      },
      "inscripciones": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. INSCRIPTIONS"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conditions d'inscription à une expérience seront détaillées dans le processus de réservation de celle-ci et prévaudront sur toute autre condition. En l'absence de telles conditions, il sera pris par défaut : L'inscription du client au voyage et la considération de la réservation comme ferme exigent le paiement d'une somme équivalente à 30% du prix total du voyage. Les 70% restants doivent être payés, au plus tard, 21 jours naturels avant le départ du voyage."])}
      },
      "condicionContractual": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. CONDITION CONTRACTUELLE"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réalisation du voyage en bus est conditionnée à l'inscription d'un minimum de 40 personnes. Dans le cas où le groupe ne se forme pas, l'agence peut annuler le voyage sans droit à indemnisation pour le client, à condition que l'agence le communique dans un délai maximal de 5 jours naturels avant la date prévue de départ."])}
      },
      "cancelaciones": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. ANNULATIONS"])},
        "descripcionA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a) Les réservations via le moteur de recherche web, le cas échéant, auront leurs conditions particulières acceptées par le client au moment de l'achat."])},
        "descripcionB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b) Les conditions d'annulation des réservations pour des expériences de groupe seront détaillées dans le processus de réservation de celle-ci et prévaudront sur toute autre condition. En l'absence de telles conditions, les suivantes seront prises par défaut :"])},
        "cancelacionGratuita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune annulation gratuite n'est acceptée dans aucun cas. Les conditions seront les suivantes :"])},
        "noReembolsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- La réservation n'est remboursable en aucun cas."])},
        "25%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 25% si l'annulation a lieu entre 45 et 30 jours naturels avant le départ."])},
        "50%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 50% si elle a lieu entre 30 et 21 jours naturels avant le départ."])},
        "100%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 100% si elle se produit dans les 20 jours naturels avant le départ."])},
        "derechoReembolso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client n'a droit à aucun remboursement du coût total de l'hébergement."])},
        "modifReembolso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Les frais de gestion associés aux modifications de la réservation ou au non-respect des délais de paiement de celle-ci ne seront en aucun cas remboursables."])}
      },
      "modificaciones": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. MODIFICATIONS"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toute modification ou changement peut être soumis à des frais de gestion associés en fonction du service et du délai de préavis requis."])}
      },
      "precioViaje": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. PRIX DU VOYAGE"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prix du voyage proposé sur ce site inclut la taxe sur la valeur ajoutée lorsque cela est applicable, mais n'inclut aucun type d'assurance ni les taxes des appartements. Les prix restent en vigueur jusqu'à communication du contraire. Le prix peut être révisé jusqu'à 20 jours naturels avant la date de départ en cas de variations dans le coût des transports (y compris le coût du carburant), dans les taxes et impôts relatifs à certains services (comme ceux de l'atterrissage, de l'embarquement ou du débarquement dans les ports et aéroports) et dans le taux de change appliqué au voyage. Face à la variation du prix, le client peut accepter le supplément ou bien renoncer au service qui aurait été modifié. La demi-pension, si elle était souscrite, comprend le petit-déjeuner et le dîner, les boissons au dîner et les extras de tout type NE sont pas inclus."])}
      },
      "establecimientosAlojamiento": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. ÉTABLISSEMENTS D'HÉBERGEMENT"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le contenu et la qualité des services fournis par l'établissement d'hébergement touristique sont déterminés par la catégorie touristique locale spécifiée dans cette brochure. TRAVEL&NIGHT se réserve le droit de changer un établissement particulier tant que la même catégorie souscrite est maintenue. Tout changement pour des raisons externes ou opérationnelles, tant que cette qualité mentionnée précédemment est maintenue, ne devra pas nécessairement impliquer de frais d'annulation gratuite pour le client. Dans le cas de l'hébergement en appartement, l'heure d'entrée sera à partir de 17h00 et le départ avant 11h00. Les enfants qui ont une place gratuite à l'hôtel, le régime sera uniquement d'hébergement et les frais engendrés seront payés directement à l'hôtel par les clients."])}
      },
      "documentacion": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. DOCUMENTATION"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le client est tenu de toujours porter sur lui son document national d'identité ou son passeport en cours de validité et original. Les mineurs de moins de 18 ans doivent porter un document les autorisant à voyager, signé par les deux parents, par le parent qui a la garde (en cas de mariages séparés ou divorcés) ou par le tuteur. Cette autorisation peut être traitée dans les commandements, commissariats et casernes des forces et corps de sécurité de l'État qui sont habilités à cet effet."])}
      },
      "responsabilidad": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. RESPONSABILITÉ"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agence de voyages sera exonérée de sa responsabilité pour la non-exécution ou l'exécution déficiente du contrat dans les cas suivants : lorsque cela est dû à une force majeure (circonstance étrangère, anormale et imprévisible), lorsqu'il s'agit d'un événement que ni l'agence organisatrice ni les fournisseurs n'auraient pu éviter même en ayant exercé la diligence nécessaire, ou lorsque la cause est imputable au client ou à un tiers. Si en raison de conditions météorologiques, de grèves ou d'autres motifs de force majeure, les services sont affectés, l'organisation s'engage à réaliser toutes les démarches nécessaires en faveur des passagers affectés pour ces situations, mais en tout cas, l'incidence économique, les frais d'hébergement, de subsistance, de transport et tout autre qui pourrait survenir en conséquence de cela, seront exclusivement à la charge du client, l'organisation étant exonérée de ces responsabilités car elles sont hors de son champ d'action. Le client est tenu de communiquer à l'agence organisatrice, dès que possible et par écrit, toute non-conformité dans l'exécution du contrat qu'il aurait constatée sur place."])}
      },
      "seguros": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. ASSURANCES"])},
        "descripcionA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel & Night opère avec les assurances exigées par le Décret Royal 271/1988 du 25 mars, qui réglemente l'exercice des activités propres aux Agences de Voyages."])},
        "descripcionB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les assurances nominatives seront à la charge des clients, si tel est le cas, telles que les assurances annulation ou de couverture pendant le déroulement des voyages."])}
      },
      "normas": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11. RÈGLES INCONTOURNABLES"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans les voyages en autocar, chaque client ne pourra emporter qu'une valise et du matériel de ski. Il n'est pas permis d'emporter des caisses ni des animaux. Pendant le voyage en autocar, le client est tenu de respecter la coexistence pacifique avec les autres voyageurs et de se conformer aux règles propres à l'entreprise de transport (ne pas fumer, ne pas manger, ne pas provoquer de troubles, etc.). Le client doit s'acquitter sur place des cautions des appartements. Ni les taxes propres à chaque hébergement ni les cautions ne sont incluses dans le prix du voyage. Les cautions seront restituées à la fin de l'expérience par l'hébergement, une fois vérifié le bon état de l'appartement par l'agence immobilière responsable. L'entreprise organisatrice ne prend en charge, en aucun cas, tout litige qui pourrait résulter de l'hébergement des clients, mais tentera toujours de médier pour essayer d'obtenir un résultat juste dérivé des conséquences de celui-ci."])}
      },
      "datosPersonales": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12. PROTECTION DES DONNÉES PERSONNELLES"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agence de voyages sera exonérée de sa responsabilité pour la non-exécution ou l'exécution déficiente du contrat dans les cas suivants : lorsque cela est dû à une force majeure (circonstance étrangère, anormale et imprévisible), lorsqu'il s'agit d'un événement que ni l'agence organisatrice ni les fournisseurs n'auraient pu éviter même en ayant exercé la diligence nécessaire, ou lorsque la cause est imputable au client ou à un tiers. Si en raison de conditions météorologiques, de grèves ou d'autres motifs de force majeure, les services sont affectés, l'organisation s'engage à réaliser toutes les démarches nécessaires en faveur des passagers affectés pour ces situations, mais en tout cas, l'incidence économique, les frais d'hébergement, de subsistance, de transport et tout autre qui pourrait survenir en conséquence de cela, seront exclusivement à la charge du client, l'organisation étant exonérée de ces responsabilités car elles sont hors de son champ d'action. Le client est tenu de communiquer à l'agence organisatrice, dès que possible et par écrit, toute non-conformité dans l'exécution du contrat qu'il aurait constatée sur place."])},
        "apartados": {
          "responsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESPONSABLE"])},
          "responsableDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel&Night S.L."])},
          "finalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FINALITÉ"])},
          "finalidadDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuaire de contacts et gestion financière, administrative et commerciale des activités développées par Travel&Night S.L."])},
          "legitimacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LÉGITIMATION"])},
          "legitimacionDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célébration et exécution d'un contrat et consentement de l'intéressé."])},
          "destinatario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DESTINATAIRE"])},
          "destinatarioDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos données seront cédées aux entreprises chargées de l'hébergement réservé, aux centrales de réservation d'hôtels et d'appartements, aux prestataires de services externes et aux entités assureurs et dans tous les cas où cela est établi par obligation légale."])},
          "derechos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DROITS"])},
          "derechosDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'accès, de rectification, de suppression, de limitation du traitement, de portabilité et d'opposition des données tel qu'expliqué dans les informations supplémentaires."])},
          "adicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMATIONS SUPPLÉMENTAIRES"])},
          "adicionalDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez consulter les informations supplémentaires et détaillées sur notre Politique de Protection des Données en envoyant une demande à "])}
        }
      }
    },
    "copyright": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juan Hurtado de Mendoza, 17 posterior, 1º Dcha, 28036 - Madrid, Espagne"])},
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])},
      "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de cookies"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales"])}
    },
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez-nous !"])}
  },
  "cookies": {
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte tous les cookies"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons des cookies pour améliorer nos services, analyser et personnaliser votre navigation. Pour continuer à naviguer sur notre site Web, vous devez accepter leur utilisation. Vous pouvez modifier les paramètres ou obtenir plus d'informations dans notre"])},
    "extras": {
      "analiticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
      "necesarias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nécessaires"])}
    },
    "gestionar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les cookies"])},
    "politicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique en matière de cookies"])},
    "preguntas": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QU'EST-CE QUE LES COOKIES ?"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTORISATION POUR L'UTILISATION DES COOKIES ?"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TYPES DE COOKIES UTILISÉES ?"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMENT DÉSACTIVER OU SUPPRIMER LES COOKIES ?"])}
    },
    "respuestas": {
      "1": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies sont des fichiers texte stockés sur l'appareil de l'utilisateur qui navigue sur Internet et qui, en particulier, contiennent un numéro permettant d'identifier de manière unique ledit appareil, même s'il change d'emplacement ou d'adresse IP."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies sont installés pendant la navigation sur Internet, soit par les sites Web que l'utilisateur visite, soit par des tiers avec lesquels le site Web est en relation, et permettent à ce dernier de connaître son activité sur le même site ou sur d'autres avec lesquels il est en relation, par exemple : le lieu d'accès, la durée de connexion, l'appareil à partir duquel il se connecte (fixe ou mobile), le système d'exploitation et le navigateur utilisés, les pages les plus visitées, le nombre de clics effectués et des données sur le comportement de l'utilisateur sur Internet."])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies sont associés uniquement à votre navigateur et ne fournissent pas par eux-mêmes de données personnelles. Les cookies ne peuvent pas endommager votre appareil et sont également très utiles, car ils nous aident à identifier et à résoudre les erreurs. Le site Web est accessible sans que les cookies soient activés, bien que leur désactivation puisse empêcher son bon fonctionnement."])}
      },
      "2": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformément à l'avis sur les cookies qui apparaît en bas de page du site Web, l'utilisateur ou visiteur des mêmes accepte qu'en naviguant sur celui-ci, il consent expressément à l'utilisation de cookies selon la description détaillée ci-dessous, sauf dans la mesure où il aurait modifié la configuration de son navigateur pour refuser leur utilisation."])}
      },
      "3": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le propriétaire du site Web utilise des cookies strictement nécessaires pour faciliter une navigation correcte sur le site Web, ainsi que pour assurer que son contenu est chargé efficacement. Ces cookies sont également des cookies de session, c'est-à-dire qu'ils ont un caractère temporaire et expirent et sont automatiquement effacés lorsque l'utilisateur ferme son navigateur."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies de Facebook Ireland Lt, utilisés pour permettre aux visiteurs d'interagir avec le contenu de Facebook et générés uniquement pour les utilisateurs de ces réseaux sociaux. Les conditions d'utilisation de ces cookies et les informations collectées sont régies par la politique de confidentialité de la plateforme sociale correspondante."])}
      },
      "4": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer et gérer les cookies sur Internet Explorer"])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer, activer et gérer les cookies sur Chrome"])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les cookies sur Firefox"])},
        "bloque4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration Web de Safari sur l'iPhone, l'iPad et l'iPod touch"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies peuvent être désactivés et supprimés en suivant les guides suivants en fonction du navigateur utilisé :"])}
      }
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLITIQUE CONCERNANT LES COOKIES"])}
  },
  "us": {
    "whoWeAre": {
      "banner": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À PROPOS DE NOUS"])},
        "text": {
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes une plateforme numérique qui révolutionne le secteur du ski en concevant des expériences dans la neige, que ce soit en individuel ou en groupe. Depuis nos débuts, nous avons accompagné plus de 30 000 voyageurs dans toutes les stations d'Espagne, d'Andorre et de France."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre spécialité est de connecter les voyageurs et de créer des communautés à travers les loisirs et le sport dans la neige. Nous disposons des meilleurs après-ski et organisons notre propre festival dans la neige, le Winter Snow Festival en Andorre. De plus, nous proposons des voyages sur mesure pour les familles, les entreprises, les écoles et les gourmets, nous adaptant aux besoins et aux goûts de nos voyageurs, en leur offrant la meilleure offre de détente, de restauration et de loisirs."])}
        }
      },
      "team": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÉQUIPE SKI&NIGHT"])}
      },
      "values": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALEURS DE SKI&NIGHT"])},
        "enviroment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiance jeune et conviviale"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chez Ski&Night, nous sommes une entreprise jeune, composée d'une équipe proche et flexible, ce qui nous permet de bien connaître le produit que nous vendons."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bonne humeur se transmet lors de la création d'expériences dans la neige et dans notre capacité à nous adapter aux besoins de nos voyageurs, que ce soit pour les universitaires, les écoles, les entreprises, les gourmets ou les familles."])}
        },
        "honesty": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honnêteté et transparence"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'honnêteté est l'une des clés de notre entreprise, étant notre principe de base dans nos relations avec nos voyageurs et nos fournisseurs."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depuis nos débuts, plus de 25 000 voyageurs nous ont fait confiance, et un pourcentage élevé renouvelle son expérience avec S&N, avec un taux de fidélité atteignant 83,8%."])}
        },
        "compromise": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement et sentiment d'appartenance"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La marque Ski&Night s'est imposée comme un style de leadership et d'engagement, une communauté qui a évolué depuis ses débuts pour devenir une marque leader dans le secteur de la neige."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un engagement qui s'étend non seulement à la relation avec les voyageurs, mais aussi aux fournisseurs, aux stations et à l'environnement naturel dans lequel nous exerçons notre activité."])}
        },
        "figures": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRANDES CHIFFRES MONDIAUX"])},
          "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNÉES D'EXPÉRIENCE"])},
          "travelers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOYAGEURS"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOYAGES EN GROUPE"])},
          "collaborations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COLLABORATIONS"])}
        },
        "season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RÉSUMÉ SAISON 2021/22"])}
      }
    }
  },
  "experencia": {
    "colegios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tienes entre 5 y 17 años y quieres aprovechar al máximo los días de esquí, este es tu experencia. Mejora tu técnica y disfruta de la naturaleza, nosotros te facilitamos el transporte, alojamiento y monitores."])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es tu experencia si quieres coincidir con gente de tu edad en los mejores festivales y après-skis. ¿Te atreves a vivir una experiencia inolvidable en la nieve?"])},
    "familias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te ofrecemos el alojamiento más cómodo para viajar a la nieve con tu familia. Además, podrás contratar clases de esquí para niños y muchas otras actividades adaptadas a tus necesidades."])},
    "IntroduceCodViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce el código de tu experencia"])},
    "trabajadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viajar con tus compañeros de trabajo es muy fácil. Si tienes más de 25 años, únete a nuestras experiencias organizados para coincidir con gente como tú, en las que el esquí y el ocio nocturno te sorprenderán."])}
  },
  "porqueReservar": [
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confort de paiement"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décidez si vous préférez payer le voyage en totalité ou seulement votre part, et consultez l'état dans votre profil."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service personnalisé"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre personnel de service client et nos guides sur place répondront à toutes vos questions."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibilité dans vos voyages"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les extras et les services dont vous avez besoin et personnalisez votre voyage au maximum."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loisirs à votre mesure"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profitez d'une large offre de détente, d'activités dans la neige, de gastronomie et de vie nocturne."])}
    }
  ],
  "viajesCatalogo": {
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voyages disponibles"])},
    "tituloFiltroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villes"])},
    "placeholderSelectMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un mois"])},
    "tituloFiltroLugarExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de l'expérience"])},
    "tituloFiltroPrecios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "tituloFiltroFestivales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivals"])},
    "filtroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les villes que vous souhaitez visiter"])},
    "filtroPrecios": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choisissez la fourchette de prix (", _interpolate(_named("currency")), ") dans laquelle vous souhaitez rechercher"])},
    "filtrosFestivales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les festivals auxquels vous souhaitez participer"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez l'un de ces voyages en groupe et profitez de l'expérience avec vos amis. Vous pouvez réserver une nouvelle chambre ou rejoindre une chambre existante"])},
    "descriptionPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservez l'un de nos voyages exclusifs à la neige avec des expériences de détente, gastronomiques, culturelles ou extrêmes. Vous pouvez également nous contacter directement au <a href='https://wa.me/+34910054547' target='_blank'><span class='mx-2 text-primary'> +34 910054670 </span></a> et nous concevrons un voyage entièrement adapté à vos besoins."])}
  },
  "seleccionAlojamientos": {
    "1availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement disponible"])},
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergements disponibles"])},
    "descriptionTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour votre expérience en groupe, nous avons ces hébergements disponibles"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les emplacements"])},
    "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hébergements"])},
    "1places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hébergement"])},
    "ordenarPor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])},
    "ordenarMenorMayor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par prix croissant"])},
    "ordenarMayorMenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par prix décroissant"])}
  },
  "mejoresOfertas": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meilleures offres"])}
  },
  "porqueReservaTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi réserver chez Ski and Night ?"])},
  "porqueReservaTitulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi réserver chez Sea and Night ?"])},
  "habitacionBuscador": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aidez-nous à trouver le meilleur prix, organisez vos chambres"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le nombre de chambres/appartements"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre"])},
    "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adultes"])},
    "ninos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfants"])},
    "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfant"])},
    "edadNino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge des enfants"])}
  },
  "viajesGrupos": {
    "todosPublicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les publics"])},
    "mas25anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 25 ans"])},
    "de18a25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 18 à 25 ans"])},
    "de0a17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 0 à 17 ans"])},
    "msjPlazasLibres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["places libres dans la chambre, en cas de rester libres un supplément sera assumé de"])},
    "msjRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour chaque place libre à répartir entre les membres de la chambre au titre de 'Reste de l'hébergement'"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "plazaLibre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place libre"])},
    "invitarCorreo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez inviter par email en cliquant sur le bouton."])},
    "llenarInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les informations de Parlez-nous un peu plus de vous."])},
    "faltaPoliticasPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les politiques de confidentialité et la politique d'hébergement en occupation complète doivent être confirmées."])},
    "faltaPoliticasCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les politiques d'annulation doivent être confirmées."])},
    "faltaRespuestasFormulario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les questions du formulaire doivent être répondues."])}
  },
  "reserva": {
    "misReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes réservations"])},
    "reactivarParaRecuperla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton pour la réactiver"])},
    "proximasReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos prochaines réservations"])},
    "misQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes QRs"])},
    "proximasNights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes prochains billets"])},
    "mensajeCompra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous ferez un achat, il apparaîtra ici"])},
    "estadoPagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État du paiement de la réservation"])},
    "pendientePago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de paiement"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de la réservation"])},
    "reservaEncuentra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette réservation se trouve"])},
    "miViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon voyage"])},
    "verQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les QR"])},
    "reservasCancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les réservations que vous souhaitez annuler"])},
    "mensajeCompraExp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cliquez ", _interpolate(_named("aqui")), " pour voir toutes les expériences disponibles"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
    "paga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paye"])},
    "totalPagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payé"])},
    "saldoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
    "graciasReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir réservé"])},
    "noReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore de réservations"])},
    "noFiestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore de réservations de fêtes"])},
    "moreAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parlez-nous de vous"])},
    "howPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez comment vous souhaitez payer"])},
    "complementos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléments"])},
    "suplementos": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppléments et dépenses"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppléments et dépenses"])},
      "PLAZAS_LIBRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Places libres"])}
    },
    "tasas": {
      "externas": {
        "TASA_TURISTICA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe de séjour"])}
      },
      "internas": {
        "TASA_GESTION_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe de gestion de la plateforme"])},
        "TASA_GESTION_CAMBIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe de gestion du changement"])}
      }
    },
    "tasa": {
      "externas": {
        "turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe touristique"])}
      },
      "gestion-plataforma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de gestion de la plateforme"])},
      "gestion-cambio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de gestion de changement"])}
    },
    "pagos": {
      "pago": {
        "senial": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acompte"])}
        },
        "total": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement total de l'expérience"])}
        },
        "viaje": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement partiel"])}
        }
      }
    },
    "tipo": {
      "PC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pension complète"])},
      "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demi-pension"])},
      "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre petit-déjeuner"])},
      "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre seule"])},
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petit-déjeuner seul"])}
    },
    "superGrupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super-Groupe"])},
      "informacionTransporte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le transport"])},
      "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur votre voyage"])},
      "fiestaContradas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fêtes réservées"])},
      "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un super-groupe"])},
      "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre un super-groupe"])},
      "msjNoGrupoamigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre groupe n'a toujours aucun groupe ami. Créez un super-groupe ou rejoignez-en un existant avant la date de commande pour voyager dans le même bus et séjourner dans des chambres aussi proches que possible."])},
      "msjSuperGruposEdificio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les super-groupes ne seront pris en compte que pour les bâtiments proches en raison du point d'arrivée"])},
      "pertenceSuperGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous appartenez au super-groupe"])},
      "msjPuntoLlegado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les super-groupes ne seront pris en compte que pour les bâtiments proches en raison du point d'arrivée"])}
    },
    "plazasLibres": {
      "modal": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il reste encore des places disponibles dans votre réservation !"])},
        "tituloSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il reste encore ", _interpolate(_named("plazasLibres")), " place libre dans votre réservation !"])},
        "tituloPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il reste encore ", _interpolate(_named("plazasLibres")), " places libres dans votre réservation !"])},
        "bodySingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il y a de la place pour ", _interpolate(_list(0)), " personne de plus dans votre réservation. Un supplément de ", _interpolate(_list(1)), " sera ajouté à chaque personne confirmée. Si les places libres sont finalement occupées, le supplément sera remboursé."])},
        "bodyPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il y a de la place pour ", _interpolate(_list(0)), " personnes de plus dans votre réservation. Un supplément de ", _interpolate(_list(1)), " sera ajouté à chaque personne confirmée. Si les places libres sont finalement occupées, le supplément sera remboursé."])},
        "addSuplemento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un supplément à la réservation"])},
        "genteReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il manque des gens à la réservation"])},
        "completarPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplir les places"])}
      }
    },
    "grupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
      "msjGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupez votre réservation avec d'autres. Les groupes seront pris en compte pour les services de l'expérience, par exemple lors de la distribution des transports. Pour créer un groupe, envoyez le lien aux utilisateurs des autres réservations."])},
      "miembrosGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres du groupe"])}
    },
    "bono": {
      "descargarBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le bon"])},
      "fechaIni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
      "fechaFin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
      "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "informacionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In"])},
      "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Out"])},
      "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime"])},
      "impuestosIncluidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes incluses"])},
      "incluidoPaqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclus dans le forfait de base"])},
      "servicioExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service supplémentaire"])},
      "localizador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisateur"])},
      "titular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titulaire"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email de contact"])}
    },
    "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus d'informations sur l'hébergement"])},
    "msjFechaLimite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date limite pour effectuer tout paiement ou modification de services gratuitement sera avant le jour"])},
    "msjPagoRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement de votre voyage a déjà été effectué. Soyez attentif au cas où des fêtes seraient publiées."])},
    "msjNoPagos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il vous reste ", _interpolate(_named("pendientePago")), _interpolate(_named("currency")), " à payer."])},
    "reservasDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservation/s de"])},
    "mantenerReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenir la réservation"])},
    "msjSolucionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous pouvons vous aider à trouver une solution alternative si vous devez apporter des modifications à votre réservation"])},
    "proximosViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expériences à venir :"])},
    "condicionesReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conditions de réservation pour le voyage sont les suivantes"])},
    "concepto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "recuperarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer la réservation"])},
    "codViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est le code de votre voyage"])},
    "mensajeThankYouSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez ", _interpolate(_named("plazaslibres")), " place libre, partagez le lien pour les compléter ou un supplément de ", _interpolate(_named("suplemento")), " sera ajouté à chaque personne confirmée."])},
    "mensajeThankYouPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez des ", _interpolate(_named("plazaslibres")), " places libres, partagez le lien pour les compléter ou un supplément de ", _interpolate(_named("suplemento")), " sera ajouté à chaque personne confirmée."])},
    "mensajeThankYouCompleto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre réservation comprend les personnes suivantes:"])},
    "mensajeThankYouAnular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["S'il n'est pas terminé à la ", _interpolate(_named("fechaLimite")), ", il sera automatiquement annulé."])},
    "mensajeFechaLimite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Délai de paiement: ", _interpolate(_named("fechaLimite")), "."])},
    "repartirCoste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le nombre de places n'est pas atteint, un supplément sera ajouté pour chaque personne confirmée."])},
    "mensajeExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes impatients de vous voir et de profiter de l'expérience ensemble."])},
    "msjAnadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ajouter quelques extras si vous les avez oubliés"])},
    "anadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des extras"])},
    "masExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'extras"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "serviciosContrados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services contractés"])},
    "realizarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer le paiement"])},
    "cambioHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de chambre"])},
    "informesTotales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports totaux"])},
    "cancelacionEntre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si l'annulation se produit entre le"])},
    "costesAsociadosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les coûts associés à l'annulation de la réservation sont les suivants :"])},
    "msjReservaDescartada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réservation a été abandonnée"])},
    "msjReservaActiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà une réservation pour ce voyage, choisissez un autre voyage ou consultez vos réservations"])},
    "descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduction"])},
    "descuentoCod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de réduction"])},
    "descuentoCodIntrod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez-le ici"])},
    "descuentoCodError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le code de réduction '", _interpolate(_named("codigo")), "'. Ce n'est pas valable."])},
    "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait de base"])},
    "suplementosGastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppléments et dépenses"])},
    "msjPendientePagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez une réservation en attente de paiement pour ce voyage. Souhaitez-vous l'annuler ou continuer avec celle-ci ?"])},
    "revisaDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez les données de votre réservation"])},
    "pagarTotalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer le montant total de la réservation"])},
    "enlaceUnirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la fin de la réservation, envoyez le lien que nous fournirons au reste des personnes afin qu'elles puissent rejoindre"])},
    "recuerdaPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'oubliez pas que vous pouvez payer la réservation en totalité ou seulement une partie"])},
    "pagarAhora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payez maintenant"])},
    "abonarAntesDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et payez le montant restant avant"])},
    "pagarPlazos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payez en plusieurs fois."])},
    "reservaCancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservation annulée"])},
    "msjCheckInReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour accélérer le processus de check-in à votre arrivée, veuillez remplir vos informations. Vous pouvez remplir vos propres données ou celles de votre chambre/appartement."])},
    "msjDatosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez consulter les données de réservation des personnes pour lesquelles vous avez effectué le premier paiement"])},
    "disponeCodReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous disposez d'un code de réservation"])},
    "msjFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelqu'un a parlé de fête"])},
    "msjNoFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas encore de fêtes disponibles pour aucun de vos voyages. Dès qu'elles seront confirmées, vous serez notifié par e-mail pour que vous puissiez réserver votre place."])},
    "msjAmigoReservado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez un ami qui a déjà réservé et que vous souhaitez vous joindre à sa chambre, cliquez sur le bouton ci-dessous"])},
    "gastos": {
      "gasto": {
        "tasa-turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de taxe de séjour"])},
        "gestion": {
          "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais supplémentaires de gestion"])}
        }
      },
      "FEE_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de gestion de la plateforme"])},
      "incremento": {
        "camas": {
          "vacias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais d'augmentation pour les lits vides"])}
        }
      },
      "recargo": {
        "pago": {
          "tardio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de paiement tardif"])}
        }
      },
      "complemento": {
        "plazas": {
          "libres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplément pour places libres"])}
        }
      },
      "visualizacionOcupantes": {
        "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne"])},
        "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
        "libre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libre"])}
      }
    }
  },
  "rooming": {
    "miRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Rooming"])},
    "resena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laissez-nous un avis"])},
    "siguenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez-nous"])},
    "gestorRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionnaire de Rooming"])},
    "tooltipAnadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois que vous avez cliqué ici, sélectionnez les membres dans le tableau à droite"])},
    "organizarDistribucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organiser la Distribution"])},
    "tooltipMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la chambre que vous souhaitez distribuer puis les personnes que vous souhaitez mettre dans cette chambre à partir du tableau de gauche"])},
    "cardHabitacion": {
      "anadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Ajouter des membres"])},
      "selectFromTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les membres dans le tableau"])}
    },
    "estado": {
      "NUEVO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
      "EN_EDICION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En modification"])},
      "PUBLICADO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié"])}
    },
    "tabla": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement"])},
      "sinRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non répartis"])},
      "repartidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répartis"])},
      "buscarNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un nom"])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])}
    },
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "hasTerminado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous terminé?"])},
    "guardarSalir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et quitter"])},
    "guardarSalirMensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous n'avez pas terminé la distribution et souhaitez continuer plus tard, cliquez sur 'Enregistrer et quitter'. Si vous avez terminé, cliquez sur 'Envoyer', notez qu'une fois envoyé, aucun changement ne pourra être apporté."])},
    "error": {
      "publicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la publication de votre rooming."])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'enregistrement de votre voyage."])}
    }
  },
  "alojamiento": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleures offres"])},
    "nuestrasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos expériences"])},
    "lineUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmation confirmée"])},
    "imagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
    "proximamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt disponible..."])},
    "verImagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les images"])},
    "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie"])},
    "sinFotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de photos disponibles"])},
    "compartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager"])},
    "tipos": {
      "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie"])},
      "apartamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartement"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôtel"])},
      "albergue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auberge"])},
      "estudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio"])},
      "no aplica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non applicable"])}
    },
    "todasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir toutes les expériences"])},
    "consigueEntrada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez votre billet"])},
    "consigueAbono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez votre abonnement"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOIR PLUS >"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOIR MOINS >"])},
    "verFoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir toutes les photos"])},
    "verMapa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la carte"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "compartirLanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regarde le voyage que j'ai trouvé !"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'hébergement"])},
    "ofertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres"])},
    "preguntasFrecuentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foire aux questions"])},
    "horariosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires et conditions spéciales"])},
    "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement"])},
    "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
    "distancias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distances"])},
    "accesoPistas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès aux pistes"])},
    "puntoInteres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points d'intérêt"])},
    "descripcionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de l'hébergement"])},
    "regimenDisponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régimes disponibles et types de chambres"])},
    "deEstacionEsqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la station de ski"])},
    "cancelacionGratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation GRATUITE"])},
    "parcialmenteReembolsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partiellement remboursable"])},
    "otrasOpcionesViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres options de voyage"])},
    "servicioHotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services de l'hôtel"])},
    "ubicacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement"])},
    "fechaAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'hébergement"])},
    "filtrosAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre Hébergements"])}
  },
  "validaciones": {
    "pagador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payeur: "])},
    "validadaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERREUR : BILLET DÉJÀ VALIDÉ"])},
    "validadaExito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BILLET VALIDÉ AVEC SUCCÈS"])},
    "validadaFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé le "])},
    "aLas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" à "])},
    "cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "unidades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unités"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "preguntaValidar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous valider le billet?"])},
    "validar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])}
  },
  "general": {
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
    "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le"])},
    "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "teneis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez"])},
    "grupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "antesDel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avant le"])},
    "ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maintenant"])},
    "checkInOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement en ligne"])},
    "checkInRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement réalisé"])},
    "codigoCopiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code a été copié"])},
    "pagoPlazosDeshabilitado": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes désormais à jour de votre échéancier de paiement, vous pouvez effectuer le prochain paiement à partir du ", _interpolate(_named("fecha")), "."])},
    "pagoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement en attente"])},
    "articulosRelacionados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles liés"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleures offres"])},
    "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
    "pagos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
    "atras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
    "siguiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "ordenar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])},
    "anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
    "viaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyage"])},
    "mostrarMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher plus"])},
    "viajeAMedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expériences sur mesure"])},
    "mostrarMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher moins"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "cerrarSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "solucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "para": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour"])},
    "pagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
    "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à partir de"])},
    "seleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection"])},
    "terminosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et conditions"])},
    "proteccionDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection des données personnelles"])},
    "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par personne"])},
    "masInformacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'informations sur ce voyage"])},
    "masInformacionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'informations sur l'hébergement"])},
    "variasOcupaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverses professions disponibles"])},
    "eligeAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre hébergement"])},
    "quieroSaberMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je veux en savoir plus"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver un logement"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre la chambre"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "ubicacionesAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir carte"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les emplacements"])},
    "bookingLetterButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "codigoInvalido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code n'est pas valide."])},
    "bookingBusDeparture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ"])},
    "diaMayus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUR"])},
    "experienceFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyager pour"])},
    "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours"])},
    "noches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuits"])},
    "noche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuit"])},
    "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du"])},
    "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au "])},
    "aplicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé"])},
    "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["places"])},
    "cantidadDisponibles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plus de ", _interpolate(_named("cantidad")), " disponibles"])},
    "disponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibles"])},
    "agotadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épuisées"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver"])},
    "listaEspera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste d'attente"])},
    "listaEsperaSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été ajoutée à la liste d'attente avec succès"])},
    "listaEsperaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'a pas été possible d'ajouter à la liste d'attente"])},
    "personas": {
      "singular": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adulte"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enfant"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personne"])}
      },
      "plural": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adultes"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enfants"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personnes"])}
      },
      "mayus": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adulte"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfant"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne"])}
      }
    },
    "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logement"])},
    "gastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chambre"])},
    "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime"])},
    "esMiReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réservation est pour moi"])},
    "nombreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utiliserons le nom et le prénom de votre profil"])},
    "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne"])},
    "menorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis mineur"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "masInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'informations"])},
    "masInformacionIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ d'informations"])},
    "borrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])},
    "esMenorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est mineur"])},
    "fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "invalidCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code invalide"])},
    "politicaPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et conditions"])}
  },
  "filtroAlojamiento": {
    "borrarFiltros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les filtres"])},
    "titulo": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer l'hébergement"])},
      "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les villes où vous aimeriez aller"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le type d'hébergement que vous aimeriez"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le nombre de places"])}
    }
  },
  "filtroModalidades": {
    "titulo": {
      "seleccionaGama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la gamme"])},
      "seleccionaServicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le service"])}
    }
  },
  "selectSubTipos": {
    "titulo": {
      "seguroAnulacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance annulation"])},
      "seguroViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance voyage"])}
    }
  },
  "resumenServiciosBuscador": {
    "nochesAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours d'hébergement"])},
    "diasForfait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours de forfait"])}
  },
  "baseMigas": {
    "seleccionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection de l'hébergement"])},
    "datosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserve"])},
    "confirmacionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de la réservation"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
    "seleccionFestival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection du festival"])}
  },
  "botones": {
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver un logement"])},
    "reservaPackFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver le pack de fête"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre la chambre"])},
    "volverViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour aux voyages"])},
    "volverReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à mes réservations"])},
    "actualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tous"])},
    "verDetalles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les détails"])},
    "verReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la réservation"])},
    "bonoAdjunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le bon joint"])},
    "cancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulée"])},
    "descargar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "volver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer"])},
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "recuperar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer"])},
    "copiar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie"])},
    "cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "modificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "modificarDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les données"])},
    "modificarPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier et Payer"])},
    "agregarPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une personne"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "darseBaja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se désabonner"])},
    "aceptarSeleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter la sélection"])},
    "cancelarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la réservation"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la réservation"])},
    "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
    "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre"])},
    "reactivarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactiver la réservation"])},
    "recordar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappeler"])},
    "pagarSenial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer le signal"])},
    "pagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "establecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établir"])},
    "verBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le Bon"])},
    "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "reintentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])}
  },
  "codigoDescuento": {
    "tienesCodDescuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous un code de réduction?"])}
  },
  "subtitulo": {
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la fin de la réservation, envoyez le lien que nous fournirons au reste des personnes afin qu'elles puissent rejoindre."])},
    "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nom de votre chambre"])},
    "comoConociste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment nous avez-vous connus ?"])},
    "comercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du commercial"])},
    "estudiando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous en train d'étudier ?"])},
    "escribeRespuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez votre réponse"])},
    "eligeUniversidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une université"])},
    "trabajando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans quelle entreprise travaillez-vous ?"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "importeTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total"])},
    "importePendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant restant"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de la réservation"])},
    "codigoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de réservation"])},
    "codigoViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de voyage"])},
    "intoduceAqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez-le ici"])},
    "codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "misViajeros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes voyageurs"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
    "mailingPublicidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing publicitaire"])},
    "subirDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la passeport"])},
    "modificarcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe"])},
    "volverPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à mon profil"])},
    "datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données"])}
  },
  "modalUnirseHabitacion": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez plus de logement ?"])},
    "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les informations suivantes et en cas de libération d'une chambre, vous serez averti"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez rejoindre la chambre d'un voyage, utilisez le code fourni par la personne ayant effectué la réservation"])}
  },
  "input": {
    "field": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
      "nombreApellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom"])},
      "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
      "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
      "numeroPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de places"])},
      "observaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarques"])},
      "redesSociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux sociaux"])},
      "comerciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicitaires"])},
      "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait de base"])}
    },
    "placeholder": {
      "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nom de votre chambre"])},
      "introduceCampo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le champ"])}
    }
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées de l'acheteur"])},
    "subtitleRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas client ? Inscrivez-vous"])},
    "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
    "recoverPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer le mot de passe"])},
    "questionLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes déjà client ? Connectez-vous"])},
    "questionUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes déjà utilisateur"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le mot de passe"])},
    "establecerContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir le mot de passe de l'utilisateur"])}
  },
  "errors": {
    "habitacion": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code ne correspond à aucune pièce"])}
    },
    "grupoReservas": {
      "pendientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a des réservations à confirmer"])}
    },
    "experiencia": {
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'expérience n'admet pas de nouvelles réservations"])}
    },
    "reserva": {
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'un des voyageurs a déjà réservé pour cette expérience"])}
    },
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors du paiement"])},
    "pagoTransferencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement de cette réservation se fait par virement bancaire"])},
    "cargaInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu lors du chargement des informations. Veuillez réessayer plus tard"])},
    "controlErrorValidarCupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le voyage demandé n'existe pas"])},
    "noAdmiteReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le voyage n'accepte pas de nouvelle réservation"])},
    "inesperado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inattendue. Veuillez réessayer plus tard"])},
    "loginReservaDescartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous connecter pour annuler la réservation"])},
    "precioCambiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un changement de prix a été détecté"])},
    "permisosDescartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les autorisations pour annuler la réservation"])},
    "descartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'annuler la réservation"])},
    "intentalo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inattendue. Veuillez réessayer plus tard"])},
    "obligatorios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains champs obligatoires ne sont pas remplis"])},
    "loginReservaCrear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous connecter pour créer une réservation"])},
    "permisosCrearReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les autorisations pour créer la réservation"])},
    "noInfoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations de la réservation n'ont pas pu être trouvées"])},
    "existeReservaViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe déjà une réservation pour ce voyage"])},
    "emailRegistrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'e-mail est déjà enregistré"])},
    "permisosProcesos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les autorisations pour effectuer le processus"])},
    "camposSinCompletar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains champs ne sont pas remplis"])},
    "recursoNoLocalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ressource demandée n'a pas pu être localisée"])},
    "conflictoEstadoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a un conflit dans l'état de la réservation"])},
    "errorContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la saisie du mot de passe"])},
    "loginRequerido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous connecter pour continuer"])},
    "reservaNoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réservation n'est pas en attente"])},
    "noAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun logement trouvé"])},
    "intenteReservaTarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez essayer de réserver plus tard. Si l'erreur persiste, contactez-nous."])}
  },
  "servicios": {
    "repercutirFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de gestion"])},
    "tasaDe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Frais de ", _interpolate(_named("nombreTasa"))])},
    "forfait": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez changer les jours de votre forfait. Sélectionnez les jours que vous souhaitez"])}
    },
    "comida": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repas"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez si vous voulez manger sur les pistes"])}
    },
    "transporte": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre moyen de transport"])}
    },
    "seguro": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurances"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre assurance"])},
      "subtipo": {
        "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation"])},
        "salud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santé"])}
      }
    },
    "actividad": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez l'activité"])}
    },
    "restauracion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repas"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez si vous voulez manger sur les pistes"])}
    },
    "festival": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivals"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le billet que vous voulez et venez profiter de l'expérience complète"])}
    },
    "clase": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez choisir les jours de cours. Sélectionnez les jours que vous souhaitez"])}
    },
    "alquiler": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez si vous voulez louer du matériel"])}
    },
    "greenFee": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Fee"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez si vous souhaitez un Green Fee."])}
    },
    "relax": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez si vous souhaitez un service de relaxation."])}
    },
    "personal": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnel"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le personnel."])}
    },
    "parking": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez votre parking préféré"])}
    },
    "mascota": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animal de compagnie"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les services pour animaux de compagnie"])}
    },
    "competicion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compétition"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la compétition"])}
    }
  },
  "trabajaNosotros": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaillez avec Nous"])}
  },
  "titulos": {
    "completarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les informations suivantes pour continuer votre réservation"])},
    "editarPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le profil"])},
    "itinerario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinéraire"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "incluye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclus"])},
    "motivosCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motifs d'annulation"])},
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservez pour autant de personnes que vous le souhaitez"])}
  },
  "cardReservaPlazasLibres": {
    "descripcion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez encore ", _interpolate(_named("plazasLibres")), " places disponibles dans votre logement, ce qui correspond à un paiement en attente de ", _interpolate(_named("pagoPendiente")), ". Vous avez deux options :"])},
    "opcionUno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager le lien pour compléter les places disponibles."])},
    "opcionDos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Payer le montant en attente de ", _interpolate(_named("pagoPendiente")), " parmi les personnes confirmées."])},
    "cancelacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si aucune des deux options n'est effectuée avant le ", _interpolate(_named("fecha")), ", la ", _interpolate(_named("final"))])},
    "cancelacionBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réservation sera automatiquement annulée."])},
    "msjPlazaLibresPagadas": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez des ", _interpolate(_named("plazasLibres")), " places libres, partagez le lien pour les compléter et le supplément « Places libres » sera recalculé en votre faveur."])},
    "button": {
      "abonarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer le montant en attente"])},
      "copiarLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien"])}
    }
  },
  "langs": {
    "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Française"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])}
  }
}