<template>
	<div class="col my-2">
		<div class="title-card d-flex p-3 justify-content-between align-items-center rounded-2">
			<div class="d-flex align-items-center">
				<i :class="categoriaServiciosMetaData.icono" class="me-2 text-primary" />
				<span class="text-primary">{{ categoriaServiciosMetaData.titulo }}</span>
			</div>
			<div class="d-flex">
				<template v-if="servicioSeleccionado">
					<span v-if="servicioSeleccionado.precio !== 0" class="text-secondary mx-2">
						<span v-if="servicioSeleccionado.precio > 0">+</span>
						{{ servicioSeleccionado.precio }}{{agenciaStore.getCurrency}}
					</span>
				</template>
				<template v-if="showCheck">
					<svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="16" cy="16" r="15" fill="var(--bs-secondary)" stroke="var(--bs-primary)" stroke-width="2" />
						<g clip-path="url(#clip0_561_15056)">
							<path
								d="M13.2492 19.8225L9.42667 16L8.125 17.2925L13.2492 22.4167L24.2492 11.4167L22.9567 10.1242L13.2492 19.8225Z"
								fill="white" />
						</g>
						<defs>
							<clipPath id="clip0_561_15056">
								<rect width="22" height="22" fill="white" transform="translate(5 5)" />
							</clipPath>
						</defs>
					</svg>
				</template>
				<template v-else>
					<svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="16" cy="16" r="15" fill="transparent" stroke="transparent" />
					</svg>
				</template>
			</div>
		</div>
		<div class="body-card">
			<div class="row d-flex justify-content-center mt-3">
				<template v-if="categoriaServicios.arquetipo === 'individual'">
					<ReservaServiciosIndividualSelector
						:idx-reserva="idxReserva"
						:categoria-servicios="categoriaServicios"
						@servicio-seleccionado="handlerServicioSeleccionado" />
				</template>
				<template v-else-if="categoriaServicios.arquetipo === 'subfiltro'">
					<ReservaServiciosSubfiltrosSelector
						:idx-reserva="idxReserva"
						:categoria-servicios="categoriaServicios"
						@servicio-seleccionado="handlerServicioSeleccionado" />
				</template>
				<template v-else-if="categoriaServicios.arquetipo === 'subtipo'">
					<ReservaServiciosSubtipoSelector
						:categoria-servicios="categoriaServicios"
						:idx-reserva="idxReserva"
						@servicio-seleccionado="handlerServicioSeleccionado" />
				</template>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed } from 'vue';
	import ReservaServiciosSubfiltrosSelector from '@/modules/viajesGrupos/components/ReservaServiciosSubfiltrosSelector.vue';
	import ReservaServiciosIndividualSelector from '@/modules/viajesGrupos/components/ReservaServiciosIndividualSelector.vue';
	import ReservaServiciosSubtipoSelector from '@/modules/viajesGrupos/components/ReservaServiciosSubtipoSelector.vue';
	import { getCategoriasServiciosMetaData } from '@/helpers/serviciosUtils';
	import { agenciaStoreModule } from '@/store_pinia/agencia';

	const props = defineProps({
		categoriaServicios: {
			type: Object,
			default: null,
		},
		idxReserva: {
			type: Number,
			default: null,
		},
	});

	const agenciaStore = agenciaStoreModule();
	const servicioSeleccionado = ref({});

	const categoriaServiciosMetaData = getCategoriasServiciosMetaData().find(
		({ selector }) => selector === props.categoriaServicios.categoria
	);

	const showCheck = computed(() => {
		const keys = Object.keys(servicioSeleccionado.value);
		if (keys.length === 0) {
			return false;
		}
		if (keys[0] === 'id') {
			return (
				servicioSeleccionado.value.nulo === false ||
				(servicioSeleccionado.value.precio !== 0 && servicioSeleccionado.value.paqueteBasico)
			);
		} else {
			return Object.values(servicioSeleccionado.value).some(subItem => {
				return subItem.nulo === false || (subItem.precio !== 0 && subItem.paqueteBasico);
			});
		}
	});

	const handlerServicioSeleccionado = data => {
		servicioSeleccionado.value = data;
	};
</script>

<style lang="css">
	.title-card {
		background-color: rgba(249, 247, 248, 1);

		border-radius: var(--bs-border-radius-lg);
	}
</style>
