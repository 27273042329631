<template>
	<div v-if="!loading">
		<div class="container-resumen px-3">
			<div class="text-center text-primary mb-5">
				<p class="fw-bold fs-3 mb-0">
					{{ $t('reserva.revisaDatos') }}
				</p>
				<p class="text-muted fs-4">Recuerda que puedes pagar la reserva íntegra o solo una parte</p>
			</div>

			<div class="background-linear text-white fw-bold p-4 rounded mb-3">
				<div class="row fs-4">
					<div class="col-6">
						{{ respuesta.viaje.lugarExperiencia.nombre }}
					</div>
					<div class="col-6 text-end">
						{{ respuesta.viaje.fechaInicio }} -
						{{ respuesta.viaje.fechaFin }}
					</div>
				</div>
			</div>
			<div class="card-resumen text-primary shadow p-4 rounded">
				<template v-for="(habitacion, indexHabitacion) in respuesta.alojamiento.habitaciones" :key="habitacion.id">
					<div class="row fs-5 fw-bold">
						<div class="col-6">{{ $t('general.habitacion') }} {{ indexHabitacion + 1 }}</div>
						<div class="col-6 text-end text-secondary">{{ getTotalHabitacion(habitacion.reservas) }}{{agenciaStore.getCurrency}}</div>
					</div>
					<hr />
					<p>{{ habitacion.descripcion }}</p>
					<template v-for="reserva in habitacion.reservas" :key="reserva.id">
						<div class="mb-3">
							<div class="row">
								<div class="col-6 fw-bold mb-2">
									{{ reserva.usuario.nombre }}
									{{ reserva.usuario.apellidos }}
								</div>
								<div class="col-6 text-end fw-bold">{{ reserva.precioTotal }}{{agenciaStore.getCurrency}}</div>
							</div>
							<template v-for="servicio in reserva.servicios" :key="servicio.id">
								<div class="row">
									<div class="col-4">
										{{ servicio.nombre }}
									</div>
									<div class="col-4 text-center text-muted">
										{{ servicio.descripcion }}
									</div>
									<div class="col-4 text-end">
										<span v-if="servicio.precio > 0"> {{ servicio.precio }}{{agenciaStore.getCurrency}} </span>
									</div>
								</div>
							</template>
						</div>

						<template v-if="computedDescuento && computedDescuento.tipoCantidad !== 'PORCENTAJE'">
							<div class="row text-">
								<div class="col-4">
									{{ $t('reserva.descuento') }}
								</div>
								<div class="col-4 text-center text-muted">
									{{ computedDescuento.descripcion }}
								</div>
								<div class="col-4 text-end fw-bold">
									<span class="mx-2">-{{ computedDescuento.cantidad }}{{agenciaStore.getCurrency}}</span>
								</div>
							</div>
						</template>
					</template>
				</template>

				<template v-if="computedDescuento && computedDescuento.tipoCantidad === 'PORCENTAJE'">
					<div class="row">
						<div class="col-4">
							{{ $t('reserva.descuento') }}
						</div>
						<div class="col-4 text-center text-muted">
							{{ computedDescuento.descripcion }}
						</div>
						<div class="col-4 text-end fw-bold">
							<span class="mx-2">-{{ computedDescuento.cantidad }}%</span>
						</div>
					</div>
				</template>
			</div>
			<div class="d-flex flex-column flex-md-row justify-content-between mt-4">
				<div>
					<FormCodigoDescuento
						:viaje-id="respuesta.viaje.id.toString()"
						:reservas-ids="
							respuesta.alojamiento.habitaciones
								.map(habitacion => habitacion.reservas.map(reserva => reserva.id))
								.flat()
						"
						@descuento-aplicado="handlerCodigoDescuento" />
				</div>
				<div class="d-flex fs-3 shadow container-total mt-3 mt-md-0">
					<div class="text-total p-3 text-primary fw-bold rounded-3">Total</div>
					<div class="p-3 text-secondary fw-bold background-linear rounded-3">{{ sumaTotalReservas }}{{agenciaStore.getCurrency}}</div>
				</div>
			</div>
			<div class="my-4">
				<div class="d-flex flex-column align-items-end me-2">
					<div class="form-check" :class="errorCancelacion ? 'text-danger' : 'text-primary'">
						<input id="flexCheckDefault" v-model="politicaPrivacidad" class="form-check-input" type="checkbox" />
						<label class="form-check-label">
							{{ $t('home.form.policy.readAccept') }}
							<span class="btn-link" @click="openPoliticas()">
								{{ $t('home.form.policy.privacyPolicy') }}
							</span>
						</label>
					</div>
					<div class="form-check" :class="errorCancelacion ? 'text-danger' : 'text-primary'">
						<input id="flexCheckDefault2" v-model="politicaCancelacion" class="form-check-input" type="checkbox" />
						<label class="form-check-label">
							{{ $t('home.form.policy.readAccept') }}
							<span class="btn-link" data-bs-toggle="modal" data-bs-target="#politicas">
								{{ $t('home.form.policy.policyCancel') }}
							</span>
						</label>
					</div>
					<!-- Modal -->
					<div id="politicas" class="modal fade" tabindex="-1" aria-labelledby="politicasLabel" aria-hidden="true">
						<div class="modal-dialog">
							<div class="modal-content">
								<div class="modal-body">
									<button type="button" class="btn-close button" data-bs-dismiss="modal" aria-label="Close" />
									<h5 class="text-secondary fw-bold">
										{{ $t('home.form.policy.cancelReserva') }}
									</h5>
									<span v-sanitize-html="obtenerPoliticasCancelacion(respuesta.alojamiento.habitaciones)" />

									<p>
										También puedes realizar un cambio de titular de la reserva para que un compañero te sustituya, este
										cambio se podrá realizar con una antelación máxima de 5 días antes de la fecha de inicio del viaje y
										puede tener un coste asociado.
									</p>
									<p>
										*Los gastos de gestión asociados a modificaciones en la reserva o por el incumplimiento de los
										plazos del pago de la misma no serán reembolsables en ningún caso.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="text-end mt-5">
				<button v-if="!respuesta.pagoUnico" class="btn btn-primary me-3 fw-bold" @click="realizarSolicitudPago(false)">
					{{ $t('botones.pagarSenial').toLocaleUpperCase() }} : {{ calculoSenial }} {{agenciaStore.getCurrency}}
				</button>
				<button class="btn btn-primary fw-bold" @click="realizarSolicitudPago(true)">
					{{ $t('botones.pagar').toLocaleUpperCase() }} {{ $t('subtitulo.total').toUpperCase() }}
				</button>
			</div>
		</div>

		<div v-if="redsys">
			<form name="from" :action="redsys.url" method="POST">
				<input type="hidden" name="Ds_SignatureVersion" :value="redsys.version" />
				<input type="hidden" name="Ds_MerchantParameters" :value="redsys.parametros" />
				<input type="hidden" name="Ds_Signature" :value="redsys.firma" />
			</form>
		</div>
	</div>
</template>

<script>
	import { computed, defineComponent, ref } from 'vue';
	import { reservaHoteles } from '@/store_pinia/reservaHoteles';
	import { useRouter } from 'vue-router';
	import FormCodigoDescuento from 'src/views/reservas/grupos/components/FormCodigoDescuento.vue';
	import PasarelaPagoApiService from '@/services/api/skiandnight/PasarelaPagoApiService';
	import { controlErrorRealizarPago } from '@/services/utils/ErrorControl';
	import { obtenerPoliticasCancelacion } from '@/helpers/htmlMakers';
	import { agenciaStoreModule } from '@/store_pinia/agencia';

	export default defineComponent({
		components: { FormCodigoDescuento },
		setup() {
			const storeReservaHoteles = reservaHoteles();
			const agenciaStore = agenciaStoreModule();

			const router = useRouter();

			const computedDescuento = ref(null);

			const loading = ref(true);

			const sumaTotalReservas = ref(0);
			const sumaTotalReservaAux = ref(0);

			const valorCodigoDescuento = ref('');

			const politicaCancelacion = ref(false);
			const errorCancelacion = ref(false);
			const politicaPrivacidad = ref(false);

			const respuesta = computed(() => storeReservaHoteles.reservaCreada);

			const getTotalHabitacion = reservas => {
				let sumaReservasAux = 0;
				reservas.map(reserva => {
					sumaReservasAux += reserva.precioTotal;
				});
				return sumaReservasAux;
			};

			const getSenialHabitacion = reservas => {
				let sumaSenialAux = 0;
				reservas.map(reserva => {
					sumaSenialAux += reserva.precioSenial;
				});
				return sumaSenialAux;
			};

			const getTotalReserva = informacionAlojamiento => {
				let sumTotalAux = 0;
				informacionAlojamiento.habitaciones.map(habitacion => {
					sumTotalAux += getTotalHabitacion(habitacion.reservas);
				});
				return sumTotalAux;
			};

			const calculoSenial = computed(() => {
				let senialAux = 0;
				respuesta.value.alojamiento.habitaciones.map(habitacion => {
					senialAux += getSenialHabitacion(habitacion.reservas);
				});
				return senialAux;
			});

			if (respuesta.value == null) {
				router.push({ name: 'home' });
			} else {
				sumaTotalReservas.value = getTotalReserva(respuesta.value.alojamiento);
				sumaTotalReservaAux.value = getTotalReserva(respuesta.value.alojamiento);
				loading.value = false;
			}

			const calcularDescuentoPorcentaje = (cantidadDescuento, valorTotal) =>
				Math.round(((100 - cantidadDescuento) * (valorTotal / 100) + Number.EPSILON) * 100) / 100;

			const openPoliticas = () => {
				let routeData = router.resolve({ name: 'PoliticaPrivacidad' });
				window.open(routeData.href, '_blank');
			};

			const redsys = ref();

			const cantidadPersonas = computed(() => {
				let cantidadPersonas = 0;
				respuesta.value.alojamiento.habitaciones.map(habitacion => {
					cantidadPersonas += habitacion.reservas.length;
				});
				return cantidadPersonas;
			});

			const handlerCodigoDescuento = informacionCodigoDescuento => {
				valorCodigoDescuento.value = informacionCodigoDescuento.codigo;
				computedDescuento.value = informacionCodigoDescuento;
				if (informacionCodigoDescuento) {
					if (informacionCodigoDescuento.pagoUnico) {
						if (informacionCodigoDescuento.tipoCantidad === 'PORCENTAJE') {
							sumaTotalReservas.value = calcularDescuentoPorcentaje(
								informacionCodigoDescuento.cantidad,
								sumaTotalReservas.value
							);
							return;
						}
					}
					if (informacionCodigoDescuento.tipoCantidad === 'PORCENTAJE') {
						sumaTotalReservas.value = calcularDescuentoPorcentaje(
							informacionCodigoDescuento.cantidad,
							sumaTotalReservas.value
						);
						return;
					}
					sumaTotalReservas.value =
						sumaTotalReservaAux.value - cantidadPersonas.value * informacionCodigoDescuento.cantidad;
					return;
				}
			};

			const realizarSolicitudPago = pagoUnico => {
				if (!politicaCancelacion.value) {
					errorCancelacion.value = true;
					return;
				}

				if (!politicaPrivacidad.value) {
					errorCancelacion.value = true;
					return;
				}

				errorCancelacion.value = false;

				PasarelaPagoApiService.solicitaCobroInicial(respuesta.value.id, valorCodigoDescuento.value, pagoUnico)
					.then(res => {
						console.log(res);
						redsys.value = res;
					})
					.catch(err => {
						controlErrorRealizarPago(err);
					})
					.finally(() => {
						document.from.submit();
					});
			};

			return {
				respuesta,
				getTotalHabitacion,
				getTotalReserva,
				loading,
				openPoliticas,
				computedDescuento,
				handlerCodigoDescuento,
				sumaTotalReservas,
				redsys,
				realizarSolicitudPago,
				calculoSenial,
				politicaCancelacion,
				politicaPrivacidad,
				errorCancelacion,
				cantidadPersonas,
				obtenerPoliticasCancelacion,
				agenciaStore,
			};
		},
	});
</script>

<style scoped lang="scss">
	.background-linear {
		background: linear-gradient(185.13deg, #5f5e8f -7.77%, #313056 76.51%);
	}
	.container-resumen {
		max-width: 1200px;
		margin: auto;
		padding-top: 13rem;
		padding-bottom: 4rem;

		.card-resumen,
		.text-total {
			background-color: #f9f7f8;
		}

		.container-total {
			border-radius: var(--bs-border-radius-xl);
			overflow: hidden;
		}

		.btn-link {
			cursor: pointer;
		}
	}
</style>
