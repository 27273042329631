<template>
	<article
		v-if="storeGlobal.valores && buscador.filtrosViajes.tiposGrupo && buscador.filtrosViajes.tiposGrupo.length > 0"
		class="trip-card pointer"
		@mousedown="startDrag"
		@mousemove="onDrag"
		@mouseup="handleMouseUp(viajeTarjeta.id)">
		<TripCardHeader
			:background-image="imageCategory(backgroundCards)"
			:festival-icon="viajeTarjeta.iconoFestival"
			:id-viaje="viajeTarjeta.id" />

		<TripCardBody
			:dias-forfait="viajeTarjeta.diasForfait"
			:end-date="viajeTarjeta.fechaFin"
			:hide-services="hideServices"
			:id-viaje="viajeTarjeta.id"
			:include-services="viajeTarjeta.incluyes"
			:start-date="viajeTarjeta.fechaInicio"
			:title="viajeTarjeta.nombre"
			:has-alojamiento="viajeTarjeta.hasAlojamiento" />

		<TripCardFooter
			:has-alojamiento="viajeTarjeta.hasAlojamiento"
			:codigo-sin-habitacion="codigoSinHabitacion"
			:loading-global="storeGlobal.loadingGlobal"
			:price="viajeTarjeta.precioDesde"
			:viaje-id="viajeTarjeta.id" />
	</article>
</template>

<script setup>
	import TripCardHeader from './TripCardHeader.vue';
	import TripCardBody from './TripCardBody.vue';
	import TripCardFooter from '@/components/card/TripCard/TripCardFooter.vue';
	import { appGlobalStore } from '@/store_pinia/app';
	import { useRoute, useRouter } from 'vue-router';
	import viajesGrupo from 'src/store_pinia/viajes/viajesGrupo';
	import { computed, ref } from 'vue';
	import { GruposInformacionViaje } from '@/router/RouterNames';
	import { viajesGrupos } from '@/store_pinia/viajesGrupo';
	import defaultBackground from '@/assets/img/fondo_mygocu.png';

	const props = defineProps({
		viajeTarjeta: {
			type: Object,
			require: true,
			default: () => null,
		},
		hideServices: {
			type: Boolean,
			require: false,
			default: () => false,
		},
		tiposViaje: {
			type: Array,
			require: false,
			default: () => [],
		},
	});

	const storeGlobal = appGlobalStore();
	const buscador = viajesGrupo();
	const router = useRouter();
	const store = viajesGrupos();
	const currentGroup = useRoute().query.grupo;

	const startX = ref(0);
	const startY = ref(0);
	const hasDragged = ref(false);

	const codigoSinHabitacion = computed(() => {
		if (
			props.viajeTarjeta.alojamientos &&
			props.viajeTarjeta.alojamientos.length === 1 &&
			!props.viajeTarjeta.alojamientos[0].hasHabitaciones
		) {
			return props.viajeTarjeta.alojamientos[0].codigoSinHabitacion;
		} else {
			return null;
		}
	});

	const srcImages = [
		storeGlobal.valores.img_cabeceracard_universitarios,
		storeGlobal.valores.img_cabeceracard_familias,
		storeGlobal.valores.img_cabeceracard_trabajadores,
		storeGlobal.valores.img_cabeceracard_colegios,
		storeGlobal.valores.img_cabeceracard_premium,
		storeGlobal.valores.img_cabeceracard_premium,
		storeGlobal.valores.img_cabeceracard_familias,
		storeGlobal.valores.img_cabeceracard_trabajadores,
		storeGlobal.valores.img_cabeceracard_universitarios,
	];

	const backgroundCards = computed(() => {
		if (!buscador.filtrosViajes && !buscador.filtrosViajes.tiposGrupo) {
			return undefined;
		} else {
			return buscador.filtrosViajes.tiposGrupo.map((tipoViaje, index) => {
				return {
					currentGroup: tipoViaje.nombre,
					src: srcImages[index],
					alt: 'Background viaje' + tipoViaje.nombre,
					title: 'Background viaje' + tipoViaje.nombre,
				};
			});
		}
	});

	function startDrag(event) {
		startX.value = event.clientX;
		startY.value = event.clientY;
		hasDragged.value = false;
	}

	function onDrag(event) {
		const deltaX = Math.abs(event.clientX - startX.value);
		const deltaY = Math.abs(event.clientY - startY.value);

		if (deltaX > 5 || deltaY > 5) {
			hasDragged.value = true;
		}
	}

	function handleMouseUp(viajeId) {
		if (!hasDragged.value) {
			goToInfo(viajeId);
		}
	}

	function imageCategory(listImages) {
		if (props.viajeTarjeta.imagenExperiencia != undefined) {
			return props.viajeTarjeta.imagenExperiencia;
		} else if (Array.isArray(listImages) && listImages.length > 0) {
			const item = listImages.find(
				({ currentGroup }) =>
					currentGroup == useRoute().query.grupo ||
					(props.viajeTarjeta.tipos &&
						props.viajeTarjeta.tipos[0] &&
						currentGroup == props.viajeTarjeta.tipos[0].nombre)
			);
			if (item) {
				return item.src;
			}
		}
		return defaultBackground;
	}

	function goToInfo(viajeId) {
		store.setViajeSeleccionado(viajeId).then(() => {
			router.push({
				name: GruposInformacionViaje,
				params: { viajeId: viajeId },
				query: { grupo: currentGroup },
			});
		});
	}
</script>

<style lang="scss">
	@import '~@/assets/scss/san.scss';

	.trip-card {
		@extend .card, .rounded-4, .border-0;
		background: white;
		box-shadow: 0 1px 4px 1px rgba(var(--bs-primary-rgb), 0.25);
		width: 280px;
		min-width: 280px;

		@include media-breakpoint-up(md) {
			width: 290px;
		}

		.white-space {
			width: 100%;
			height: 25px;

			@include media-breakpoint-up(md) {
				height: 26px;
			}
		}

		.rotate-icon {
			transform: rotate(180deg);
			transition: transform 0.15s ease;
		}

		@keyframes marquee {
			0% {
				transform: translateX(0);
			}
			100% {
				transform: translateX(-66.6%);
			}
		}
	}
</style>
