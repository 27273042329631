import { defineStore } from 'pinia';

export const agenciaStoreModule = defineStore('agenciaStore', {
	state: () => ({
		nombre: null,
		currency: null
	}),
	getters: {
		getCurrency(state) {
			return state.currency;
		},
	},
	actions: {
		setData(hAgenciaDto) {
			this.nombre = hAgenciaDto.nombre;
			this.currency = hAgenciaDto.currency;
		}
	},
});
