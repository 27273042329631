<template>
	<div>
		<div class="banner-servicio d-flex flex-column px-3">
			<div class="align-self-center h-50 w-100 d-flex align-items-center mt-5">
				<BaseMigas
					:migas-text="[
						$t('baseMigas.seleccionFestival'),
						$t('baseMigas.datosReserva'),
						$t('baseMigas.confirmacionReserva'),
						$t('baseMigas.pago'),
					]"
					:posicion="1"
					class="d-none d-md-block my-5" />
			</div>
			<div class="ps-0 ps-md-5 pb-3 texto-banner">
				<h5 class="fw-bold text-primary">Elige las entradas que quieras comprar.</h5>
				<p class="text-muted">Selecciona el número de entradas que necesites y rellena tus datos.</p>
			</div>
		</div>
		<div v-if="loading" class="container-loading d-flex align-items-center justify-content-center">
			<SpinnerVue />
		</div>
		<div v-if="!loading" class="container-servicios">
			<div class="row">
				<div class="col-12 col-lg-8 p-4">
					<div v-for="servicio in serviciosFestivales" :key="servicio.id">
						<transition appear>
							<FestivalesServicioDropdown :servicio="servicio" />
						</transition>
					</div>
					<template v-if="!store.state.login.isLogged">
						<LoginReserva v-if="!recordar && !fromRecordar" @logueado="crearTicketera()" @recordar="updateRecordar()" />

						<LoginReserva
							v-else-if="!recordar && fromRecordar"
							:show-registrar-inicial="false"
							@logueado="crearTicketera()"
							@recordar="updateRecordar()" />

						<UsuarioPasswordRecordar v-else :from-login="false" @volver-a-login="updateRecordar()" />
					</template>
				</div>

				<div class="d-none d-lg-block col-4 py-4">
					<FestivalesCardResumenServiciosVue
						:informacion-festival="informacionFestival"
						:servicios-evento="serviciosEvento" />
				</div>
			</div>
		</div>

		<div class="text-center my-4">
			<button class="btn btn-primary me-2" @click="$router.go(-1)">
				{{ $t('general.atras') }}
			</button>
			<button class="btn btn-secondary" @click="crearTicketera()">
				{{ $t('general.siguiente') }}
			</button>
		</div>
	</div>
</template>

<script>
	import { computed, defineComponent, ref } from 'vue';
	import FestivalesServicioDropdown from './FestivalesServicioDropdown.vue';
	import UsuarioPasswordRecordar from '@/views/usuario/UsuarioPasswordRecordar/UsuarioPasswordRecordar.vue';
	import LoginReserva from 'src/views/login/LoginReserva.vue';
	import { useStore } from 'vuex';
	import { firstLetterCapital } from 'src/helpers/languageUtils';
	import EventosApiService from 'src/services/api/skiandnight/EventosApiService';
	import BaseMigas from '@/components/BaseMigas';
	import { festivalesStore } from 'src/store_pinia/festivales';
	import { useRoute, useRouter } from 'vue-router';
	import { FestivalesDetalle, FestivalesResumen } from 'src/router/RouterNames';
	import { controlErrorCrearTicketera } from 'src/services/utils/ErrorControl';
	import SpinnerVue from 'src/components/SpinnerLoading.vue';
	import FestivalesCardResumenServiciosVue from './FestivalesCardResumenServicios.vue';
	import { alertWarning } from '@/services/utils/AlertUtils';
	export default defineComponent({
		components: {
			FestivalesServicioDropdown,
			UsuarioPasswordRecordar,
			LoginReserva,
			BaseMigas,
			SpinnerVue,
			FestivalesCardResumenServiciosVue,
		},
		setup() {
			const store_festivales = festivalesStore();

			const router = useRouter();

			const route = useRoute();

			const fromRecordar = ref(false);

			const uri = route.params.uri;
			const eventoId = route.params.eventoId;
			const codigoAgrupacion = route.query.codigo || null;

			const informacionFestival = ref({});
			const recordar = ref(false);

			const loading = ref(true);

			const serviciosEvento = ref([]);

			const openEvento = uriNombre => {
				const routeData = router.resolve({
					name: FestivalesDetalle,
					params: { uri: uriNombre },
				});
				window.open(routeData.href, '_blank');
			};

			EventosApiService.getFestival(uri, codigoAgrupacion).then(res => {
				store_festivales.setFestival(res);
				informacionFestival.value = res;
			});

			EventosApiService.getEvento(uri, eventoId)
				.then(res => {
					serviciosEvento.value = res;
				})
				.then(() => generateInitialService())
				.finally(() => {
					loading.value = false;
				});

			const serviciosFestivales = computed(() => {
				let servAux = [...serviciosEvento.value.paquetesTicket];

				servAux.sort((a, b) => b.activo - a.activo);

				return servAux;
			});

			const updateRecordar = () => {
				fromRecordar.value = true;
				recordar.value = !recordar.value;
			};

			store_festivales.reservaServiciosFestivales = null;

			const store = useStore();

			const generateInitialService = () => {
				let serviceAux = {};
				serviciosFestivales.value.map(servicio => {
					serviceAux[`${servicio.tipo}${servicio.subtipo ? firstLetterCapital(servicio.subtipo) : ''}${servicio.id}`] =
						{
							id: servicio.id,
							nombre: servicio.titulo,
							precio: servicio.precio,
							tipo: servicio.tipo,
							subtipo: servicio.subtipo,
							cantidad: 0,
							tickets: [],
						};
				});
				store_festivales.setReservaServiciosFestivales(serviceAux);
			};

			const calcularTotalServicio = servicioReservado => {
				let totalAux = 0;
				servicioReservado.tickets.map(ticket => {
					if (servicioReservado.tipo != 'consumicion') {
						totalAux = servicioReservado.cantidad * servicioReservado.precio;
					} else {
						totalAux += ticket.cantidad * ticket.precio;
					}
				});
				return totalAux;
			};

			const reservaServiciosFestivales = computed(() => store_festivales.reservaServiciosFestivales);

			const resumenReserva = () => {
				let serviciosAux = [];
				Object.keys(reservaServiciosFestivales.value).map(servicio => {
					serviciosAux.push({
						...reservaServiciosFestivales.value[servicio],
					});
				});
				return serviciosAux;
			};

			const crearTicketera = async () => {
				let data = await buildReservaTickets();

				if (data.paquetesTicket.some(paquete => paquete.tickets.some(({ cantidad }) => cantidad === 0))) {
					alertWarning('Existes campos con cantidad 0');
					return;
				}

				EventosApiService.createTicketera(uri, eventoId, data)
					.then(res => store_festivales.setTicketeraGuardada(res))
					.then(() => {
						router.push({
							name: FestivalesResumen,
							params: { uri: uri, eventoId: eventoId },
						});
					})
					.catch(err => {
						controlErrorCrearTicketera(err);
					});
			};

			const buildReservaTickets = async () => {
				let auxServicios = reservaServiciosFestivales.value;

				let objetoReserva = {
					eventoId: eventoId,
					paquetesTicket: [],
				};

				Object.keys(auxServicios).map(servicio => {
					let itemPaquetesTicket = {
						id: auxServicios[servicio].id,
						tickets: [],
					};

					if (itemPaquetesTicket.cantidad == 0) return;

					if (auxServicios[servicio].tickets.length == 0) return;

					auxServicios[servicio].tickets.map(ticket => {
						itemPaquetesTicket.tickets.push({
							id: ticket.id,
							cantidad:
								auxServicios[servicio].tipo == 'consumicion' ? ticket.cantidad : auxServicios[servicio].cantidad,
						});
					});
					objetoReserva.paquetesTicket.push(itemPaquetesTicket);
				});
				return await objetoReserva;
			};

			const calcularTotalReserva = resumenReserva => {
				let auxTotal = 0;
				resumenReserva.map(servicio => {
					if (servicio.cantidad && servicio.tickets.length > 0) {
						if (servicio.tipo != 'consumicion') {
							auxTotal += servicio.precio * servicio.cantidad;
						} else {
							servicio.tickets.forEach(ticket => {
								auxTotal += ticket.cantidad * ticket.precio;
							});
						}
					}
				});
				return auxTotal;
			};

			return {
				serviciosFestivales,
				store,
				reservaServiciosFestivales,
				resumenReserva,
				calcularTotalServicio,
				calcularTotalReserva,
				loading,
				serviciosEvento,
				informacionFestival,
				openEvento,
				crearTicketera,
				fromRecordar,
				updateRecordar,
				recordar,
			};
		},
	});
</script>

<style scoped>
	.container-servicios {
		max-width: 1200px;
		margin: auto;
	}

	.v-enter-active,
	.v-leave-active {
		transition: opacity 0.5s ease;
	}

	.v-enter-from,
	.v-leave-to {
		opacity: 0;
	}

	.container-loading {
		min-height: 80vh;
	}

	.banner-servicio {
		width: 100%;
		min-height: 350px;
		background-image: url('src/assets/festivales/bannerServicio.webp');
		background-size: cover;
		padding-top: 7rem;
	}

	.texto-banner {
		width: 50%;
	}

	.card-resumen {
		border-radius: var(--bs-border-radius-xxl);
		background-color: #f9f7f8;
	}

	@media screen and (max-width: 900px) {
		.texto-banner {
			width: 100%;
		}
	}
</style>
../../../helpers/languageUtils../../../services/api/skiandnight/EventosApiService../../../store_pinia/festivales../../../router/RouterNames../../../services/utils/ErrorControl
