<template>
	<Transition name="modal">
		<div v-if="showModal" class="modal-mask" @click="closeModal()">
			<div class="modal-container">
				<div class="d-flex justify-content-center p-4 background-img">
					<img src="@/assets/svgs/plazasLibresWarning.svg" class="modal-image justify-content-center" />
				</div>
				<div class="modal-title px-4 pb-2 pt-3 pb-md-0 text-center">
					<span v-if="props.plazasLibres === 1">
						{{ $t('reserva.plazasLibres.modal.tituloSingular', { plazasLibres: props.plazasLibres }) }}
					</span>
					<span v-else>
						{{ $t('reserva.plazasLibres.modal.tituloPlural', { plazasLibres: props.plazasLibres }) }}
					</span>
				</div>
				<div class="text-primary px-md-5 py-md-2 px-1 py-0 m-0 text-center">
					<CardOccupancyMini
						:total-occupancies="totalOccupancies"
						:occupants-info="occupantsInfo"
						:has-habs="hasHabs" />
				</div>
				<div class="modal-body text-primary px-4 pb-0 pt-2 m-0">
					<i18n-t v-if="props.plazasLibres === 1" keypath="reserva.plazasLibres.modal.bodySingular" tag="span">
						<strong>{{ plazasLibres }}</strong>
						<strong>{{ precioTotal }}{{agenciaStore.getCurrency}}</strong>
					</i18n-t>
					<i18n-t v-else keypath="reserva.plazasLibres.modal.bodyPlural" tag="span">
						<strong>{{ plazasLibres }}</strong>
						<strong>{{ precioTotal }}{{agenciaStore.getCurrency}}</strong>
					</i18n-t>
				</div>
				<div class="modal-footer px-4 pb-3 pt-0 justify-content-center">
					<template v-if="!props.debtLiquidationPeriod && props.daysToFillRoom !== 0">
						<button class="btn btn-primary btn-suplemento me-3" @click="closeModal('cancel')">
							{{ $t('reserva.plazasLibres.modal.addSuplemento') }}
						</button>
						<button class="btn btn-primary btn-falta" @click="closeModal('submit')">
							{{ $t('reserva.plazasLibres.modal.genteReservar') }}
						</button>
					</template>
					<template v-else>
						<button class="btn btn-primary btn-suplemento me-3" @click="closeModal()">
							{{ $t('reserva.plazasLibres.modal.completarPlazas') }}
						</button>
						<button class="btn btn-primary btn-falta" @click="closeModal('cancel')">
							{{ $t('reserva.plazasLibres.modal.addSuplemento') }}
						</button>
					</template>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script setup>
	import CardOccupancyMini from '@/modules/viajesGrupos/components/CardOccupancyMini.vue';
	import { computed } from 'vue';
	import { agenciaStoreModule } from '@/store_pinia/agencia';

	const props = defineProps({
		show: Boolean,
		plazasLibres: {
			type: Number || String,
			default: '',
		},
		precioTotal: {
			type: Number || String,
			default: '',
		},
		totalOccupancies: {
			type: Number,
			default: 0,
			required: false,
		},
		occupantsInfo: {
			type: Array,
			default: () => [],
			required: false,
		},
		hasHabs: {
			type: Boolean,
			default: false,
			required: false,
		},
		debtLiquidationPeriod: {
			type: Boolean,
			default: false,
			required: false,
		},
		daysToFillRoom: {
			type: Number,
			default: 0,
			required: false,
		},
	});

	const emit = defineEmits(['onClose', 'onSubmit', 'onCancel']);

	const agenciaStore = agenciaStoreModule();

	function closeModal(action) {
		if (action === 'submit') {
			emit('onSubmit');
		} else if (action === 'cancel') {
			emit('onCancel');
		}
		emit('onClose');
	}

	const showModal = computed(() => props.show);
</script>

<style scoped lang="scss">
	.modal-mask {
		position: fixed;
		z-index: 9998;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		transition: opacity 0.3s ease;
	}

	.modal-container {
		max-width: 700px;
		min-width: 700px;
		background-color: #fff;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
		transition: all 0.3s ease;
		border-radius: var(--bs-border-radius-xl);
		padding: 0 !important;

		@media screen and (max-width: 768px) {
			max-width: 500px;
			min-width: 70vw;
		}
	}

	.modal-body {
		max-height: 79vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.modal-default-button {
		float: right;
	}

	.modal-enter-from {
		opacity: 0;
	}

	.modal-leave-to {
		opacity: 0;
	}

	.modal-enter-from .modal-container,
	.modal-leave-to .modal-container {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}

	@media screen and (max-width: 768px) {
		.modal-container {
			width: 95%;
		}
	}

	.modal-title {
		color: #e54a5c;
		font-size: 24px;
		font-weight: 700;

		@media screen and (max-width: 768px) {
			font-size: 16px;
		}
	}

	.background-img {
		background-color: #ffd2d2;
		border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0;
	}

	.btn-suplemento {
		border: 1px solid var(--bs-primary);
		background-color: white !important;
		box-shadow: 0 0 0 0;
		transition: background-color 0.3s, border-color 0.3s;

		&:hover {
			border-color: var(--bs-secondary);
		}
	}
	.btn-falta {
		box-shadow: 0 0 0 0;
	}
</style>
