<template>
	<template v-if="festival">
		<template v-if="!movil">
			<div
				class="d-flex flex-column align-items-center justify-content-center festival-img-fondo"
				:style="`background-image: url(${festival.imagenDesktop})`">
				<template v-if="festival.mostrarDatosCabecera">
					<p
						class="fs-2 titulo text-primary mt-5"
						:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
						{{ capitalize(festival.titulo) }}
					</p>
					<p
						class="fs-6 subtitulo"
						:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
						{{ capitalize(festival.subtitulo) }}
					</p>
					<p
						class="fecha-festival"
						:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
						{{ formatDatesFullMonth(festival.fechaInicio) }} -
						{{ formatDatesFullMonthAndYear(festival.fechaFin) }}
					</p>
				</template>
			</div>
		</template>

		<template v-if="movil">
			<div
				class="d-flex flex-column align-items-center justify-content-end pb-2 festival-img-fondo"
				:style="`background-image: url(${festival.imagenMobile})`">
				<template v-if="festival.mostrarDatosCabecera">
					<p
						class="fs-2 titulo text-primary mt-5"
						:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
						{{ capitalize(festival.titulo) }}
					</p>
					<p
						class="fs-6 subtitulo"
						:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
						{{ capitalize(festival.subtitulo) }}
					</p>
					<p
						class="fecha-festival"
						:style="festival.colorTextoCabecera ? `color: ${festival.colorTextoCabecera} !important` : ''">
						{{ formatDatesFullMonth(festival.fechaInicio) }} -
						{{ formatDatesFullMonthAndYear(festival.fechaFin) }}
					</p>
				</template>
			</div>
		</template>

		<div id="eventos" class="container festivales-detalle">
			<div class="row gap-4">
				<template v-for="evento in eventos" :key="evento">
					<div class="col text-center">
						<TarjetaReservaFestival :evento="evento" :festival="festival" />
					</div>
				</template>
			</div>

			<div class="text-center mt-5">
				<button class="btn btn-secondary" @click="$router.go(-1)">Atras</button>
			</div>
		</div>
	</template>
	<template v-else>
		<Spinner />
	</template>
</template>

<script setup>
	import Spinner from '@/components/SpinnerLoading.vue';
	import { ref } from 'vue';
	import { festivalesStore } from '@/store_pinia/festivales';
	import TarjetaReservaFestival from '@/components/tarjetas/TarjetaReservaFestival';
	import EventosApiService from 'src/services/api/skiandnight/EventosApiService';
	import { useRoute } from 'vue-router';
	import { capitalize } from '@/helpers/filters';
	import { formatDatesFullMonth, formatDatesFullMonthAndYear } from '@/helpers/dateUtiles';

	const festival = ref();
	const eventos = ref([]);
	const store_pinia_festivales = festivalesStore();
	const route = useRoute();
	const uri = route.params.uri;

	store_pinia_festivales
		.setFestival(uri)
		.then(() => {
			festival.value = store_pinia_festivales.festival;
		})
		.then(() => {
			EventosApiService.getEventosFestival(uri).then(res => {
				eventos.value = res;
			});
		})
		.finally(() => {
			var el = document.getElementById('eventos');
			el.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'nearest',
			});
		});

	const movil = window.screen.availWidth <= 575;
</script>

<style lang="scss" scoped>
	.festivales-detalle {
		background-color: white;
		min-height: 80vh;
		background-size: contain;
		background-position: top;
		color: var(--bs-primary);
		font-weight: 400;
		font-size: 0.8rem;
	}
	.festival-img-fondo {
		min-height: 75vh;
		background-size: cover;
		background-position: top;
	}

	.btn-secondary {
		border-radius: var(--bs-border-radius-xl);
		font-weight: 600;
		padding: 0.5rem 1.5rem;
		transition: 0.3s ease-in-out;
		&:hover {
			background-color: var(--bs-primary);
			color: var(--bs-secondary);
			transform: scale(1.1);
		}
	}

	@media (max-width: 575px) {
		.festival-img-fondo {
			min-height: 35vh;
		}
	}

	.titulo-descripcion {
		font-weight: 700;
		font-size: 1.2rem;
		opacity: 0.7;
	}

	.informacion {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 6; /* number of lines to show */
		line-clamp: 6;
		-webkit-box-orient: vertical;
	}

	.descripcion-festival {
		font-size: 0.95rem;
	}

	.ubicacion-festival {
		font-size: 1.3rem;
		margin: 0px;
		font-weight: 700;
		opacity: 0.7;
	}
	.titulo {
		font-weight: 800;
	}

	.subtitulo {
		font-weight: 800;
	}

	.titulo-lineup {
		font-weight: 700;
	}

	.fecha-festival {
		font-weight: 800;
	}

	.titulo-sponsors {
		font-weight: 700;
	}

	.img-carousel-lineup {
		width: 100%;
		padding: 20px;
	}

	.ver-mapa {
		font-size: 0.9rem;
	}

	@media screen and (max-width: 992px) {
		.text-container {
			display: flex;
			flex-direction: column;

			.left,
			.right {
				width: 100%;
			}
		}
	}

	@media (min-width: 576px) {
		//a partir de sm
		.festivales-detalle {
			padding-top: 5rem;
		}
	}

	@media (min-width: 1537px) {
	}
</style>
../../../services/api/skiandnight/EventosApiService
