<template>
	<template v-if="serviciosDisponibles">
		<div class="col card-services">
			<div class="title-card">
				<div class="text">
					<i v-if="selector != 'actividad' && selector != 'festival'" :class="informacionServicio.icono" />
					<template v-if="selector == 'festival'">
						<img src="@/assets/servicios/festival.png" height="40" width="40" alt="" class="me-2" />
					</template>
					<template v-if="selector == 'actividad'">
						<img src="@/assets/servicios/ActividadIcono.webp" height="40" width="40" alt="" class="me-2" />
					</template>
					<span>{{ informacionServicio.titulo }}</span>
				</div>
				<div class="d-flex">
					<div v-if="servicioSeleccionado">
						<span v-if="sumaServicios != 0" class="text-secondary mx-2">
							<span v-if="sumaServicios > 0">+</span>
							{{ sumaServicios }}{{agenciaStore.getCurrency}}
						</span>
					</div>
					<span v-if="sumaServicios != 0" class="dot" />
				</div>
			</div>

			<div class="body-card px-3">
				<div class="row">
					<p class="mt-3">
						{{ informacionServicio.subtitulo }}
					</p>
				</div>
				<div class="row d-flex flex-column flex-md-row">
					<div v-for="(lista, key) in listaSelectores" :key="lista" class="col">
						<div class="row">
							<h6 class="text-primary fw-bold">
								<span v-if="key === 'cancelacion'">Seguro de Anulación</span>
								<span v-else-if="key === 'salud'">Seguro de {{ $t('general.experiencia') }}</span>
								<span v-else>
									{{ firstLetterCapital(key) }}
								</span>
							</h6>
							<div
								v-if="servicioSeleccionado[key] && !servicioSeleccionado[key].nulo"
								class="row text-muted fw-bold justify-content-between mb-2">
								<div class="col-auto">
									{{ servicioSeleccionado[key].descripcion }}
								</div>
								<div v-if="selector === 'seguro'" class="col-6 text-end">
									<button-open-pdf
										button-text="Ver Poliza"
										:id-servicio="servicioSeleccionado[key].id"
										:id-viaje="idViaje" />
								</div>
							</div>
						</div>
						<div class="input-group mb-3">
							<select v-model="servicioSeleccionado[key]" class="form-select">
								<option v-for="servicio in lista.filter(s => s.activo)" :key="servicio.id" :value="servicio">
									<span v-if="!servicio.nulo && selector != 'seguro' && selector != 'festival'">
										{{ servicio.numDias }} <span v-if="servicio.numDias == 1"> {{ $t('general.dia') }}</span>
										<span v-else> {{ $t('general.dias') }}</span>
									</span>
									{{ servicio.nombre }}
									<span>
										<span v-if="!servicio.nulo && key != 'consumicion' && key != 'complemento'">
											( del
											{{ dateMonthNumbers(servicio.fechaInicio) }}
											{{ $t('general.al') }}
											{{ dateMonthNumbers(servicio.fechaFin) }})
										</span>
										<span v-if="servicio.precio > 0">(+</span>
										<span v-if="servicio.precio">
											<span v-if="servicio.precio < 0">(</span>
											{{ servicio.precio }}{{agenciaStore.getCurrency}})
										</span>
									</span>
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</template>
</template>

<script>
	import { ref, reactive, watch } from 'vue';
	import { dateMonthNumbers } from '@/helpers/dateUtiles';
	import { firstLetterCapital } from '@/helpers/languageUtils';
	import { selectServiceText } from '@/constants/selectService';
	import buttonOpenPdf from '@/components/buttons/buttonOpenPdf.vue';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	export default {
		components: { buttonOpenPdf },
		props: {
			/**
			 *  El selector de tipo subtipo son solo validos seguro|actividad|festival
			 */
			selector: {
				type: String,
				required: true,
			},

			serviciosDisponibles: {
				type: Array,
				required: true,
			},

			/**
			 *  Solo require el idViaje para la busqueda de seguro y solo se tiene que usar
			 *  cuando el selector == 'seguro'
			 */
			idViaje: {
				type: String,
				required: false,
				default: null,
			},

			servicioPredefinido: {
				type: Object,
				default: null,
			},
		},
		emits: ['servicioActualizado'],
		setup(props, { emit }) {
			const agenciaStore = agenciaStoreModule();
			const informacionServicios = ref([]);

			const servicioSeleccionado = reactive({});

			const listaSubtipo = ref([]);

			const listaSelectores = ref({});

			const informacionServicio = selectServiceText[props.selector];

			const sumaServicios = ref(0);

			if (props.serviciosDisponibles) {
				informacionServicios.value = [...props.serviciosDisponibles];
			}

			informacionServicios.value.map(({ subtipo }) => {
				if (listaSubtipo.value.indexOf(subtipo) == -1) {
					listaSubtipo.value.push(subtipo);
				}
			});

			listaSubtipo.value.map(nombreSubtipo => {
				let listaAux = informacionServicios.value.filter(({ subtipo }) => nombreSubtipo == subtipo);
				listaSelectores.value[nombreSubtipo] = listaAux;
				servicioSeleccionado[nombreSubtipo] = [];
			});

			listaSubtipo.value.map(nombreSubtipo => {
				servicioSeleccionado[nombreSubtipo] = listaSelectores.value[nombreSubtipo][0];
			});

			watch(servicioSeleccionado, () => {
				let sumaAux = 0;
				Object.keys(servicioSeleccionado).map(precioServicio => {
					if (servicioSeleccionado[precioServicio].precio != undefined) {
						sumaAux += servicioSeleccionado[precioServicio].precio;
					}
				});
				emit('servicioActualizado', servicioSeleccionado);

				sumaServicios.value = sumaAux;
			});

			return {
				informacionServicios,
				servicioSeleccionado,
				dateMonthNumbers,
				listaSelectores,
				firstLetterCapital,
				sumaServicios,
				informacionServicio,
				agenciaStore,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.title-card {
		background-color: #f9f7f8;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--bs-border-radius-lg);

		.text {
			display: flex;
			align-items: center;
			i {
				margin-right: 0.5rem;
			}
		}
	}

	.dot {
		height: 25px;
		width: 25px;
		background-color: var(--bs-secondary);
		border-radius: 50%;
		display: inline-block;
	}

	.dot-disabled {
		height: 25px;
		width: 25px;
		background-color: #727272;
		border-radius: 50%;
		display: inline-block;
	}
</style>
