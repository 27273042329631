<template>
	<div ref="datepickerContainer" class="text-center">
		<button class="btn btn-primary" @click="toggleDatepicker">
			<CalendaryIcon class="me-2" color="white" /> {{ $t('home.banner.eligeFecha') }}
		</button>
		<div v-if="showDatepicker" class="dropdown-menu p-3" style="display: block; position: absolute">
			<div class="position-relativa">
				<Datepicker
					v-model="monthSelection"
					:locale="locale"
					inline
					month-picker
					auto-apply
					@update:modelValue="onMonthSelected" />
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, onMounted, onBeforeUnmount } from 'vue';
	import { useI18n } from 'vue-i18n';
	import Datepicker from '@vuepic/vue-datepicker';
	import CalendaryIcon from '@/components/Icons/CalendaryIcon.vue';
	import { ViajesGrupoCatalogo } from '@/router/RouterNames';
	import { useRouter } from 'vue-router';

	const router = useRouter();
	const showDatepicker = ref(false);
	const monthSelection = ref(null);
	const datepickerContainer = ref(null);
	const { locale } = useI18n();

	function toggleDatepicker() {
		showDatepicker.value = !showDatepicker.value;
	}

	function onMonthSelected(selectedValue) {
		const { year, month } = selectedValue;
		goToCatalogoViajesGrupo(year, month);
		showDatepicker.value = false;
	}

	function goToCatalogoViajesGrupo(year, month) {
		router.push({
			name: ViajesGrupoCatalogo,
			query: { selectedYear: year, selectedMonth: month },
		});
	}

	function handleClickOutside(event) {
		if (datepickerContainer.value && !datepickerContainer.value.contains(event.target)) {
			showDatepicker.value = false;
		}
	}

	onMounted(() => {
		document.addEventListener('click', handleClickOutside);
	});

	onBeforeUnmount(() => {
		document.removeEventListener('click', handleClickOutside);
	});
</script>

<style scoped lang="scss">
	.btn-primary {
		background-color: #2c3e50;
		border-color: #2c3e50;
		border-radius: 50px;
		font-size: 18px;
		padding: 10px 20px;
		color: #fff;
		text-shadow: 0px 0px 1px rgba(var(--bs-primary-rgb), 0.4), 2px 4px 5px rgba(var(--bs-primary-rgb), 0.1);
		box-shadow: 2px 2px 4px rgba(var(--bs-primary-rgb), 0.2);
		transition: background-color 0.3s ease;
		cursor: pointer;
	}

	.btn-primary:hover {
		background-color: #34495e;
	}

	.dropdown-menu {
		padding: 15px;
		border-radius: 12px;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
		margin-top: 10px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	.position-relativa {
		display: flex;
		justify-content: center;
	}

	.text-center {
		position: relative;
		text-align: center;
	}
</style>
