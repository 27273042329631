export default {
  "actions": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])}
  },
  "header": {
    "horarioAtencion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L-J de 9:00h-18:30h, V de 9:00-14:00h"])},
    "ski": {
      "groups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestras experiencias"])},
        "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universitarios"])},
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colegios"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajadores"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
        "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familias"])}
      },
      "destinations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinos"])}
      },
      "stations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estaciones"])}
      }
    },
    "night": {
      "festivals": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivales"])}
      },
      "apres": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après ski"])}
      }
    },
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosotros"])},
      "whoWeAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quiénes somos?"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabaja con nosotros"])}
    },
    "user": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])}
    },
    "login": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Bienvenido!"])},
      "welcomePlanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¡Bienvenido a ", _interpolate(_named("planner")), "!"])},
      "continuarGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar con Google"])},
      "tusDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus datos"])},
      "restablecerPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])}
    }
  },
  "searcher": {
    "organized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestras experiencias"])},
    "personalized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias a medida"])},
    "destiny": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destino"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Dónde queréis ir?"])}
    },
    "date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechas"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige cuando podéis ir"])}
    },
    "datesAccomodation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechas alojamiento"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige cuando queréis ir"])}
    },
    "datesForfait": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechas forfait"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechas de forfait"])}
    },
    "rooms": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitaciones"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige las habitaciones"])}
    },
    "groups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de grupo"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿De dónde venís?"])}
    }
  },
  "filters": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
    "restablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
    "alojamiento": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te has quedado sin alojamiento"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellena los siguientes datos y en caso de quedar libre alguna habitación se te avisará"])}
    }
  },
  "home": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "banner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIVE LA EXPERIENCIA COMPLETA"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete a nuestras experiencias con tus amigos y conoce a una comunidad de viajeros como tú. Selecciona un destino, las fechas en las que te gustaría viajar y tu origen. ¿A qué esperas para unirte a las mejores experiencias en la nieve?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendador de experiencia"])},
      "eligeFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige cuando viajar"])}
    },
    "festivales": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUESTRAS EXPERIENCIAS"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaja con tus amigos a nuestros destinos favoritos donde podrás disfrutar de nuestros dos eventos principales: AWS Formi y WSF Pas de la Casa."])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechas"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localización"])}
    },
    "buscadorSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz tu experiencia a medida"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No te encajan nuestros viajes de grupo? Utiliza nuestro buscador para configurar el viaje totalmente a medida."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al buscador"])}
    },
    "porqueReservar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Por qué reservar con Ski&Night?"])},
      "skiEnjoy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKI, ENJOY"])},
      "sleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLEEP, "])},
      "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REPEAT"])},
      "texto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organiza tu viaje a la nieve a cualquier destino de Europa."])},
      "texto2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" son los destinos perfectos para disfrutar de la nieve y del mejor ocio nocturno."])},
      "texto3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Ski&Night te ofrecemos una experiencia única en los mejores destinos en pleno paraíso natural. Te facilitamos el"])},
      "texto4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" y el acceso a festivales para que solo te preocupes de disfrutar."])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alojamiento, las clases"])},
      "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el alquiler de material"])}
    },
    "packs": {
      "school": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias para colegios"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tienes entre 5 y 17 años y quieres aprovechar al máximo los días de esquí, este es tu experiencia. Mejora tu técnica y disfruta de la naturaleza, nosotros te facilitamos el transporte, alojamiento y monitores. "])}
      },
      "family": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias para familias"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te ofrecemos el alojamiento más cómodo para viajar a la nieve con tu familia. Además, podrás contratar clases de esquí para niños y muchas otras actividades adaptadas a tus necesidades. "])}
      },
      "university": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias para universitarios"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es tu experiencia si quieres coincidir con gente de tu edad en los mejores festivales y après-skis. ¿Te atreves a vivir una experiencia inolvidable en la nieve?"])}
      },
      "company": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias para trabajadores"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viajar con tus compañeros de trabajo es muy fácil. Si tienes más de 25 años, únete a nuestras experiencias organizados para coincidir con gente como tú, en las que el esquí y el ocio nocturno te sorprenderán. "])}
      },
      "premium": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias Premium"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva un experiencia exclusivo a los mejores alojamientos en la nieve complementando los días de esquí con experiencias únicas en la nieve: gourmet, relax, deportes extremos o experiencias culturales."])}
      }
    },
    "festival": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter Snow Festival, nuestro festival"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Ski&Night hemos diseñado la experiencia Winter Snow Festival, nuestro festival en la nieve en el que podrás disfrutar de los DJs y músicos más exclusivos del momento en plena montaña."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este año celebramos la 4 ºedición del WSF, una experiencia única en la nieve que podrás disfrutar en Andorra junto a más de 5.000 personas como tú. "])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta nuestros festivales"])}
    },
    "apres": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los mejores après skis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somos especialistas en organizar las mejores fiestas en la nieve. Después de pasar el día de la nieve podrás disfrutar de buena música de la mano de nuestros DJs en un entorno incomparable. Te presentamos una selección con los après ski más exclusivos:"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos"])}
    },
    "experiencias": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las mejores experiencias"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos"])}
    },
    "offers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las mejores ofertas"])}
    },
    "stations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las mejores estaciones de esquí"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizamos las mejores escapadas de experiencias a la nieve en Formigal, Grandvalira y Sierra Nevada, entre otras estaciones. Echa un vistazo a las estaciones en las que organizamos experiencias y escoge la que mejor se adapte a ti."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar Todos"])}
    },
    "form": {
      "realizarCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizar checkin"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])},
      "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
      "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "personalInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos Personales"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellidos"])},
        "expedicionDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de expedición del DNI/Pasaporte"])},
        "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
        "passwordRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
        "ocultar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar"])},
        "mostrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar"])},
        "password8CharReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 8 caracteres"])},
        "password1MayusReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 1 mayúscula"])},
        "password1NumReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 1 número"])},
        "passwordSeguridadBuena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad de la contraseña: buena"])},
        "welcomeBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido de nuevo"])},
        "emailLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce tu email para iniciar sesión o registrarte"])},
        "contraseniaLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce tu contraseña para iniciar sesión"])},
        "emailOtherLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión con otra cuenta"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
        "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar correo electrónico"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actual"])},
        "newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
        "confirmnewpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar nueva contraseña"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
        "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
        "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Población"])},
        "provincia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
        "codPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código Postal"])},
        "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Nacimiento"])},
        "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexo"])},
        "hombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hombre"])},
        "mujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mujer"])},
        "nacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacionalidad"])},
        "diaHoraLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día y hora de llegada"])},
        "horaSalida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día y hora de salida"])},
        "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugar de Nacimiento"])},
        "diaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dia de Llegada"])},
        "horaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de Llegada"])},
        "anversoReversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anverso y Reverso DNI subido"])},
        "alquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler de material"])},
        "descripcionAlquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos son orientativos, en la propia tienda podrás probarte y cambiarlo por los que mejor se ajusten"])},
        "altura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura (cm)"])},
        "ingresaAltura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa la altura"])},
        "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso (kg)"])},
        "ingresaPeso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el peso"])},
        "tallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talla de pie (EU)"])},
        "ingresaTallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa la talla de pie"])},
        "emailComercialesActivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email comerciales activos"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres contarnos algo más sobre ti?"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe aquí"])},
        "ADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anverso del DNI/Pasaporte"])},
        "msjADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendrá que ser un pdf con formato png, jpg o gif"])},
        "selectedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar documento"])},
        "selecciona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona"])},
        "RDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverso del DNI/Pasaporte"])},
        "anversoReversoDnis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anverso y reverso del DNI/Pasaporte"])},
        "howDid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo nos conociste?"])},
        "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si quieres, puedes adjuntarnos tu CV"])},
        "errorPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al validar contraseña, compruebe las credenciales."])},
        "noTienesCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No tienes cuenta?"])},
        "yaTienesCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya tienes cuenta?"])},
        "aceptoMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto el envío de novedades y comunicaciones comerciales."])},
        "noRecibirMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No quiero recibir novedades y comunicaciones comerciales."])},
        "registrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
        "errorRegPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña debe cumplir con todos los requisitos de seguridad."])},
        "logeate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión"])},
        "dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento de identidad/Pasaporte"])},
        "msjcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para que tu contraseña sea más segura, te recomendamos que utilices una combinación de letras mayúsculas y minúsculas, números y caracteres especiales como *, %, !."])},
        "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
        "iniciarSesionRegistrarReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión o regístrate para reservar"])},
        "registradoCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrado correctamente"])}
      },
      "TripInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características del experiencia"])},
        "whereFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desde dónde viajas?"])},
        "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuántas personas sois?"])},
        "whoTravel": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Con quién viajas?"])},
          "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amigos"])},
          "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familia"])},
          "couple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareja"])},
          "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compañeros de trabajo"])}
        },
        "whenTravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechas del experiencia"])},
        "howManyDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuántos días quieres esquiar?"])},
        "destiny": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿A dónde quieres ir?"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin preferencia, recomiéndame!"])}
        },
        "interests": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy interesado en"])},
          "cousine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastronomia"])},
          "party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiesta y ambiente"])},
          "relax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax y spa"])},
          "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades en la nieve"])}
        },
        "accommodation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Dónde preferirías alojarte?"])},
          "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
          "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartamento"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tengo preferencia"])}
        },
        "tracks": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Distancia a pistas?"])},
          "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero estar a pie de pista"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No me importa moverme"])}
        }
      },
      "Thanks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Te volveremos a contactar en menos de 48 horas!"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por la información. Pronto tendras noticias"])}
      },
      "policy": {
        "readAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He leído y acepto la"])},
        "readAccepts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He leído y acepto las"])},
        "readAcceptsLos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He leído y acepto los"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y los"])},
        "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["términos y condiciones"])},
        "freePlacesPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de plazas libres"])},
        "policyCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Cancelación"])},
        "policyAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Alojamiento Plena Ocupación"])},
        "cancelReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelación de la Reserva"])},
        "titleCondicionesCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones y gastos de cancelación"])},
        "msgCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las condiciones de reserva para el experiencia son las siguientes."])},
        "descriptionCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el cliente desiste del experiencia, después de la formalización del depósito exigido por considerar firme la reserva REEMBOLSABLE, tendría que abonar las siguientes sumas :"])},
        "descriptionCondicionesNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el cliente desiste del experiencia, después de la formalización del depósito exigido por considerar firme la reserva NO REEMBOLSABLE, tendría que abonar las siguientes sumas :"])},
        "detail1Condiciones": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El ", _interpolate(_named("porcentaje")), "% si se produce entre ", _interpolate(_named("fechaInicio")), " y  antes de ", _interpolate(_named("fechaFin"))])},
        "detail2Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El 100% si es dentro de los 15 días naturales antes de la salida."])},
        "detail3Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La reserva del experiencia no se devolverá bajo ninguna circunstancia"])},
        "detail4Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["También puedes realizar un cambio de titular de la reserva para que un compañero te sustituya, este cambio se podrá realizar con una antelación máxima de 5 días antes de la fecha de inicio del experiencia y puede tener un coste asociado."])},
        "detail5Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los gastos de gestión asociados a modificaciones en la reserva o por el incumplimiento de los plazos del pago de la misma no serán reembolsables en ningún caso."])},
        "detail1Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Del ", _interpolate(_named("fechaInicio")), " al ", _interpolate(_named("fechaFin")), " "])},
        "detail2Condiciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los suplementos y gastos asociados a modificaciones en la reserva o por el incumplimiento de los plazos del pago de la misma y las tasas no serán reembolsables en ningún caso."])},
        "detail3Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Podrás modificar tu reserva de manera gratuita hasta el ", _interpolate(_named("fecha")), " en el apartado Mis reservas en tu perfil."])}
      }
    }
  },
  "sponsors": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsors y colaboradores"])}
  },
  "footer": {
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quiénes somos?"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabaja con nosotros"])},
      "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])}
    },
    "saleGroups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas para grupos"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias para colegios"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias para familias"])},
      "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias para universitarios"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias para empresas"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias premium"])}
    },
    "saleSki": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas para esquiar"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquí Puente de Diciembre"])},
      "christmas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquí Navidad"])},
      "newYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquí Año Nuevo"])},
      "holyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquí Semana Santa"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras ofertas"])}
    },
    "terminosCondiciones": {
      "bono": {
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones legales de venta de servicios TRAVEL&NIGHT S.L. - NIF B87814125 La adquisición de esta entrada representa la aceptación de las condiciones legales, política de privacidad y cookies que puede consultar en la web de la compra. Este bono es revocable en cualquier momento por el organizador mediante el ofrecimiento de la devolución del importe de la misma. La organización establece sus propias condiciones legales para el acceso y venta de servicios que deberás consultar en sus medios o solicitar expresamente al mismo.Para cualquier reclamación relativa a la organización del evento o experiencia, el portador de este bono deberá dirigirse al organizador. No se admiten cambios o devoluciones, solo aquellas autorizadas por el organizador. La organización se reserva el derecho de admisión y podrá negar o expulsar el disfrute de cualquiera de los servicios que forman parte de la experiencia. TRAVEL&NIGHT no se responsabiliza de ningún problema derivado de la falsificación, duplicación o venta ilegal de esta entrada. En caso de devoluciones, el organizador se reserva el derecho a no devolver los gastos de gestión ya que esta ha sido correctamente realizada por la plataforma."])}
      },
      "orgTecJuridica": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. ORGANIZACIÓN TÉCNICA Y REGULACIÓN JURÍDICA."])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cumplimiento de lo previsto en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI) se informa que el titular del Sitio Web es Travel&Night S.L. (en adelante, TRAVEL&NIGHT) con domicilio en C/ Gloria Fuertes,4 5B, 28049, Madrid, Madrid, con C.I.F. número B-87814125, inscrita en el Registro Mercantil de la Comunidad de Madrid."])}
      },
      "inscripciones": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. INSCRIPCIONES"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las condiciones de inscripción a una experiencia se detallarán en el proceso de reserva de la propia y prevalecerán sobre cualquier otra condición. En caso de no existir, se tomará por defecto: La inscripción del cliente en el viaje y la consideración de la reserva en firme exige el pago de la suma equivalente al 30% del precio total del viaje. El 70% restante se deberá abonar, como máximo, 21 días naturales antes de la salida del viaje."])}
      },
      "condicionContractual": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. CONDICIÓN CONTRACTUAL"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La realización del viaje con bus queda condicionada a la inscripción de un mínimo de 40 personas. En el caso que no se forme el grupo, la agencia puede cancelar el viaje sin derecho de indemnización para el cliente siempre que la agencia se lo comunique en un plazo máximo de 5 días naturales antes de la fecha prevista de salida."])}
      },
      "cancelaciones": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. CANCELACIONES"])},
        "descripcionA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a) Las reservas del buscador web, en caso de existir, tendrán sus condiciones particulares aceptadas por el cliente en el momento de la compra."])},
        "descripcionB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b) Las condiciones de cancelación de las reservas en experiencias grupales se detallarán en el proceso de reserva de la propia y prevalecerán sobre cualquier otra condición. En caso de no existir se tomarán por defecto las siguientes:"])},
        "cancelacionGratuita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se acepta cancelación gratuita en ninguno de los casos. Las condiciones serán las siguientes:"])},
        "noReembolsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- La reserva en ningún caso es reembolsable."])},
        "25%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- El 25% si la cancelación se produce entre 45 y 30 días naturales antes de la salida."])},
        "50%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- El 50% si se produce entre 30 y 21 días naturales antes de la salida."])},
        "100%": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- El 100% si es dentro de los 20 días naturales antes de la salida."])},
        "derechoReembolso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente no tiene derecho a ningún rembolso del total del alojamiento."])},
        "modifReembolso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Los gastos de gestión asociados a modificaciones en la reserva o por el incumplimiento de los plazos del pago de la misma no serán reembolsables en ningún caso."])}
      },
      "modificaciones": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. MODIFICACIONES"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier modificación o cambio puede estar sujeto a gastos de gestión asociados al mismo en función del servicio y de la antelación con el que se requiere."])}
      },
      "precioViaje": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. PRECIO DEL VIAJE"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El precio del viaje ofrecido en esta web incluye el impuesto sobre el valor añadido cuando es aplicable, pero no incluye ningún tipo de seguro ni las tasas de los apartamentos. Los precios quedan vigentes hasta la comunicación de lo contrario . El precio puede ser revisado hasta 20 días naturales antes de la fecha de salida en el caso que haya variaciones en el coste de los transportes (incluido el coste del carburante), en las tasas e impuestos referentes a determinados servicios (como los de aterrizaje, embarque o desembarque en puertos y aeropuertos) y en el tipo de cambio aplicados al viaje. Frente a la variación del precio, el cliente puede aceptar el suplemento o bien desistir del servicio que se hubiera visto modificado. La Media Pensión, si estuviere contratada, consiste en el desayuno y la cena, las bebidas en la cena y los extras de cualquier tipo NO están incluidos."])}
      },
      "establecimientosAlojamiento": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. ESTABLECIMIENTOS DE ALOJAMIENTO"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El contenido y la calidad de los servicios prestados por el establecimiento de alojamiento turístico son determinados por la categoría turística local especificada en este opúsculo. TRAVEL&NIGHT se reserva la facultad de variar un determinado establecimiento siempre que se mantenga la misma categoría contratada. Cualquier cambio por razones externas u operativas, siempre que se mantenga esa calidad mencionada previamente, no tendrá por qué suponer gasto de cancelación gratuita para el cliente. En el caso del alojamiento en apartamento el horario de entrada será a partir de las 17.00 horas y la salida antes de las 11.00 horas. Los niños que tengan plaza gratuita en el hotel, el régimen será de solo alojamiento y los gastos que originen serán pagados directamente al hotel por los clientes."])}
      },
      "documentacion": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. DOCUMENTACIÓN"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente tiene la obligación de llevar siempre el documento nacional de identidad o el pasaporte en sus ejemplares originales y vigentes. Los menores de 18 años tienen que llevar un documento que les autorice a hacer el viaje, firmado por los dos padres, por el padre que tenga la guardia y custodia (en caso de matrimonios separados o divorciados) o por el tutor. Esta autorización se puede tramitar en comandancias, comisarías y cuarteles de fuerzas y cuerpos de seguridad del Estado que estén habilitados para ello. "])}
      },
      "responsabilidad": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. RESPONSABILIDAD"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La agencia de viajes quedará eximida de su responsabilidad por la no ejecución o la ejecución deficiente del contrato en los siguientes supuestos\":\" cuando se deba a una causa de fuerza mayor (circunstancia ajena, anormal e imprevisible), cuando sea un acontecimiento que ni la agencia organizadora ni los proveedores hayan podido evitar aun habiendo hecho la diligencia necesaria, o cuando la causa sea imputable al cliente o a un tercero. Si por causas meteorológicas, por vaga o por otros motivos de fuerza mayor quedarán afectados los servicios, la organización se compromete a realizar todas las gestiones necesarias en beneficio de los pasajeros afectados para estas situaciones, pero en cualquier caso, la incidencia económica, los gastos de alojamiento, manutención, transporte y cualquier otra que se origine como a consecuencia de esto, irá a cargo exclusivo del cliente, quedando la organización eximida de estas responsabilidades porque están fuera de su ámbito de acción. El cliente queda obligado a comunicar a la agencia organizadora, tan pronto como sea posible y por escrito, cualquier incumplimiento en la ejecución del contrato que haya comprobado “in situ”."])}
      },
      "seguros": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. SEGUROS"])},
        "descripcionA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel & Night opera con los seguros exigidos en el Real Decreto 271/1988, de 25 de marzo, por el que se regula el ejercicio de las actividades propias de las Agencias de Viajes."])},
        "descripcionB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los seguros a título nominal, correrán a cargo de los clientes, si así lo dispusieran, tales como seguros de anulación o de cobertura durante el desarrollo de los viajes."])}
      },
      "normas": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11. NORMAS INELUDIBLES"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En los viajes con autocar cada cliente sólo podrá llevar una maleta y el material de esquí. No está permitido llevar cajas ni animales. Durante el viaje en autocar, el cliente queda obligado a respetar la convivencia pacífica con los otros viajeros y a cumplir las normas propias de la empresa transportista (no fumar, no comer, no provocar alborotos, etc.). El cliente tiene que satisfacer in situ las fianzas de los apartamentos. Ni las tasas propias de cada alojamiento ni las fianzas están incluidos en el precio del viaje. Las fianzas serán devueltas al final de la experiencia por parte del alojamiento, una vez comprobado el correcto estado del apartamento por la inmobiliaria responsable. La empresa organizadora no se hace cargo, en ningún caso, de cualquier disputa que pueda resultar a causa del hospedaje de los clientes, sí tratará siempre de mediar para tratar de conseguir un resultado justo derivado de las consecuencias del mismo."])}
      },
      "datosPersonales": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12. PROTECCIÓN DE DATOS PERSONALES"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La agencia de viajes quedará eximida de su responsabilidad por la no ejecución o la ejecución deficiente del contrato en los siguientes supuestos: cuando se deba a una causa de fuerza mayor (circunstancia ajena, anormal e imprevisible), cuando sea un acontecimiento que ni la agencia organizadora ni los proveedores hayan podido evitar aun habiendo hecho la diligencia necesaria, o cuando la causa sea imputable al cliente o a un tercero. Si por causas meteorológicas, por vaga o por otros motivos de fuerza mayor quedarán afectados los servicios, la organización se compromete a realizar todas las gestiones necesarias en beneficio de los pasajeros afectados para estas situaciones, pero en cualquier caso, la incidencia económica, los gastos de alojamiento, manutención, transporte y cualquier otra que se origine como a consecuencia de esto, irá a cargo exclusivo del cliente, quedando la organización eximida de estas responsabilidades porque están fuera de su ámbito de acción. El cliente queda obligado a comunicar a la agencia organizadora, tan pronto como sea posible y por escrito, cualquier incumplimiento en la ejecución del contrato que haya comprobado “in situ”."])},
        "apartados": {
          "responsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESPONSABLE"])},
          "responsableDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel&Night S.L."])},
          "finalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FINALIDAD"])},
          "finalidadDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorio de contactos y gestión financiera, administrativa y comercial de las actividades desarrolladas por Travel&Night S.L."])},
          "legitimacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEGITIMACIÓN"])},
          "legitimacionDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celebración y ejecución de contrato y consentimiento del interesado."])},
          "destinatario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DESTINATARIO"])},
          "destinatarioDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus datos serán cedidos a las empresas encargadas del alojamiento reservado, a las centrales de reserva de hoteles y apartamentos, a los proveedores de servicios externos y entidades aseguradoras y en todos los casos en que se establezca por obligación legal."])},
          "derechos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DERECHOS"])},
          "derechosDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De acceso, de rectificación, de supresión, de limitación de tratamiento, de portabilidad y de oposición de los datos como se explica en la información adicional."])},
          "adicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMACIÓN ADICIONAL"])},
          "adicionalDescripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede consultar la información adicional y detallada sobre nuestra Política de Protección de Datos remitiendo una consulta a "])}
        }
      }
    },
    "copyright": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juan Hurtado de Mendoza, 17 posterior, 1º Dcha, 28036 - Madrid, España"])},
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso legal"])},
      "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de cookies"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones generales"])}
    },
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Síguenos!"])}
  },
  "us": {
    "whoWeAre": {
      "banner": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOBRE NOSOTROS"])},
        "text": {
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somos una plataforma digital que está revolucionando el sector del esquí, diseñando experiencias a la nieve, tanto individuales como en grupo. Desde nuestro origen, hemos llevado a más de 30.000 viajeros a todas las estaciones de España, Andorra y Francia."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra especialidad es conectar viajeros y crear comunidades a través del ocio y el deporte en la nieve. Contamos con los mejores après-skis y con un festival propio en la nieve, el Winter Snow Festival en Andorra. Además, ofrecemos experiencias a medida para familias, empresas, colegios y gourmet en los que nos adaptamos a las necesidades y gustos de nuestros viajeros, ofreciéndoles la mejor oferta de relax, restauración y ocio. "])}
        }
      },
      "team": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EQUIPO SKI&NIGHT"])}
      },
      "values": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALORES DE SKI&NIGHT"])},
        "enviroment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiente joven y cercano"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Ski&Night somos una empresa joven, formada por un equipo cercano y flexible, lo que nos permite conocer muy bien el producto que vendemos."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El buen rollo se transmite a la hora de crear experiencias en la nieve y en la forma de adaptarnos a las necesidades de nuestros viajeros,  tanto para universitarios como para colegios, empresas, gourmet y familias. "])}
        },
        "honesty": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honestidad y transparencia"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La honestidad es una de las claves de nuestra compañía, siendo nuestra máxima a la hora de actuar tanto con nuestros viajeros como de cara a nuestros proveedores. "])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde nuestro origen, más de 25.000  viajeros han confiado en nosotros y un alto porcentaje repite su experiencia con S&N, con una tasa de fidelidad que asciende al 83,8 %."])}
        },
        "compromise": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compromiso y sentido de pertenencia"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La marca Ski&Night se ha convertido en un estilo de liderazgo y compromiso, una comunidad que ha evolucionado desde su origen dando lugar a una marca líder en el sector de la nieve. "])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un compromiso que se extiende no solo a la relación con los viajeros, sino a los proveedores, estaciones y el entorno natural en el que se desarrolla nuestra actividad. "])}
        },
        "figures": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRANDES CIFRAS GLOBALES"])},
          "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AÑOS DE EXPERIENCIA"])},
          "travelers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIAJEROS"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIAJES GRUPO"])},
          "collaborations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COLABORACIONES"])}
        },
        "season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESUMEN TEMPORADA 2021/22"])}
      }
    }
  },
  "porqueReservar": [
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comodidad en el pago"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decide si prefieres pagar la experiencia completa o solo tu parte y consulta el estado en tu perfil."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención personalizada"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro personal de atención al cliente y guías en destino resolverán todas tus dudas."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibilidad en tus experiencias"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona los extras y servicios que necesites y personaliza tu experiencia al máximo."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocio a tu medida"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disfruta de una amplia oferta de relax, actividades en la nieve, gastronomía y ocio nocturno."])}
    }
  ],
  "porqueReservar2": [
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comodidad en el pago"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decide si prefieres pagar la experiencia completa o solo tu parte y consulta el estado en tu perfil."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención personalizada"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro personal de atención al cliente y guías en destino resolverán todas tus dudas."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibilidad en tus experiencias"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona los extras y servicios que necesites y personaliza tu experiencia al máximo."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocio a tu medida"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disfruta de una amplia oferta de relax, actividades en la playa, gastronomía y ocio nocturno."])}
    }
  ],
  "viajesCatalogo": {
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["experiencias disponibles"])},
    "placeholderSelectMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un mes"])},
    "tituloFiltroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poblaciones"])},
    "tituloFiltroLugarExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugar de la experiencia"])},
    "tituloFiltroPrecios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios"])},
    "tituloFiltroFestivales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivales"])},
    "filtroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige los lugares a los que te gustaría ir"])},
    "filtroPrecios": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elige el rango de precios (", _interpolate(_named("currency")), ") en los que quieres buscar"])},
    "filtrosFestivales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige los festivales a los que te gustaria ir"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete a uno de estos experiencias en grupo y disfruta de la experiencia con tus amigos. Puedes reservar una nueva habitación o unirte a una existente"])},
    "descriptionPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva uno de nuestras experiencias exclusivos a la nieve con experiencias relax, gourmet, culturales o extreme. También puedes contactar directamente con nosotros en este teléfono <a href='https://wa.me/+34910054547' target='_blank'><span class='mx-2 text-primary'> +34 910054670 </span></a> y diseñaremos un experiencia completamente adaptado a tus necesidades."])}
  },
  "seleccionAlojamientos": {
    "1availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alojamiento disponible"])},
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alojamientos disponibles"])},
    "descriptionTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para tu experiencia en grupo tenemos disponibles estos alojamientos"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver ubicaciones"])},
    "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alojamientos"])},
    "1places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alojamiento"])},
    "ordenarPor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por"])},
    "ordenarMenorMayor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar de menor a mayor precio"])},
    "ordenarMayorMenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar de mayor a menor precio"])}
  },
  "mejoresOfertas": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las mejores ofertas"])}
  },
  "porqueReservaTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Por qué reservar en Ski and Night?"])},
  "porqueReservaTitulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Por qué reservar en Sea and Night?"])},
  "habitacionBuscador": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayúdanos a encontrar el mejor precio, organiza tus habitaciones"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el número de habitaciones/apartamentos"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitación"])},
    "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adultos"])},
    "ninos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niños"])},
    "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niño"])},
    "edadNino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edad de los niños"])}
  },
  "viajesGrupos": {
    "todosPublicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los públicos"])},
    "mas25anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de 25 años"])},
    "de18a25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 18 a 25 años"])},
    "de0a17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 0 a 17 años"])},
    "msjPlazasLibres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plazas libres en la habitación, en el caso de que quedarse libres se asumirá un sobrecoste de"])},
    "msjRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por cada plaza libre a repartir entre los integrantes de la habitación en concepto “Resto del alojamiento”"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "plazaLibre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaza libre"])},
    "invitarCorreo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes invitar por correo pulsando el botón."])},
    "llenarInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llenar la información de Cuéntanos algo más de ti."])},
    "faltaPoliticasPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta confirmar políticas de privacidad y política de alojamiento plena ocupación."])},
    "faltaPoliticasCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta confirmar políticas de cancelación."])},
    "faltaRespuestasFormulario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta responder todas las preguntas del formulario."])}
  },
  "reserva": {
    "misReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis reservas"])},
    "reactivarParaRecuperla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulsa el botón reactivar para recuperla"])},
    "proximasReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus próximas reservas"])},
    "misQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis QRs"])},
    "proximasNights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis próximos tickets"])},
    "estadoPagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del pago de la reserva"])},
    "pendientePago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente de pago"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total reserva"])},
    "reservaEncuentra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta reserva se encuentra"])},
    "miViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi experiencia"])},
    "verQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver QRs"])},
    "reservasCancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona las reservas que quieras cancelar"])},
    "mensajeCompraExp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pincha ", _interpolate(_named("aqui")), " para ver las todas las experiencias disponibles"])},
    "mensajeCompraTicket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pincha ", _interpolate(_named("aqui")), " para ver las todos las fiestas disponibles"])},
    "graciasReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por reservar"])},
    "noReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no tienes experiencias"])},
    "noFiestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no tienes tickets"])},
    "moreAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuéntanos algo más de ti"])},
    "howPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige cómo quieres pagar"])},
    "pagoPlazosDeshabilitado": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ya estás al día con tu calendario de pagos, puedes realizar el siguiente pago a partir del ", _interpolate(_named("fecha")), "."])},
    "pagoSenial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago señal"])},
    "totalPagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total pagado"])},
    "saldoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo pendiente"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
    "paga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga"])},
    "pagoTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago total reserva"])},
    "totalDevolver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL a devolver"])},
    "gastoCancelacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gastos de cancelación ", _interpolate(_named("fechaHoy"))])},
    "complementos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complementos"])},
    "suplementos": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suplementos y gastos"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suplementos y gastos"])},
      "PLAZAS_LIBRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plazas libres"])}
    },
    "tasas": {
      "externas": {
        "TASA_TURISTICA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa turística"])}
      },
      "internas": {
        "TASA_GESTION_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de gestión de la plataforma"])},
        "TASA_GESTION_CAMBIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de gestión de cambio"])}
      }
    },
    "tasa": {
      "externas": {
        "turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa turística"])}
      },
      "gestion-plataforma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de gestión de la plataforma"])},
      "gestion-cambio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de gestión de cambio"])}
    },
    "pagos": {
      "pago": {
        "senial": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago señal"])}
        },
        "total": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago de la experiencia total"])}
        },
        "viaje": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago parcial"])}
        }
      }
    },
    "tipo": {
      "PC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensión completa"])},
      "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media pensión"])},
      "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alojamiento y desayuno"])},
      "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo alojamiento"])},
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo desayuno"])}
    },
    "superGrupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super-Grupo"])},
      "informacionTransporte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información Transporte"])},
      "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de tu experiencia"])},
      "fiestaContradas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiestas contratadas"])},
      "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear super-grupo"])},
      "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirse a super-grupo"])},
      "msjNoGrupoamigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu grupo todavía no tiene ningún grupo amigo. Crea un supergrupo o únete a uno existente antes de la fecha de pedido para viajar en el mismo autobús y alojarte en habitaciones lo más cercana posibles."])},
      "msjSuperGruposEdificio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los supergrupos sólo se tendrán en cuenta para edificios cercanos debido al punto de llegado"])},
      "pertenceSuperGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perteneces al supergrupo"])},
      "msjPuntoLlegado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los supergrupos sólo se tendrán en cuenta para edificios cercanos debido al punto de llegado"])}
    },
    "plazasLibres": {
      "modal": {
        "tituloSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¡Aún hay ", _interpolate(_named("plazasLibres")), " plaza libre en tu reserva!"])},
        "tituloPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¡Aún hay ", _interpolate(_named("plazasLibres")), " plazas libres en tu reserva!"])},
        "bodySingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En tu reserva cabe ", _interpolate(_list(0)), " persona más. Se añadirá un suplemento de ", _interpolate(_list(1)), " a cada persona confirmada. Si finalmente se completan las plazas libres se devolverá el suplemento."])},
        "bodyPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En tu reserva caben ", _interpolate(_list(0)), " personas más. Se añadirá un suplemento de ", _interpolate(_list(1)), " a cada persona confirmada. Si finalmente se completan las plazas libres se devolverá el suplemento."])},
        "addSuplemento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir suplemento a la reserva"])},
        "genteReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta gente por reservar"])},
        "completarPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar plazas"])}
      }
    },
    "grupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
      "msjGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrupa tu reserva con otras. Los grupos se tendrán en cuenta de cara los servicios de la experiencia, por ejemplo al distribuir los transportes. Para crear un grupo envía el link a los usuarios de las otras reservas."])},
      "miembrosGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembros del grupo"])}
    },
    "bono": {
      "descargarBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar bono"])},
      "fechaIni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha inicio"])},
      "fechaFin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha fin"])},
      "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
      "informacionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])},
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In"])},
      "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Out"])},
      "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régimen"])},
      "impuestosIncluidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuestos incluidos"])},
      "incluidoPaqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluido en paquete básico"])},
      "servicioExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio Extra"])},
      "localizador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localizador"])},
      "titular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titular"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email de contacto"])}
    },
    "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["más información del alojamiento"])},
    "informacionFestival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información del festival"])},
    "msjFechaLimite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha límite de pago es el día"])},
    "msjPagoRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya se ha realizado el pago de su experiencia. Esté atento por si se publican fiestas."])},
    "msjNoPagos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Todavía tienes ", _interpolate(_named("pendientePago")), _interpolate(_named("currency")), " pendiente de pago."])},
    "reservasDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva/s de"])},
    "mantenerReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantener Reserva"])},
    "msjSolucionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podemos ayudarte a encontrar una solución alternativa si tienes que hacer cambios en tu reserva"])},
    "proximosViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximas experiencias:"])},
    "condicionesReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las condiciones de reserva para el experiencia son las siguientes"])},
    "concepto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concepto"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
    "recuperarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar reserva"])},
    "codViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es el código de tu experiencia"])},
    "mensajeThankYouSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tienes ", _interpolate(_named("plazaslibres")), " plaza libre, comparte el link para completarlas o se añadirá un suplemento de ", _interpolate(_named("suplemento")), " a cada persona confirmada."])},
    "mensajeThankYouPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tienes ", _interpolate(_named("plazaslibres")), " plazas libres, comparte el link para completarlas o se añadirá un suplemento de ", _interpolate(_named("suplemento")), " a cada persona confirmada."])},
    "mensajeThankYouCompleto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu reserva se compone de las siguientes personas."])},
    "mensajeThankYouAnular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si no se completa antes del ", _interpolate(_named("fechaLimite")), " se cancelará automáticamente."])},
    "mensajeFechaLimite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fecha límite de pago: ", _interpolate(_named("fechaLimite")), "."])},
    "repartirCoste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Si no se completan las plazas libres  se añadirá un suplemento a cada persona confirmada."])},
    "enlacePersonas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al acabar la reserva, envía el enlace que te proporcionaremos al resto de personas para que se unan."])},
    "mensajeExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos deseando verte y disfrutar de la experiencia juntos."])},
    "msjAnadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes añadir algunos extras si se te han olvidado"])},
    "anadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir extras"])},
    "masExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más extras"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "serviciosContrados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios Contratados"])},
    "realizarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizar Pago"])},
    "cambioHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de habitación"])},
    "informesTotales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes Totales"])},
    "cancelacionEntre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si la cancelación se produce entre el "])},
    "costesAsociadosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los costes asociados a la cancelación de la reserva son:"])},
    "msjReservaDescartada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La reserva fue descartada"])},
    "msjReservaActiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente ya tiene una reserva en este experiencia, elija otro experiencia o consulte sus reservas"])},
    "descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento"])},
    "descuentoCod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de descuento"])},
    "descuentoCodIntrod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdúcelo aquí"])},
    "descuentoCodError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El código de descuento '", _interpolate(_named("codigo")), "'. No es válido."])},
    "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquete Básico"])},
    "suplementosGastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suplementos y gastos"])},
    "msjPendientePagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispone de una reserva pendiente de pago para este experiencia ¿Desea descartarla o continuar con ella?"])},
    "revisaDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa los datos de tu reserva"])},
    "pagarTotalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar el importe total de la reserva"])},
    "enlaceUnirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al acabar la reserva, envia el enlace que te proporcionaremos al resto de personas para que se unan"])},
    "recuerdaPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuerda que puedes pagar la reserva íntegra o solo una parte"])},
    "pagarAhora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar ahora"])},
    "abonarAntesDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y abonar la cantidad restante antes del"])},
    "pagarPlazos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar a plazos."])},
    "pagarTotalRestante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar todo el importe restante de la reserva"])},
    "reservaCancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva cancelada"])},
    "msjCheckInReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para agilizar el proceso de check-in tu llegada, es necesario que rellenes tu información, puedes rellenar tus datos o todos los de tu misma habitación/apartamento"])},
    "msjDatosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes consultar los datos de la reserva de las personas de las que hiciste el primer pago"])},
    "disponeCodReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispones de un codigo de reserva"])},
    "msjFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alguien ha dicho fiesta"])},
    "msjNoFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no hay fiestas disponibles para ninguno de tus experiencias. En cuanto se confirmen se te notificará por email para que puedas entrar a reservar tu plaza."])},
    "msjAmigoReservado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tienes un amigo que ya ha reservado y quieres unirte a su habitación, haz clic en el siguiente botón"])},
    "gastos": {
      "gasto": {
        "tasa-turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos por tasa turistica"])},
        "gestion": {
          "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos extras de gestión"])}
        }
      },
      "FEE_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de gestión de la plataforma"])},
      "incremento": {
        "camas": {
          "vacias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasto por resto de alojamiento"])}
        }
      },
      "recargo": {
        "pago": {
          "tardio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasto por pago tardio"])}
        }
      },
      "complemento": {
        "plazas": {
          "libres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suplemento por plazas libres"])}
        }
      }
    },
    "visualizacionOcupantes": {
      "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaza"])},
      "libre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["libre"])}
    }
  },
  "rooming": {
    "miRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi rooming"])},
    "resena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjanos una reseña"])},
    "siguenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síguenos"])},
    "gestorRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestor de rooming"])},
    "tooltipAnadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez hayas pulsado aquí selecciona los integrantes en la tabla derecha"])},
    "organizarDistribucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizar distribución"])},
    "tooltipMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la habitación que quieras distribuir y después a las personas que quieras meter en esa habitación de la tabla izquierda"])},
    "cardHabitacion": {
      "anadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Añadir integrantes"])},
      "selectFromTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona los integrantes de la tabla"])}
    },
    "estado": {
      "NUEVO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo"])},
      "EN_EDICION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En edición"])},
      "PUBLICADO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicado"])}
    },
    "tabla": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellidos"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alojamiento"])},
      "sinRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin repartir"])},
      "repartidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repartidos"])},
      "buscarNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar nombre"])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
      "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar"])},
      "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])}
    },
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "hasTerminado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Has terminado?"])},
    "guardarSalir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar y salir"])},
    "guardarSalirMensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no has terminado la distribución y deseas continuar más tarde pulsa en “Guardar y salir”. Si ya has terminado pulsa en “Enviar”, ten en cuenta que una vez enviada no se podrá realizar ningún cambio."])},
    "error": {
      "publicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al publicar tu rooming"])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al guardar tu viaje"])}
    }
  },
  "alojamiento": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alojamiento"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las mejores ofertas"])},
    "nuestrasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestras experiencias"])},
    "imagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imágenes"])},
    "verImagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver imágenes"])},
    "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galería"])},
    "compartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
    "tipos": {
      "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galería"])},
      "apartamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartamento"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "albergue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albergue"])},
      "estudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estudio"])},
      "no aplica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No aplica"])}
    },
    "lineUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line up confirmado"])},
    "compartirLanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Mira el viaje que he encontrado!"])},
    "consigueEntrada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consigue tu entrada"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOSTRAR MÁS >"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOSTRAR MENOS >"])},
    "consigueAbono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consigue tu abono"])},
    "todasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas las experiencias"])},
    "proximamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximamente..."])},
    "verFoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas las fotos"])},
    "verMapa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver mapa"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver ubicaciones"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del alojamiento"])},
    "ofertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas"])},
    "preguntasFrecuentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes"])},
    "horariosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horarios y condiciones especiales"])},
    "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in"])},
    "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out"])},
    "distancias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancias"])},
    "accesoPistas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso a pistas"])},
    "puntoInteres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos de interés"])},
    "descripcionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del alojamiento"])},
    "regimenDisponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regímenes disponibles y tipos de habitación"])},
    "deEstacionEsqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la estación de esquí"])},
    "cancelacionGratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelación GRATIS"])},
    "parcialmenteReembolsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente reembolsable"])},
    "otrasOpcionesViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras opciones de experiencia"])},
    "servicioHotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios de Hotel"])},
    "ubicacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación"])},
    "fechaAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Alojamiento"])},
    "filtrosAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro Alojamientos"])}
  },
  "validaciones": {
    "pagador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagador: "])},
    "validadaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERROR: ENTRADA YA VALIDADA"])},
    "validadaExito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRADA VALIDADA CON EXITO"])},
    "validadaFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validada el "])},
    "aLas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" a las "])},
    "cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "unidades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unid."])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "preguntaValidar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres validar la entrada?"])},
    "validar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])}
  },
  "general": {
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El"])},
    "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquí"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "teneis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenéis"])},
    "grupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
    "antesDel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["antes del"])},
    "ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ahora"])},
    "checkInOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In Online"])},
    "checkInRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In Realizado"])},
    "codigoCopiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código copiado"])},
    "pagoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago Pendiente"])},
    "articulosRelacionados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículos relacionados"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mejores ofertas"])},
    "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelación"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver menos"])},
    "pagos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos"])},
    "atras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi perfil"])},
    "siguiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "ordenar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por"])},
    "anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["años"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opción"])},
    "experiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencia"])},
    "mostrarMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar más"])},
    "viajeAMedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencias a medida"])},
    "mostrarMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar menos"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "cerrarSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
    "solucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solución"])},
    "para": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para"])},
    "pagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagado"])},
    "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desde"])},
    "seleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección"])},
    "terminosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y Condiciones"])},
    "proteccionDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protección de Datos Personales"])},
    "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por persona"])},
    "masInformacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información de este experiencia"])},
    "masInformacionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información alojamiento"])},
    "variasOcupaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varias ocupaciones disponibles"])},
    "eligeAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige tu alojamiento"])},
    "quieroSaberMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero saber más"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos"])},
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservar alojamiento"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirse a habitación"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
    "ubicacionesAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver mapa"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver ubicaciones"])},
    "bookingLetterButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "codigoInvalido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código no es válido."])},
    "bookingBusDeparture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salida"])},
    "experienceFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viajes para"])},
    "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días"])},
    "dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día"])},
    "diaMayus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DÍA"])},
    "noches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noches"])},
    "noche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noche"])},
    "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["del"])},
    "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al "])},
    "aplicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])},
    "plazaUno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaza"])},
    "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plazas"])},
    "cantidadDisponibles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Más de ", _interpolate(_named("cantidad")), " disponibles"])},
    "disponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibles"])},
    "agotadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agotadas"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservar"])},
    "listaEspera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de espera"])},
    "listaEsperaSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu petición se añadió a la lista de espera satisfactoriamente"])},
    "listaEsperaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ha sido posible agregar a la lista de espera"])},
    "personas": {
      "singular": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adulto"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niño"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persona"])}
      },
      "plural": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adultos"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niños"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personas"])}
      },
      "mayus": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adulto"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niño"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])}
      }
    },
    "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alojamiento"])},
    "gastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitación"])},
    "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regimen"])},
    "esMiReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La reserva es para mí"])},
    "nombreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizaremos el nombre y apellido de tu perfil"])},
    "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])},
    "menorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy menor de edad"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "masInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información"])},
    "masInformacionIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ información"])},
    "borrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "esMenorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es menor de edad"])},
    "fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "invalidCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código Invalido"])},
    "politicaPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y condiciones"])}
  },
  "filtroAlojamiento": {
    "borrarFiltros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar filtros"])},
    "titulo": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar alojamiento"])},
      "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poblaciones"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de alojamiento"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº plazas"])}
    }
  },
  "filtroModalidades": {
    "titulo": {
      "seleccionaGama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar gama"])},
      "seleccionaServicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar servicio"])}
    }
  },
  "selectSubTipos": {
    "titulo": {
      "seguroAnulacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguro de Anulación"])},
      "seguroViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguro de experiencia"])}
    }
  },
  "resumenServiciosBuscador": {
    "nochesAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["días de alojamiento "])},
    "diasForfait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["días de forfait"])}
  },
  "baseMigas": {
    "seleccionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de alojamiento"])},
    "datosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva"])},
    "confirmacionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación reserva"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
    "seleccionFestival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de festival"])}
  },
  "botones": {
    "miEntrada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi entrada"])},
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservar alojamiento"])},
    "reservaPackFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservar pack de fiesta"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirse a habitación"])},
    "volverViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a experiencias"])},
    "volverReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a mis reservas"])},
    "actualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservar"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos"])},
    "verDetalles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver detalles"])},
    "verReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Reserva"])},
    "bonoAdjunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar Bono Adjunto"])},
    "cancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelada"])},
    "descargar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
    "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "volver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "recuperar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar"])},
    "copiar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
    "cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "cancelarExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar experiencia"])},
    "modificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar"])},
    "modificarDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar datos"])},
    "modificarPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar y Pagar"])},
    "agregarPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añade otra persona"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "darseBaja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darse de baja"])},
    "aceptarSeleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar Selección"])},
    "cancelarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar Reserva"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar Reserva"])},
    "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar"])},
    "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unirse"])},
    "reactivarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivar reserva"])},
    "recordar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordar"])},
    "pagarSenial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar Señal"])},
    "pagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "establecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecer"])},
    "verBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Bono"])},
    "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "reintentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reintentar"])}
  },
  "codigoDescuento": {
    "tienesCodDescuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes un código de descuento"])}
  },
  "cookies": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLITICA SOBRE COOKIES"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizamos cookies para mejorar nuestros servicios, analizar y personalizar tu navegacion. Para continuar navegando por nuestra web, debes aceptar su uso. Puedes cambiar la configuracion u obtener más información en nuestra"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto todas las cookies"])},
    "politicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Cookies"])},
    "gestionar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionar cookies"])},
    "extras": {
      "necesarias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesarias"])},
      "analiticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analíticas"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markenting"])}
    },
    "preguntas": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿QUÉ SON LAS COOKIES?"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿AUTORIZACIÓN PARA EL USO DE COOKIES?"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿TIPO DE COOKIES EMPLEADAS?"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿CÓMO DESACTIVAR O ELIMINAR LAS COOKIES?"])}
    },
    "respuestas": {
      "1": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las cookies son ficheros de texto que se almacenan en el dispositivo del usuario que navega a través de Internet y que, en particular, contiene un número que permite identificar unívocamente dicho dispositivo, aunque éste cambie de localización o de dirección IP."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las cookies son instaladas durante la navegación por Internet, bien por los sitios web que visita el usuario o bien por terceros con los que se relaciona el sitio web, y permiten a éste conocer su actividad en el mismo sitio o en otros con los que se relaciona éste, por ejemplo: el lugar desde el que accede, el tiempo de conexión, el dispositivo desde el que accede (fijo o móvil), el sistema operativo y navegador utilizados, las páginas más visitadas, el número de clicks realizados y de datos respecto al comportamiento del usuario en Internet."])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las cookies se asocian únicamente a su navegador y no proporcionan por sí mismas datos personales. Las cookies no pueden dañar su dispositivo y además son muy útiles, ya que nos ayudan a identificar y resolver errores. El sitio web es accesible sin necesidad de que las cookies estén activadas, si bien, su desactivación puede impedir el correcto funcionamiento del mismo."])}
      },
      "2": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De conformidad con el aviso de cookies que aparece en el pie de página del sitio web, el usuario o visitante de los mismos acepta que, al navegar por el mismo, consiente expresamente el uso de cookies según la descripción que se detalla a continuación, excepto en la medida que haya modificado la configuración de su navegador para rechazar la utilización de las mismas."])}
      },
      "3": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Titular de la web utiliza cookies estrictamente necesarias que sirven para facilitar la correcta navegación en el sitio Web, así como para asegurar que el contenido de los mismos se carga eficazmente. Estas cookies son, además, cookies de sesión es decir que tienen carácter temporal y expiran y se borran automáticamente cuando el usuario cierra su navegador."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies de Facebook Ireland Lt, que se utilizan para que los visitantes puedan interactuar con el contenido de Facebook y que se generan únicamente para los usuarios de dichas redes sociales. Las condiciones de utilización de estas cookies y la información recopilada se regula por la política de privacidad de la plataforma social correspondiente."])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las Cookies se pueden desactivar y eliminar siguiendo las siguientes guías en función del navegador empleado:"])},
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar y administrar cookies en Internet Explorer"])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar, habilitar y administrar cookies en Chrome"])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar cookies en Firefox"])},
        "bloque4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración web de Safari en el iPhone, el iPad y el iPod touch"])}
      }
    }
  },
  "politicasCancelacion": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Políticas de Cancelación"])}
  },
  "titulos": {
    "incluye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluye"])},
    "itinerario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinerario"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva para tantas personas como quieras"])},
    "completarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellena los siguientes datos para continuar con tu reserva"])},
    "motivosCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivos de cancelación"])},
    "editarPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar Perfil"])}
  },
  "subtitulo": {
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al acabar la reserva, envía el enlace que te proporcionaremos al resto de personas para que se unan."])},
    "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce el nombre de tu habitación"])},
    "comoConociste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo nos conocisteis?"])},
    "estudiando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás estudiando?"])},
    "escribeRespuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe tu respuesta"])},
    "eligeUniversidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige una universidad"])},
    "trabajando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿En qué empresa estás trabajando?"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "importeTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe total"])},
    "importePagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe total pagado"])},
    "importePendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe pendiente"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total reserva"])},
    "totalPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL a pagar"])},
    "saldoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo pendiente"])},
    "codigoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de reserva"])},
    "codigoViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de experiencia"])},
    "intoduceAqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdúcelo aquí"])},
    "codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "misViajeros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis viajeros"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi perfil"])},
    "mailingPublicidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing publicidad"])},
    "comercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del comercial"])},
    "subirDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir DNI/Pasaporte"])},
    "modificarcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar contraseña"])},
    "volverPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a mi perfil"])},
    "datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos"])}
  },
  "modalUnirseHabitacion": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Te has quedado sin alojamiento?"])},
    "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellena los siguientes datos y en caso de quedar libre alguna habitación se te avisará"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si quieres unirte a la habitación de un experiencia, utiliza el código que te ha dado la persona que haya realizado la reserva"])}
  },
  "input": {
    "field": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "nombreApellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre y apellidos"])},
      "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
      "numeroPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de plazas"])},
      "observaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observaciones"])},
      "redesSociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redes sociales"])},
      "comerciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comerciales"])},
      "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquete básico"])}
    },
    "placeholder": {
      "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe el nombre de tu habitación"])},
      "introduceCampo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce el campo"])}
    }
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos del comprador"])},
    "subtitleRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No eres cliente? Regístrate"])},
    "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvidaste tu contraseña"])},
    "recoverPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar contraseña"])},
    "questionLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya eres cliente? Inicia sesión"])},
    "questionUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya eres usuario"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar la contraseña"])},
    "establecerContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecer contraseña de usuario"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])}
  },
  "experiencia": {
    "IntroduceCodViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce el código de tu experiencia"])},
    "colegios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tienes entre 5 y 17 años y quieres aprovechar al máximo los días de esquí, este es tu experiencia. Mejora tu técnica y disfruta de la naturaleza, nosotros te facilitamos el transporte, alojamiento y monitores."])},
    "familias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te ofrecemos el alojamiento más cómodo para viajar a la nieve con tu familia. Además, podrás contratar clases de esquí para niños y muchas otras actividades adaptadas a tus necesidades."])},
    "trabajadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viajar con tus compañeros de trabajo es muy fácil. Si tienes más de 25 años, únete a nuestras experiencias organizados para coincidir con gente como tú, en las que el esquí y el ocio nocturno te sorprenderán."])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es tu experiencia si quieres coincidir con gente de tu edad en los mejores festivales y après-skis. ¿Te atreves a vivir una experiencia inolvidable en la nieve?"])}
  },
  "errors": {
    "habitacion": {
      "codigoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código no corresponde a ninguna habitación"])}
    },
    "grupoReservas": {
      "pendientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existen reservas pendientes de confirmar"])}
    },
    "experiencia": {
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La experiencia no admite nuevas reservas"])}
    },
    "reserva": {
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno de los viajeros ya tiene una reserva en esta experiencia"])}
    },
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error al realizar el pago"])},
    "pagoTransferencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago de esta reserva es por transferencia"])},
    "cargaInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un problema al cargar la información. Intentelo más tarde"])},
    "controlErrorValidarCupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe el experiencia solicitado"])},
    "noAdmiteReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El experiencia no admite una reserva nueva"])},
    "precioCambiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha detectado un cambio de precios"])},
    "inesperado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error inesperado. Intente luego"])},
    "loginReservaDescartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesitas logearte para descartar la reserva"])},
    "permisosDescartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cuenta con los permisos para descartar la reserva"])},
    "descartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede descartar la reserva"])},
    "intentalo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error inesperado. Intentelo luego"])},
    "obligatorios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existen campos obligatorios sin rellenar"])},
    "loginReservaCrear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesitas hacer login para crear la reserva"])},
    "permisosCrearReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cuentas con los permisos para crear la reserva"])},
    "noInfoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido encontrar la información de la reserva"])},
    "existeReservaNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya existe una reserva pagada con este nombre."])},
    "existeReservaViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya existe una reserva para este experiencia"])},
    "emailRegistrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El email ya se encuentra registrado"])},
    "permisosProcesos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cuenta con los permisos para realizar el proceso"])},
    "camposSinCompletar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existe campos sin completar"])},
    "recursoNoLocalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El recurso solicitado no ha podido ser localizado"])},
    "conflictoEstadoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existe un conflicto en el estado de la reserva"])},
    "errorContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al ingresar la contraseña"])},
    "loginRequerido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesita logearse para continuar"])},
    "reservaNoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La reserva no se encuentra en estado pendiente"])},
    "noAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron alojamientos"])},
    "intenteReservaTarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, intente reservar más tarde, en el caso de que el error persista contactenós."])}
  },
  "servicios": {
    "repercutirFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de gestión"])},
    "tasaDe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tasa de ", _interpolate(_named("nombreTasa"))])},
    "forfait": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes cambiar los dias de tu forfait. Selecciona los dias que quieras"])}
    },
    "comida": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comida"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige si quieres comida en pistas"])}
    },
    "transporte": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporte"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige tu medio de transporte"])}
    },
    "seguro": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguros"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige tu seguros"])},
      "subtipo": {
        "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelación"])},
        "salud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salud"])}
      }
    },
    "actividad": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige la actividad"])}
    },
    "restauracion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comida"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige si quieres comida en pistas"])}
    },
    "festival": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festivales"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige la entrada que quieras y ven a disfrutar la experiencia completa"])}
    },
    "clase": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clases"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes elegir los días de clases. Selecciona los días que quieras"])}
    },
    "alquiler": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquileres"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige si quieres alquilar equipo"])}
    },
    "greenFee": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Fee"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige si quieres Green Fee."])}
    },
    "relax": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige si quieres servicio de relax."])}
    },
    "personal": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige el personal."])}
    },
    "parking": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleecion tu parqueo preferido"])}
    },
    "mascota": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mascota"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige los servicios de mascota"])}
    },
    "competicion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competicion"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige la competicion"])}
    }
  },
  "trabajaNosotros": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabaja con Nosotros"])}
  },
  "cardReservaPlazasLibres": {
    "descripcion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Todavía tienes ", _interpolate(_named("plazasLibres")), " plazas libres en tu alojamiento, lo que supone un pago pendiente de ", _interpolate(_named("pagoPendiente")), ". Tienes dos opciones:"])},
    "opcionUno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir el link para completar las plazas libres."])},
    "opcionDos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonar el pago pendiente de ", _interpolate(_named("pagoPendiente")), " entre las personas confirmadas."])},
    "cancelacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si no se lleva a cabo una de las dos opciones antes del ", _interpolate(_named("fecha")), " la ", _interpolate(_named("final"))])},
    "cancelacionBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reserva se cancelará automáticamente."])},
    "msjPlazaLibresPagadas": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("plazasLibres")), " free places, share the link to complete them and the supplement “Free places” will be recalculated in your favor."])},
    "button": {
      "abonarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonar el pago pendiente"])},
      "copiarLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar Link"])}
    }
  },
  "langs": {
    "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francés"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])}
  }
}