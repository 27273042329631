<template>
	<!-- col-sm-10 col-md-10 -->
	<div v-if="informacion" class="col-12 col-lg-4 col-xl-4 col-xxl-3">
		<div class="card_landing h-100">
			<div class="card__header">
				<div class="card__header__background">
					<img :src="informacion?.imagenes[0]" />
					<!-- <img
			  src="https://resources.mygocu.com/48fcd863-19b4-42e1-9bfc-8ed0d33ae73f/dyn_img/hoteles/5611.jpg"
			/> -->
				</div>
				<div class="card__header__price my-2">
					<div class="row justify-content-center">
						<div class="col-auto price-card">{{ precioMedioReservaMasBajo }} {{agenciaStore.getCurrency}}</div>
					</div>
					<span class="text-primary text-subTitle-header">
						{{ $t('general.porPersona') }}
					</span>
				</div>
			</div>
			<div class="card__body text-left">
				<div class="row gx-3 mt-2 mx-4 mb-3 text-left">
					<div class="col-12">
						<div class="row mt-3">
							<div v-if="tipo == 'alojamiento'" class="col-12">
								<span class="text-primary text-detail__bold p-0">
									{{ informacion.nombre }}
									<i v-for="i in informacion.estrellas" :key="i" class="fa-regular fa-star" />
									<div />
								</span>
							</div>
							<div class="col-12">
								<a class="fa-solid fa-calendar fa-md text-primary my-3" />
								<span class="text-primary text-detail__bold">
									{{ filtros.fechaInicio }} -
									{{ filtros.fechaFin }}
									({{ calcularDiferencia(filtros.fechaInicio, filtros.fechaFin) }}días )
								</span>
							</div>
							<div class="col-12">
								<a class="fa-solid fa-users fa-md text-primary my-3" />
								<span class="text-primary text-detail">{{ personas }} personas</span>
							</div>
							<div class="col-12">
								<a class="fa-solid fa-person-skiing fa-md text-primary my-3" />
								<span class="text-primary text-detail"
									>Forfait
									{{ calcularDiferencia(filtros.fechaInicioFF, filtros.fechaFinFF) }}
									días</span
								>
							</div>
						</div>
					</div>
				</div>
				<div class="card__body__buttons text-center">
					<div class="card__body-button mb-2">
						<button
							class="btn btn-primary"
							@click="goToBuscadorAlojamiento(informacion, filtros.lugarExperiencia, filtros)">
							<span class="text-primary text-button">Disfruta de esta oferta</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed, ref } from 'vue';
	import { buscadorStore } from '@/store_pinia/buscador';
	import { useRouter } from 'vue-router';
	import { BuscadorAlojamiento } from '@/router/RouterNames';
	import { agenciaStoreModule } from '@/store_pinia/agencia';

	const store_buscador = buscadorStore();
	const agenciaStore = agenciaStoreModule();
	const router = useRouter();

	const props = defineProps({
		informacion: {
			type: Object,
			default: null,
		},
		tipo: {
			type: String,
			default: null,
		},
		filtros: {
			type: Object,
			default: null,
		},
	});

	const precios = ref(props.informacion?.pensiones);
	const TipoPension = ref('');

	const personas = computed(() => {
		let sumaPersona = 0;
		for (let i = 0; i < props.filtros.habitaciones.length; i++) {
			sumaPersona += props.filtros.habitaciones[i].habitacion.length;
		}
		return sumaPersona;
	});

	let preciosArray = [];
	for (let tipoPension in precios.value) {
		for (let key in precios.value[tipoPension]) {
			precios.value[tipoPension][key].forEach(item => {
				item.tipoPension = tipoPension;
			});
			preciosArray = preciosArray.concat(Object.values(precios.value[tipoPension][key]));
		}
	}
	const precioMedioReservaMasBajo = Math.min(...preciosArray.map(item => Number(item.precioMedioReserva).toFixed(2)));
	TipoPension.value = preciosArray.find(
		item => Number(item.precioMedioReserva).toFixed(2) === precioMedioReservaMasBajo.toFixed(2)
	).tipoPension;

	const goToBuscadorAlojamiento = (hotel, lugarExperiencia, filtrosLanding) => {
		hotel.currentPension = TipoPension.value;
		store_buscador.estacionSeleccionada = lugarExperiencia;
		store_buscador.hotelSeleccionado = hotel;
		let destino = {
			nombre: '',
			areaId: filtrosLanding.area,
			estacionId: filtrosLanding.lugarExperiencia,
		};
		let fechasAlojamiento = [filtrosLanding.fechaInicio, filtrosLanding.fechaFin];
		let fechasForfait = [filtrosLanding.fechaInicioFF, filtrosLanding.fechaFinFF];
		let habitaciones = filtrosLanding.habitaciones;

		let query = {
			destino: destino,
			fechasAlojamiento: fechasAlojamiento,
			fechasForfait: fechasForfait,
			habitaciones: habitaciones,
		};

		store_buscador.filtrosSeleccionadosBuscador = query;

		router.push({
			name: BuscadorAlojamiento,
			params: { idAlojamiento: hotel.id },
		});
	};

	const calcularDiferencia = (fechaInicio, fechaFin) => {
		if (fechaInicio && fechaFin) {
			const start = new Date(fechaInicio);
			const end = new Date(fechaFin);

			const differenceInMilliseconds = end - start;
			const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

			return differenceInDays;
		}
	};
</script>

<style lang="scss" scoped>
	.card_landing {
		border-radius: var(--bs-border-radius-xl);
		background: var(--Blanco, #fff);
		box-shadow: 0px 1px 4px 1px rgba(var(--bs-primary-rgb), 0.25);
	}

	.card__header {
		position: relative;

		&__background {
			overflow: hidden;
			border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0;

			img {
				width: 100%;
				height: 150px;
				// opacity: 0.5;
				object-fit: cover;
			}
		}

		&__price {
			margin: auto;
			position: absolute;
			top: 118px;
			left: 0;
			bottom: 0;
			right: 0;
			text-align: center;

			span {
				font-size: 0.9rem;
			}

			.price-card {
				padding: 0.3rem;
				font-weight: 600;
				border-radius: var(--bs-border-radius-xl);
				background: linear-gradient(185deg, #5f5e8f -7.77%, var(--bs-primary) 76.51%);
				box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
				font-size: 1.5rem;
				color: white;
				top: -0.8rem;
			}
		}

		.text-subTitle-header {
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.154px;
		}
	}

	.card__body {
		display: flex;
		flex-direction: column;
		height: max-content;
		justify-content: space-between;
		margin-top: 2rem;

		&-button {
			button {
				padding: 0.3rem 1rem;
				font-size: 0.8em;
				transition: 0.3s ease-in-out;

				&:hover {
					transform: scale(1.1);
				}
			}

			.text-button {
				text-align: center;
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 0.154px;
			}
		}

		.text-detail {
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: 0.154px;
			padding-left: 1rem;

			&__bold {
				font-size: 14px;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.154px;
				font-weight: 600;
				padding-left: 1rem;
			}
		}
	}

	.services-text {
		font-size: 0.8rem;
	}

	.services-button {
		display: inline-table;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		border-radius: 50%;
		font-weight: 900;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		/* margin: auto; */
		padding: 10px;
		color: white;
	}
</style>
